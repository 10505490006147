import React, { Component } from "react";
import {
  apiGroupContactDelete,
  apiGroupContactInput,
  apiGroupContactList,
  apiGroupDeleteAll,
} from "../../Service/api";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  DialogActions,
  DialogContent,
  IconButton,
  DialogContentText,
  Stack,
  Alert,
  DialogTitle,
  Slide,
  Checkbox,
} from "@mui/material";
import { Input, FormGroup, Label } from "reactstrap";
import {
  Refresh,
  Edit,
  Delete,
  AddBox,
  Close,
  Save,
  Cancel,
  Check,
  DeleteOutline,
} from "@mui/icons-material";
import Select from "react-select";
const stylesListComent = {
  inline: {
    display: "inline",
  },
};

const stylesListDialog = {
  inline: {
    display: "inline",
  },
};

const stylesDialog = {
  appBar: {
    position: "relative",
    backgroundColor: "#03101e",
  },
  title: {
    marginLeft: 0,
    flex: 1,
    fontSize: 16,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class GroupContactPage extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.state = {
      tableData: [],
      tableDisplay: [],
      filter: "",
      setOpenEdit: false,
      setOpenAddNew: false,
      openSuccess: false,
      openSuccessText: "",
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      group_id: 0,
      group_name: "",
      openAlertDelete: false,
      itemDeleted: {},
      selectAll: false,
      checked: [],
      selectRowData: [],
      openSelectedRow: false,
      openAlertDeleteAll: false,
    };

    this.tableColumns = [
      {
        Header: "No",
        headerStyle: { fontWeight: "bold" },
        accessor: "id",
        style: { textAlign: "center" },
        width: 100,
      },
      {
        Header: "Group",
        headerStyle: { fontWeight: "bold" },
        accessor: "group_name",
        style: { textAlign: "center" },
      },
      {
        Header: "Action",
        headerStyle: { fontWeight: "bold" },
        accessor: "",
        style: { textAlign: "center" },
        width: 200,
        Cell: (e) => (
          <div>
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#3f51b5",
              }}
              startIcon={<Edit />}
              onClick={() => this.doRowEdit(e.original)}
            >
              <Typography
                variant="button"
                style={{
                  fontSize: 14,
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Edit
              </Typography>
            </Button>
            &nbsp;
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#ff0000",
              }}
              startIcon={<Delete />}
              onClick={() => this.doRowDeleteInfo(e.original)}
            >
              <Typography
                variant="button"
                style={{
                  fontSize: 14,
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Delete
              </Typography>
            </Button>
          </div>
        ),
      },
    ];
  }

  componentDidMount() {
    this.getDataGroup();
  }

  getDataGroup = () => {
    this.props.doLoading();
    apiGroupContactList(this.state.filter)
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          var temp = this.state.tableData;
          temp = data.records;
          for (var i = 0; i < temp.length; i++) {
            temp[i].id = i + 1;
          }
          this.setState({ tableData: temp });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  doDeleteGroup = (group_id) => {
    this.props.doLoading();
    apiGroupContactDelete(group_id)
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            openSuccess: true,
            openSuccessText: "Data deleted successfully",
            openAlertDelete: false,
            itemDeleted: {},
          });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  doSubmit = () => {
    let params = {
      group_id: this.state.group_id,
      group_name: this.state.group_name,
    };
    apiGroupContactInput(params).then((res) => {
      let data = res.data;
      if (data.status === "OK") {
        this.setState({
          group_name: "",
          openSuccess: true,
          openSuccessText:
            this.state.setOpenEdit === true
              ? "Edit data successfully updated"
              : "Data saved successfully",
        });
        this.handleCloseAddNew();
      }
    });
  };

  checkData = () => {
    const { group_name } = this.state;

    if (group_name === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Group empty?",
        titleBody: "Please enter group",
      });
    } else {
      this.doSubmit();
    }
  };

  doRowDeleteInfo = (item) => {
    this.setState({
      openAlertDelete: true,
      itemDeleted: item,
    });
  };

  handleCloseItemInfo = () => {
    this.setState({
      openAlertDelete: false,
      itemDeleted: {},
      openAlertDeleteAll: false,
      selectRowData: [],
    });
  };

  pressDeleteAll = () => {
    console.log(this.state.selectRowData);
    let params = {
      item_delete: this.state.selectRowData,
    };
    this.props.doLoading();
    apiGroupDeleteAll(params)
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            openSuccess: true,
            openSuccessText: "Data deleted successfully.",
            openAlertDeleteAll: false,
          });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  toggleSelectAll = (e) => {
    let tmp = this.state.tableData;
    for (let i = 0; i < tmp.length; i++) {
      tmp[i].isChecked = e.target.checked;
    }
    // console.log(tmp);
    // console.log(e.target.checked);
    this.setState({ tableData: tmp, selectAll: e.target.checked });
    var selectAll = !this.state.selectAll;
    // console.log(selectAll);
    this.setState({ selectAll: selectAll });
    var checkedCopy = [];
    tmp.forEach(function (e, index) {
      checkedCopy.push(selectAll);
    });
    // console.log(checkedCopy);
    this.setState({
      checked: checkedCopy,
    });

    var selectRow = [];
    tmp.forEach((val, idx) => {
      if (val.isChecked === true) {
        selectRow.push(val);
      }
    });

    // console.log(selectRow);
    this.setState({
      selectRowData: selectRow,
    });
  };

  handleCheck = (e, i) => {
    let tmp = this.state.tableData;
    tmp[i].isChecked = e.target.checked;
    // console.log(tmp);
    this.setState({ tableDisplay: tmp });
    this.setState({ checked: tmp[i].isChecked });

    var checkedCopy = this.state.checked;
    checkedCopy[i] = !this.state.checked[i];
    if (checkedCopy[i] === false) {
      this.setState({ selectAll: false });
    }
    this.setState({
      checked: checkedCopy,
    });

    var selectRow = [];
    tmp.forEach((val, idx) => {
      if (val.isChecked === true) {
        selectRow.push(val);
      }
    });
    this.setState({
      selectRowData: selectRow,
    });
  };

  renderRemoveItemAll = () => {
    return (
      <Dialog
        open={this.state.openAlertDeleteAll}
        onClose={this.handleCloseItemInfo}
        aria-labelledby="customized-dialog-title"
        fullWidth="sm"
        maxWidth="sm"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography
              component="span"
              variant="h2"
              style={stylesDialog.title}
            >
              Delete Telegram Account?
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseItemInfo()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ marginTop: 10 }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              component="span"
              variant="body2"
              style={(stylesListDialog.inline, { fontSize: 16, color: "#333" })}
            >
              Do you want to delete the account?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#808080",
            }}
            startIcon={<Cancel />}
            onClick={this.handleCloseItemInfo}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>{" "}
          <Button
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#0693e3",
            }}
            startIcon={<Check />}
            onClick={() => this.pressDeleteAll()}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Yes
            </Typography>
          </Button>{" "}
        </DialogActions>
      </Dialog>
    );
  };

  renderRemoveItemInfo = () => {
    let item = this.state.itemDeleted;
    return (
      <Dialog
        open={this.state.openAlertDelete}
        onClose={this.handleCloseItemInfo}
        aria-labelledby="customized-dialog-title"
        fullWidth="sm"
        maxWidth="sm"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography
              component="span"
              variant="h2"
              style={stylesDialog.title}
            >
              Delete Group Contact?
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseItemInfo()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ marginTop: 10 }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              component="span"
              variant="body2"
              style={(stylesListDialog.inline, { fontSize: 16, color: "#333" })}
            >
              Are you sure want to delete group contact {item.group_name}?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#808080",
            }}
            startIcon={<Cancel />}
            onClick={this.handleCloseItemInfo}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>{" "}
          <Button
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#0693e3",
            }}
            startIcon={<Check />}
            onClick={() => this.doDeleteGroup(item.group_id)}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Yes
            </Typography>
          </Button>{" "}
        </DialogActions>
      </Dialog>
    );
  };

  doRowEdit = (row) => {
    this.setState({
      setOpenEdit: true,
      group_id: row.group_id,
      group_name: row.group_name,
    });
  };

  pressReset = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
      selectAll: false,
      selectRowData: [],
      checked: [],
    });
    this.getDataGroup();
  };

  pressAddNew = () => {
    this.setState({
      setOpenAddNew: true,
    });
  };

  handleCloseAddNew = () => {
    this.setState({
      setOpenAddNew: false,
      setOpenEdit: false,
      group_name: "",
      group_id: 0,
    });
  };

  renderDialogAddNew = () => {
    return (
      <Dialog
        onClose={this.handleCloseAddNew}
        aria-labelledby="customized-dialog-title"
        open={
          this.state.setOpenAddNew === true
            ? this.state.setOpenAddNew
            : this.state.setOpenEdit
        }
        fullWidth="md"
        maxWidth="md"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography variant="h5" style={stylesDialog.title}>
              {this.state.setOpenAddNew === true
                ? "Add Group Contact"
                : this.state.setOpenEdit === true
                ? "Edit Group Contact"
                : this.state.setOpenEdit}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseAddNew()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 60, marginTop: 20 }}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    color: "#000",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  Group Name
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="groupname"
                  id="groupname"
                  placeholder="Enter group name"
                  value={this.state.group_name}
                  onChange={(event) =>
                    this.setState({ group_name: event.target.value })
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#d0021b",
            }}
            startIcon={<Cancel />}
            onClick={() => this.handleCloseAddNew()}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#004dcf",
            }}
            startIcon={<Save />}
            onClick={() => this.checkData()}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Save
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth="xs"
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  closeStackSuccess = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
    });
  };

  renderSuccess = () => {
    if (this.state.openSuccess === true) {
      setTimeout(() => this.pressReset(), 2000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  // onClick={() => this.reset()}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openSuccessText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  color: "#FFF",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <Typography
                  component="span"
                  variant="h1"
                  style={
                    (stylesListComent.inline,
                    {
                      fontSize: 20,
                      color: "#000",
                      fontWeight: "bold",
                    })
                  }
                >
                  Group Contact
                </Typography>
                <br></br>
                <div className="contentDate">
                  <div style={{ marginRight: 16 }}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#1273DE",
                      }}
                      startIcon={<Refresh />}
                      onClick={() => this.pressReset()}
                    >
                      <Typography
                        variant="button"
                        style={{
                          fontSize: 14,
                          color: "#fff",
                          textTransform: "capitalize",
                        }}
                      >
                        Refresh
                      </Typography>
                    </Button>
                  </div>
                  <div style={{ marginRight: 0 }}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#008b02",
                      }}
                      startIcon={<AddBox />}
                      onClick={() => this.pressAddNew()}
                    >
                      <Typography
                        variant="button"
                        style={{
                          fontSize: 14,
                          color: "#fff",
                          textTransform: "capitalize",
                        }}
                      >
                        Add New
                      </Typography>
                    </Button>
                  </div>
                  {this.state.selectRowData.length > 0 ? (
                    <div style={{ marginRight: 0, marginLeft: 16 }}>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#ff0000",
                        }}
                        startIcon={<DeleteOutline />}
                        onClick={() =>
                          this.setState({ openAlertDeleteAll: true })
                        }
                      >
                        <Typography
                          variant="button"
                          style={{
                            fontSize: 14,
                            color: "#fff",
                            textTransform: "capitalize",
                          }}
                        >
                          Delete
                        </Typography>
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <br></br>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <br></br>
        <div className="box-container">
          <ReactTable
            ref={(r) => (this.reactTable = r)}
            data={this.state.tableData}
            columns={[
              {
                Header: (
                  <Checkbox
                    style={{
                      width: 30,
                      height: 30,
                      color: "#3a6cf6",
                    }}
                    onChange={(e) => this.toggleSelectAll(e)}
                    checked={this.state.selectAll}
                    inputProps={{
                      "aria-labelledby": "checkbox-list-secondary-label",
                    }}
                  />
                ),
                Cell: (row) => (
                  <Checkbox
                    style={{
                      width: 30,
                      height: 30,
                      color: "#3a6cf6",
                    }}
                    defaultChecked={this.state.checked[row.index]}
                    checked={this.state.checked[row.index]}
                    onChange={(e) => this.handleCheck(e, row.index)}
                    inputProps={{
                      "aria-labelledby": "checkbox-list-secondary-label",
                    }}
                  />
                ),
                headerStyle: { fontWeight: "bold" },
                sortable: false,
                filterable: true,
                style: { textAlign: "center" },
                width: 100,
              },
              {
                Header: "No",
                headerStyle: { fontWeight: "bold" },
                accessor: "id",
                style: { textAlign: "center" },
                width: 100,
              },
              {
                Header: "Group",
                headerStyle: { fontWeight: "bold" },
                accessor: "group_name",
                style: { textAlign: "center" },
              },
              {
                Header: "Action",
                headerStyle: { fontWeight: "bold" },
                accessor: "",
                style: { textAlign: "center" },
                width: 200,
                Cell: (e) => (
                  <div>
                    <Button
                      variant="contained"
                      size="small"
                      style={{
                        backgroundColor: "#3f51b5",
                      }}
                      startIcon={<Edit />}
                      onClick={() => this.doRowEdit(e.original)}
                    >
                      <Typography
                        variant="button"
                        style={{
                          fontSize: 14,
                          color: "#fff",
                          textTransform: "capitalize",
                        }}
                      >
                        Edit
                      </Typography>
                    </Button>
                    &nbsp;
                    <Button
                      variant="contained"
                      size="small"
                      style={{
                        backgroundColor: "#ff0000",
                      }}
                      startIcon={<Delete />}
                      onClick={() => this.doRowDeleteInfo(e.original)}
                    >
                      <Typography
                        variant="button"
                        style={{
                          fontSize: 14,
                          color: "#fff",
                          textTransform: "capitalize",
                        }}
                      >
                        Delete
                      </Typography>
                    </Button>
                  </div>
                ),
              },
            ]}
            style={{ backgroundColor: "#f2f2f2" }}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id])
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            }
            defaultPageSize={5}
          />
        </div>
        {this.renderDialogAddNew()}
        {this.renderValidationAlert()}
        {this.renderSuccess()}
        {this.renderRemoveItemAll()}
        {this.renderRemoveItemInfo()}
      </Box>
    );
  }
}
export default GroupContactPage;

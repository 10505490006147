export function convertToRupiah(number) {
  if (number) {
    var rupiah = "";

    var numberrev = number

      .toString()

      .split("")

      .reverse()

      .join("");

    for (var i = 0; i < numberrev.length; i++)
      if (i % 3 == 0) rupiah += numberrev.substr(i, 3) + ".";

    return (
      "Rp. " +
      rupiah

        .split("", rupiah.length - 1)

        .reverse()

        .join("")
    );
  } else {
    return number;
  }
}

export var Data_Config = {
  api_id: 12789006,
  api_hash: "3630f6df6b56de2bee4eab4001bc11e8",
  phone: +6282136180185,
  string_session:
    "1BQANOTEuMTA4LjU2LjIwMAG7TeDZRGpOS/hCKP+fBMnlhEGhtevEaUZF63ix9hn34a8/N487v83ZXA89WXoVlFzTcrjEGmqe/l7GsD007aCb8sglIHkkyS5sVl8cwzM5m63geKCSb9KpVxwhg8vZz43g3zmXgypm+GEZqCFz6PHKUElruAYjlhPgbxVbI/x5x/avA8XHasFxuNLVxUM43SSyOvqhKr9w7hgo0/1giOZzTof0/ahtA/0YFNC3wgPhnDvOi7v3TkBjOb7rrWld5p1Kgpjy958YMiX7GvuiE+tbgBV8YiWD0KAntuJYmm+DUKG9pKhvSoqt70Va257OajfNnz6cdvWqsoyXvNJLpngTgg==",
};

export var shuffle = (array) => {
  let counter = array.length;

  // While there are elements in the array
  while (counter > 0) {
    // Pick a random index
    let index = Math.floor(Math.random() * counter);

    // Decrease counter by 1
    counter--;

    // And swap the last element with it
    let temp = array[counter];
    array[counter] = array[index];
    array[index] = temp;
  }

  return array;
};

export var getRoundRobin = (index, array) => {
  const arrData = array;
  const after = arrData.slice(index + 1, arrData.length);
  const before = arrData.slice(0, index + 1);

  return after.concat(before);
};

export var SetSeccondsFlood = (dateFlood, dateNow) => {
  var dif = (dateNow.getTime() - dateFlood.getTime()) / 1000;
  return dif;
};

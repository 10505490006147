import React, { Component } from "react";
import {
  apiGroupContactList,
  apiAllContactListNoLimit,
  apiAllContactGroupIdNoLimit,
  apiManageApiAccount,
} from "../../Service/api";
import { Api, TelegramClient } from "telegram";
import { StringSession } from "telegram/sessions";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  DialogContentText,
  DialogTitle,
  Slide,
  Checkbox,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Stack,
  Alert,
} from "@mui/material";
import Select from "react-select";
import { Input } from "reactstrap";
import {
  Contacts,
  Close,
  StopCircle,
  AddBox,
  Send,
  Search,
} from "@mui/icons-material";
import { Data_Config, shuffle } from "../../../global";
const stylesListComent = {
  inline: {
    display: "inline",
  },
};

const stylesListDialog = {
  inline: {
    display: "inline",
  },
};

const stylesDialog = {
  appBar: {
    position: "relative",
    backgroundColor: "#03101e",
  },
  title: {
    marginLeft: 0,
    flex: 1,
    fontSize: 16,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class SendMessagePersonal extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.searchContact = React.createRef();
    this.messageText = React.createRef();
    this.state = {
      tableData: [],
      tableDisplay: [],
      filter: "",
      message: "",
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      showLoading: false,
      showTextGetParticipants: false,
      arrDataUserParticipants: [],
      selectedGroupId: null,
      groupShow: [],
      AllContact: [],
      selectAll: false,
      pushData: [],
      openSuccess: false,
      openSuccessText: "",
      openFailed: false,
      openFailedText: "",
      selectAll: false,
      checked: [],
      selectRowData: [],
      loadingMessage: false,
      accountShow: [],
    };
  }

  componentDidMount() {
    this.getDataAccounts();
    this.getDataGroup();
  }

  async getConnection(obj) {
    console.log(obj);
    const apiId = obj.api_id;
    const apiHash = obj.api_hash;
    const session = new StringSession(obj.string_token);
    const client = new TelegramClient(session, apiId, apiHash, {
      connectionRetries: 5,
    });
    await client.session.setDC(5, "flora.web.telegram.org", 443);
    client.connect();
    console.log(client);
    // .then((client) => {
    //   console.log(obj.alias);
    //   console.log(client);
    // })
    // .catch((err) => {
    //   console.log(err.errorMessage.toString());
    // });
  }

  getDataAccounts = () => {
    apiManageApiAccount()
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          var temp = this.state.accountShow;
          temp = data.records;
          for (var i = 0; i < temp.length; i++) {
            temp[i].value = temp[i].manage_id;
            temp[i].label = temp[i].alias;
          }
          console.log(temp);
          this.setState({ accountShow: temp });
          // if (temp.length > 0) {
          //   const promises = temp.map(
          //     (obj_item, i) =>
          //       new Promise((resolve) =>
          //         setTimeout(() => {
          //           return this.getConnection(obj_item);
          //           resolve();
          //         }, 3000 * i)
          //       )
          //   );
          //   Promise.all(promises).then(() => console.log("done"));
          // }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getDataGroup = () => {
    apiGroupContactList()
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          var temp = this.state.groupShow;
          temp = data.records;
          for (var i = 0; i < temp.length; i++) {
            temp[i].value = temp[i].group_id;
            temp[i].label = temp[i].group_name;
          }

          this.setState({
            selectedGroupId: temp[0],
          });

          this.setState({ groupShow: temp });
          this.getAllContactByGroup(temp[0].value);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getAllContact = (newFilter) => {
    this.props.doLoading();
    apiAllContactListNoLimit(newFilter)
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          var temp = this.state.AllContact;
          temp = data.records;
          for (var i = 0; i < temp.length; i++) {
            temp[i].isChecked = false;
            temp[i].id = i + 1;
          }
          this.setState({ AllContact: temp });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  getAllContactByGroup = (group_id) => {
    this.props.doLoading();
    apiAllContactGroupIdNoLimit(group_id)
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          var temp = this.state.AllContact;
          temp = data.records;
          for (var i = 0; i < temp.length; i++) {
            temp[i].isChecked = false;
            temp[i].id = i + 1;
          }
          this.setState({ AllContact: temp });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  doSearchContact = () => {
    let newFilter = this.searchContact.current.value;
    this.setState({
      filter: newFilter,
      tableData: [],
    });

    this.getAllContact(newFilter);
  };

  // handleCheck(e, i) {
  //   let dataParticipants = this.state.AllContact;
  //   for (var j = 0; j < dataParticipants.length; j++) {
  //     if (j === i) {
  //       dataParticipants[j].isChecked = e.target.checked;
  //     } else {
  //       dataParticipants[j].isChecked = false;
  //     }
  //   }
  //   this.setState({
  //     AllContact: dataParticipants,
  //   });
  // }

  handleCheck = (e, i) => {
    let dataParticipants = this.state.AllContact;
    for (var j = 0; j < dataParticipants.length; j++) {
      if (j === i) {
        dataParticipants[j].isChecked = e.target.checked;
      } else {
        dataParticipants[j].isChecked = false;
      }
    }
    this.setState({
      AllContact: dataParticipants,
      checked: dataParticipants[i].isChecked,
    });

    var checkedCopy = this.state.checked;
    checkedCopy[i] = !this.state.checked[i];
    if (checkedCopy[i] === false) {
      this.setState({ selectAll: false });
    }
    this.setState({
      checked: checkedCopy,
    });
  };

  toggleSelectAll(e) {
    let dataParticipants = this.state.AllContact;
    for (let i = 0; i < dataParticipants.length; i++) {
      dataParticipants[i].isChecked = e.target.checked;
    }
    this.setState({
      AllContact: dataParticipants,
      selectAll: e.target.checked,
    });
  }

  handleChangeOptionGroup = (selectedGroupId) => {
    // this.searchContact.current.value = "";
    this.setState({ selectedGroupId });
    this.getAllContactByGroup(selectedGroupId.value);
  };

  pressSendMessagePersonal = () => {
    let newMessage = this.messageText.current.value;
    const result = this.state.AllContact.filter((v) => v.isChecked === true);

    this.setState({
      message: newMessage,
    });

    if (newMessage === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty message?",
        titleBody: "Please write a message.",
      });
    } else if (result.length === 0) {
      this.setState({
        openValidation: true,
        titleValidation: "Select Contact?",
        titleBody: "Select a contact to send the message.",
      });
    } else {
      const result = this.state.AllContact.filter((v) => v.isChecked === true);
      let data = [];
      result.map((obj, i) => {
        data.push(obj.username !== "" ? obj.username : obj.phone);
      });
      this.setState({
        loadingMessage: true,
      });

      let dataAccount = shuffle(this.state.accountShow);
      console.log(dataAccount);
      let getApi = dataAccount;
      if (getApi.length > 0) {
        this.SendMessage(data, getApi[0]);
      }
    }
  };

  pressStop = () => {
    this.setState({
      showLoading: false,
    });
  };

  makeid = (length) => {
    var result = "";
    var characters = "0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  async AddContact(temp) {
    let dataAccount = temp;
    let getApi = dataAccount.filter((obj) => obj.as_default === 1);

    const apiId = getApi[0].api_id;
    const apiHash = getApi[0].api_hash;
    const session = new StringSession(getApi[0].string_token);

    const client = new TelegramClient(session, apiId, apiHash, {
      connectionRetries: 5,
    });

    await client.session.setDC(5, "flora.web.telegram.org", 443);
    await client.connect();
    const result = await client.invoke(
      new Api.contacts.AddContact({
        id: 771910007,
        firstName: "Uci",
        lastName: "Baetulloh",
        phone: "",
        addPhonePrivacyException: true,
      })
    );
    console.log(result);
  }

  async SendMessage(data, getAPI) {
    const apiId = getAPI.api_id;
    const apiHash = getAPI.api_hash;
    const session = new StringSession(getAPI.string_token);
    const client = new TelegramClient(session, apiId, apiHash, {
      connectionRetries: 5,
    });

    let params = {
      peer: data[0],
      message: this.state.message,
      randomId: this.makeid(10),
      silent: false,
    };

    console.log(params);

    await client.session.setDC(5, "flora.web.telegram.org", 443);
    await client.connect();
    await client
      .invoke(new Api.messages.SendMessage(params))
      .then((res) => {
        console.log(res);
        if (res !== undefined || res !== null || res !== "") {
          this.setState({
            openSuccess: true,
            openSuccessText: "Message sent successfully.",
            loadingMessage: false,
          });
        } else {
          this.setState({
            openFailed: true,
            openFailedText: "Message failed to send.",
            loadingMessage: false,
          });
        }
      })
      .catch((err) => {
        console.log(err.errorMessage);
        this.setState({
          openFailed: true,
          openFailedText: err.errorMessage,
          loadingMessage: false,
        });
      });
  }

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth="xs"
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  renderListParticipants = () => {
    if (this.state.AllContact.length > 0) {
      return (
        <List className="list-form">
          <div style={{ marginBottom: 0 }}>
            {this.state.AllContact.map((dt, i) => {
              return (
                <div>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            style={
                              (stylesListComent.inline,
                              { fontSize: 15, color: "#000", fontWeight: 800 })
                            }
                          >
                            {dt.first_name} {dt.last_name}
                          </Typography>
                          <br></br>
                          {dt.username !== "" ? (
                            <Typography
                              component="span"
                              variant="body2"
                              style={
                                (stylesListComent.inline,
                                { fontSize: 15, color: "#000" })
                              }
                            >
                              @{dt.username}
                            </Typography>
                          ) : (
                            <Typography
                              component="span"
                              variant="body2"
                              style={
                                (stylesListComent.inline,
                                { fontSize: 15, color: "#000" })
                              }
                            >
                              {dt.phone}
                            </Typography>
                          )}
                        </React.Fragment>
                      }
                    />
                    <div style={{ alignSelf: "center", textAlign: "center" }}>
                      <ListItemSecondaryAction>
                        <Checkbox
                          style={{
                            width: 50,
                            height: 50,
                            color: "#07213c",
                          }}
                          edge="end"
                          onChange={(e) => this.handleCheck(e, i)}
                          checked={dt.isChecked}
                          inputProps={{
                            "aria-labelledby": "checkbox-list-secondary-label",
                          }}
                        />
                      </ListItemSecondaryAction>
                    </div>
                  </ListItem>
                  <Divider />
                </div>
              );
            })}
          </div>
        </List>
      );
    } else {
      return (
        <List className="list-form">
          <div style={{ marginBottom: 0, padding: 20, textAlign: "center" }}>
            <Typography
              component="span"
              variant="body2"
              style={(stylesListComent.inline, { fontSize: 20, color: "#000" })}
            >
              Contact not available
            </Typography>
          </div>
        </List>
      );
    }
  };

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth="xs"
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  closeStackSuccess = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
    });
  };

  pressReset = () => {
    this.messageText.current.value = "";
    this.setState({
      filter: "",
      message: "",
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      showLoading: false,
      showTextGetParticipants: false,
      selectedGroupId: null,
      selectAll: false,
      openSuccess: false,
      openSuccessText: "",
      openFailed: false,
      openFailedText: "",
    });
  };

  renderSuccess = () => {
    if (this.state.openSuccess === true) {
      setTimeout(() => this.pressReset(), 2000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  // onClick={() => this.reset()}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openSuccessText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  renderFailed = () => {
    if (this.state.openFailed === true) {
      setTimeout(() => this.pressReset(), 2000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="error"
              action={
                <IconButton aria-label="close" color="inherit" size="small">
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openFailedText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  color: "#FFF",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <Typography
                  component="span"
                  variant="h1"
                  style={
                    (stylesListComent.inline,
                    {
                      fontSize: 20,
                      color: "#000",
                      fontWeight: "bold",
                    })
                  }
                >
                  Send Message to Personal
                </Typography>
                <br></br>
                <br></br>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <br></br>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Grid item xs={12}>
                <Paper
                  style={{
                    padding: "10px",
                    backgroundColor: "#fff",
                    // height: "75vh",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Input
                        autoComplete="off"
                        type="textarea"
                        name="message"
                        id="message"
                        rows={5}
                        placeholder="Write a message"
                        innerRef={this.messageText}
                        // onChange={(event) =>
                        //   this.setState({ message: event.target.value })
                        // }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        fullWidth
                        size="large"
                        style={{
                          backgroundColor: "#07213c",
                        }}
                        endIcon={
                          this.state.loadingMessage === true ? null : <Send />
                        }
                        onClick={() => this.pressSendMessagePersonal()}
                      >
                        {this.state.loadingMessage === true ? (
                          <CircularProgress
                            style={{ color: "#fff" }}
                            size={24}
                          />
                        ) : (
                          <Typography
                            variant="button"
                            style={{
                              color: "#fff",
                              textTransform: "uppercase",
                            }}
                          >
                            Send
                          </Typography>
                        )}
                      </Button>{" "}
                    </Grid>
                    <br></br>
                  </Grid>
                </Paper>
                <br></br>
                {this.renderSuccess()}
                {this.renderFailed()}
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid item xs={12}>
                <Paper
                  style={{
                    padding: "10px",
                    backgroundColor: "#fff",
                    // height: "75vh",
                  }}
                >
                  <Typography
                    component="span"
                    variant="h1"
                    style={
                      (stylesListComent.inline,
                      {
                        fontSize: 20,
                        color: "#000",
                        fontWeight: "bold",
                      })
                    }
                  >
                    All Contact
                  </Typography>
                  <br></br>
                  <div className="contentDate">
                    <div style={{ marginRight: 0, width: 200 }}>
                      <Select
                        // isClearable
                        classNamePrefix="select"
                        placeholder="Select For group"
                        value={this.state.selectedGroupId}
                        onChange={this.handleChangeOptionGroup}
                        options={this.state.groupShow}
                      />
                    </div>
                    {/* <div style={{ width: 200 }}>
                      <Input
                        autoComplete="off"
                        type="text"
                        name="filter"
                        id="filter"
                        placeholder="Search contact..."
                        innerRef={this.searchContact}
                      />
                    </div>
                    <div style={{ marginRight: 0, marginLeft: 16 }}>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#358bb0",
                        }}
                        startIcon={<Search />}
                        onClick={() => this.doSearchContact()}
                      >
                        <Typography
                          variant="button"
                          style={{
                            fontSize: 14,
                            color: "#fff",
                            textTransform: "capitalize",
                          }}
                        >
                          Search
                        </Typography>
                      </Button>
                    </div> */}
                  </div>
                  <br></br>
                  <br></br>
                </Paper>
              </Grid>
              {/* <div className="content-item-list">
                {this.renderListParticipants()}

              </div> */}
              <br></br>
              <div className="box-container">
                <ReactTable
                  ref={(r) => (this.reactTable = r)}
                  data={this.state.AllContact}
                  columns={[
                    {
                      Header: (
                        // <Checkbox
                        //   style={{
                        //     width: 30,
                        //     height: 30,
                        //     color: "#3a6cf6",
                        //   }}
                        //   onChange={(e) => this.toggleSelectAll(e)}
                        //   checked={this.state.selectAll}
                        //   inputProps={{
                        //     "aria-labelledby": "checkbox-list-secondary-label",
                        //   }}
                        // />
                        <></>
                      ),
                      Cell: (row) => (
                        <Checkbox
                          style={{
                            width: 30,
                            height: 30,
                            color: "#3a6cf6",
                          }}
                          defaultChecked={this.state.checked[row.index]}
                          checked={this.state.checked[row.index]}
                          onChange={(e) => this.handleCheck(e, row.index)}
                          inputProps={{
                            "aria-labelledby": "checkbox-list-secondary-label",
                          }}
                        />
                      ),
                      headerStyle: { fontWeight: "bold" },
                      sortable: false,
                      filterable: true,
                      style: { textAlign: "center" },
                      width: 100,
                    },
                    {
                      Header: "No",
                      headerStyle: { fontWeight: "bold" },
                      accessor: "id",
                      style: { textAlign: "center" },
                      width: 100,
                    },
                    {
                      Header: "Name",
                      headerStyle: { fontWeight: "bold" },
                      accessor: "full_name",
                      style: { textAlign: "left" },
                    },
                    {
                      Header: "@Username",
                      headerStyle: { fontWeight: "bold" },
                      accessor: "username",
                      style: { textAlign: "center" },
                    },
                    {
                      Header: "Phone Number",
                      headerStyle: { fontWeight: "bold" },
                      accessor: "phone",
                      style: { textAlign: "center" },
                    },
                  ]}
                  style={{ backgroundColor: "#f2f2f2" }}
                  filterable
                  defaultFilterMethod={(filter, row) =>
                    String(row[filter.id])
                      .toLowerCase()
                      .includes(filter.value.toLowerCase())
                  }
                  defaultPageSize={5}
                />
              </div>
            </Grid>
          </Grid>
        </Box>
        {this.renderValidationAlert()}
      </Box>
    );
  }
}
export default SendMessagePersonal;

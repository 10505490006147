import axios from "axios";
import { webserviceurl } from "./baseUrl";

export function apiCameraAddUpdate(body) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "cameraAddUpdate.php",
      data: body,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiCameraList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "cameraList.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiCameraDelete(deviceId) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "cameraDelete.php",
      data: { deviceId: deviceId },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiCameraId(deviceId) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "cameraId.php",
      data: { deviceId: deviceId },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiCameraSelect() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "cameraSelect.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "groupList.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiViewList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "viewList.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAddManageApi(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "telegram_manage_api.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupContactList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "group_list.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupContactInput(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "group_insert_update.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupContactDelete(group_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "group_delete.php",
      data: { group_id: group_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGrabContact(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "grab_contact_insert.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAllContactList(filter) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "all_contact_list.php",
      data: { filter: filter },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAllContactGroupId(group_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "all_contact_group_id.php",
      data: { group_id: group_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAllContactListNoLimit(filter) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "all_contact_list_nolimit.php",
      data: { filter: filter },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAllContactGroupIdNoLimit(group_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "all_contact_group_id_nolimit.php",
      data: { group_id: group_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAllContactDelete(contact_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "all_contact_delete.php",
      data: { contact_id: contact_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiManageApiTelegram() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "manage_api_list.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiManageApiTelegramDelete(manage_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "manage_api_delete.php",
      data: { manage_id: manage_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiManageApiAccount() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "manage_api.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountInsertUpdate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "manage_api_insert.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiJoinGroupInsert(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "join_group_insert.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiJoinGroupList(group_account_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "join_group_list.php",
      data: { group_account_id: group_account_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiJoinGroupDelete(group_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "join_group_delete.php",
      data: { group_id: group_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiManageDeleteAll(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "manage_api_delete_all.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupDeleteAll(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "group_delete_all.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAllContactDeleteAll(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "all_contact_delete_all.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiImportContact(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "import_contact.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupAccountList() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "group_account_list.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupAccountInput(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "group_account_insert_update.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupAccountUpdate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "group_account_update.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupAccountDelete(group_account_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "group_account_delete.php",
      data: { group_account_id: group_account_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupAccountDeleteAll(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "group_account_delete_all.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiAccountTelegram() {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "account_telegram.php",
      data: {},
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiGroupTelegramAccount(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "add_telegram_account_group.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiUpdateAccountBanned(manage_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "update_account_banned.php",
      data: { manage_id: manage_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiUpdateAccountSession(manage_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "update_account_session.php",
      data: { manage_id: manage_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiUpdateAccountFlood(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "update_account_flood.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiUpdateMaxInvite(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "update_account_maxinvite.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiUpdateMaxDate(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "update_account_maxdate.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiUpdateNormalAccount(params) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "update_account_normal.php",
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export function apiUpdateAccountActive(manage_id) {
  try {
    const response = axios({
      method: "post",
      url: webserviceurl + "update_account_active.php",
      data: { manage_id: manage_id },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
}

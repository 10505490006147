import React, { Component } from "react";
import {
  apiGroupContactList,
  apiGrabContact,
  apiManageApiAccount,
  apiGroupAccountList,
  apiUpdateAccountBanned,
  apiUpdateAccountSession,
  apiUpdateAccountFlood,
  apiUpdateAccountActive,
} from "../../Service/api";
import {
  DisconnectedWS,
  GetParticipants,
} from "../../TelegramApi/ClinetApiTelegram";
import { Api, TelegramClient } from "telegram";
import { StringSession } from "telegram/sessions";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  DialogContentText,
  DialogTitle,
  Slide,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemSecondaryAction,
  Checkbox,
  AppBar,
  Toolbar,
  Stack,
  Alert,
} from "@mui/material";
import { Input } from "reactstrap";
import {
  Contacts,
  Close,
  StopCircle,
  Refresh,
  AddBox,
  Save,
  Cancel,
} from "@mui/icons-material";
import Select from "react-select";
import { shuffle, getRoundRobin } from "../../../global";
import moment from "moment";
const stylesListComent = {
  inline: {
    display: "inline",
  },
};

const stylesListDialog = {
  inline: {
    display: "inline",
  },
};

const stylesDialog = {
  appBar: {
    position: "relative",
    backgroundColor: "#07213c",
  },
  title: {
    marginLeft: 0,
    flex: 1,
    fontSize: 16,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class FromGroupPage extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.channelText = React.createRef();
    this.state = {
      tableData: [],
      tableDisplay: [],
      filter: "",
      channel: "",
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      showLoading: false,
      showTextGetParticipants: false,
      masterDataUserParticipants: [],
      arrDataUserParticipants: [],
      countAllParticipants: 0,
      countAllGetParticipants: 0,
      offset: 0,
      limit: 100,
      hash: 0,
      selectAll: false,
      setOpenAddNew: false,
      groupShow: [],
      selectedGroupId: null,
      totalUpload: 0,
      countUserBot: 0,
      countDeleteAccount: 0,
      countActive: 0,
      countOffline: 0,
      selectAll: false,
      checked: [],
      selectRowData: [],
      loadingMessage: false,
      accountShow: [],
      DataGroupAccountTelegram: [],
      selectGroupAccountTelegramId: null,
      totalAccount: 0,
      getRoundRobinIdx: 0,
      loadingUpload: false,
    };
  }

  componentDidMount() {
    this.getDataGroupTelegramAccount();
    this.getDataAccounts();
    this.getDataGroup();
  }

  getDataGroupTelegramAccount = () => {
    apiGroupAccountList()
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          var temp = this.state.tableData;
          temp = data.records;
          for (var i = 0; i < temp.length; i++) {
            temp[i].id = i + 1;
            temp[i].total = temp[i].info_linked.length;
            temp[i].status = temp[i].is_available === 0 ? "Hide" : "Show";
            temp[i].value = temp[i].group_account_id;
            temp[i].label = temp[i].group_account_name;
          }

          if (this.state.selectGroupAccountTelegramId !== null) {
            let updateData = temp.filter(
              (obj) =>
                obj.value === this.state.selectGroupAccountTelegramId.value
            );

            console.log("Mount Data", updateData[0]);

            const format1 = "YYYY-MM-DD HH:mm:ss";
            var date = new Date();
            var DateTimeNow = moment(date).format(format1);

            if (updateData[0].info_linked.length > 0) {
              let dataFilterFlood = updateData[0].info_linked.filter(
                (obj) => obj.status === 4 && obj.flood >= DateTimeNow
              );

              console.log("DATA ACCOUNT FLOOD", dataFilterFlood);

              let dataFloodToActive = updateData[0].info_linked.filter(
                (obj) => obj.status === 4 && obj.flood < DateTimeNow
              );

              console.log("DATA ACCOUNT FLOOD TO ACTIVE", dataFloodToActive);

              if (dataFloodToActive.length > 0) {
                dataFloodToActive.map((obj) => {
                  return this.doUpdateAccountActive(obj.manage_id);
                });
              }

              let dataUnFlood = updateData[0].info_linked.filter(
                (val) => !dataFilterFlood.includes(val)
              );
              console.log("DATA ACCOUNT UNFLOOD", dataUnFlood);

              let arrGroupAccount = [];
              arrGroupAccount.push(updateData[0]);
              for (var i = 0; i < arrGroupAccount.length; i++) {
                arrGroupAccount[i].info_linked = dataUnFlood;
              }

              console.log("REAL ACCOUNT", arrGroupAccount[0]);

              this.setState({
                selectGroupAccountTelegramId: arrGroupAccount[0],
                DataGroupAccountTelegram: temp,
              });
            } else {
              console.log("normal");
              this.setState({
                selectGroupAccountTelegramId: updateData[0],
                DataGroupAccountTelegram: temp,
              });
            }
          } else {
            console.log("Mount Data", temp[0]);

            const format1 = "YYYY-MM-DD HH:mm:ss";
            var date = new Date();
            var DateTimeNow = moment(date).format(format1);

            if (temp[0].info_linked.length > 0) {
              let dataFilterFlood = temp[0].info_linked.filter(
                (obj) => obj.status === 4 && obj.flood >= DateTimeNow
              );

              console.log("DATA ACCOUNT FLOOD", dataFilterFlood);

              let dataFloodToActive = temp[0].info_linked.filter(
                (obj) => obj.status === 4 && obj.flood < DateTimeNow
              );

              console.log("DATA ACCOUNT FLOOD TO ACTIVE", dataFloodToActive);

              if (dataFloodToActive.length > 0) {
                dataFloodToActive.map((obj) => {
                  return this.doUpdateAccountActive(obj.manage_id);
                });
              }

              let dataUnFlood = temp[0].info_linked.filter(
                (val) => !dataFilterFlood.includes(val)
              );
              console.log("DATA ACCOUNT UNFLOOD", dataUnFlood);

              let arrGroupAccount = [];
              arrGroupAccount.push(temp[0]);
              for (var i = 0; i < arrGroupAccount.length; i++) {
                arrGroupAccount[i].info_linked = dataUnFlood;
              }

              console.log("REAL ACCOUNT", arrGroupAccount[0]);

              this.setState({
                selectGroupAccountTelegramId: arrGroupAccount[0],
                DataGroupAccountTelegram: temp,
              });
            } else {
              console.log("normal");
              this.setState({
                selectGroupAccountTelegramId: temp[0],
                DataGroupAccountTelegram: temp,
              });
            }

            // this.setState({
            //   DataGroupAccountTelegram: temp,
            //   selectGroupAccountTelegramId: temp[0],
            // });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doUpdateAccountActive = (manage_id) => {
    apiUpdateAccountActive(manage_id)
      .then((res) => {
        // console.log(res);
        let data = res.data;
        if (data.status === "OK") {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doUpdateSession = (manage_id) => {
    apiUpdateAccountSession(manage_id)
      .then((res) => {
        console.log(res);
        let data = res.data;
        if (data.status === "OK") {
          this.getDataGroupTelegramAccount();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doUpdateBanned = (manage_id) => {
    apiUpdateAccountBanned(manage_id)
      .then((res) => {
        console.log(res);
        let data = res.data;
        if (data.status === "OK") {
          this.getDataGroupTelegramAccount();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doUpdateFlood = (manage_id, flood_time) => {
    let params = {
      manage_id: manage_id,
      flood_time: flood_time,
    };
    apiUpdateAccountFlood(params)
      .then((res) => {
        console.log(res);
        let data = res.data;
        if (data.status === "OK") {
          this.getDataGroupTelegramAccount();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getDataAccounts = () => {
    apiManageApiAccount()
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          var temp = this.state.accountShow;
          temp = data.records;
          for (var i = 0; i < temp.length; i++) {
            temp[i].value = temp[i].manage_id;
            temp[i].label = temp[i].alias;
          }
          this.setState({ accountShow: temp });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getDataGroup = () => {
    apiGroupContactList()
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          var temp = this.state.groupShow;
          temp = data.records;
          for (var i = 0; i < temp.length; i++) {
            temp[i].value = temp[i].group_id;
            temp[i].label = temp[i].group_name;
          }
          this.setState({ groupShow: temp });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  checkData = () => {
    const { selectedGroupId } = this.state;
    if (selectedGroupId === null) {
      this.setState({
        openValidation: true,
        titleValidation: "Group empty?",
        titleBody: "Select group to upload database.",
      });
    } else {
      this.doSubmitParticipants();
    }
  };

  doSubmitParticipants = () => {
    const result = this.state.arrDataUserParticipants.filter(
      (v) => v.isChecked === true
    );

    let params = {
      group_id: this.state.selectedGroupId.value,
      arrDataParticipantsSelected: result,
    };
    this.setState({
      loadingUpload: true,
    });
    this.props.doLoading();
    apiGrabContact(params)
      .then((res) => {
        this.setState({
          loadingUpload: false,
        });
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            setOpenAddNew: false,
            selectedGroupId: null,
            openSuccess: true,
            openSuccessText: "Data saved successfully",
          });
          this.setState({
            showLoading: false,
            showTextGetParticipants: false,
            countUserBot: 0,
            countDeleteAccount: 0,
            countActive: 0,
            countOffline: 0,
            masterDataUserParticipants: [],
            arrDataUserParticipants: [],
            countAllParticipants: 0,
            countAllGetParticipants: 0,
            offset: 0,
            limit: 100,
            hash: 0,
            selectAll: false,
            setOpenAddNew: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          loadingUpload: false,
        });
        this.props.doLoading();
        console.log(err);
      });
  };

  pressGetParticipants = () => {
    let newChannel = this.channelText.current.value;
    if (newChannel === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty link group telegram?",
        titleBody: "Please enter link group telegram.",
      });
    } else {
      let dataAccount = this.state.selectGroupAccountTelegramId;
      if (dataAccount.info_linked.length > 0) {
        this.setState({
          showLoading: true,
          showTextGetParticipants: true,
          countUserBot: 0,
          countDeleteAccount: 0,
          countActive: 0,
          countOffline: 0,
          masterDataUserParticipants: [],
          arrDataUserParticipants: [],
          countAllParticipants: 0,
          countAllGetParticipants: 0,
          offset: 0,
          limit: 100,
          hash: 0,
          selectAll: false,
          setOpenAddNew: false,
        });

        let totalAccount = dataAccount.info_linked.length;
        this.setState({
          totalAccount: totalAccount,
        });

        let DataAccountTelegram = dataAccount.info_linked.sort((a, b) => {
          if (a.manage_id < b.manage_id) return -1;
          if (a.manage_id > b.manage_id) return 1;
          return 0;
        });
        let getApi = getRoundRobin(0, DataAccountTelegram);
        console.log(getApi);
        if (DataAccountTelegram.length > 0) {
          // this.doGetParticipants(newChannel, getApi[0], DataAccountTelegram);
          this.doGetParticipants(newChannel);
        }
      } else {
        this.setState({
          openValidation: true,
          titleValidation: "Empty group account telegram",
          titleBody: "Telegram account not available",
        });
        this.setState({
          submitLoading: false,
        });
      }
    }
  };

  pressReset = () => {
    this.channelText.current.value = "";
    this.setState({ openSuccess: false, openSuccessText: "" });
    this.getDataGroup();
  };

  pressStop = () => {
    this.setState({
      showLoading: false,
    });
  };

  doGetParticipants = (newChannel) => {
    let dataAccount = this.state.selectGroupAccountTelegramId;

    console.log(dataAccount);

    if (dataAccount.info_linked.length > 0) {
      let totalAccount = dataAccount.info_linked.length;
      this.setState({
        totalAccount: totalAccount,
      });

      let DataAccountTelegram = dataAccount.info_linked.sort((a, b) => {
        if (a.manage_id < b.manage_id) return -1;
        if (a.manage_id > b.manage_id) return 1;
        return 0;
      });
      let getApi = getRoundRobin(
        this.state.getRoundRobinIdx,
        DataAccountTelegram
      );
      var getAPI = getApi[0];
      console.log(getAPI);

      if (totalAccount === this.state.getRoundRobinIdx) {
        this.setState({
          getRoundRobinIdx: 0,
        });
      } else {
        this.setState({
          getRoundRobinIdx: this.state.getRoundRobinIdx + 1,
        });
      }

      console.log(
        "Account Telegram:",
        getAPI.alias === "" ? getAPI.phonenumber : getAPI.alias
      );

      let offset = this.state.offset;
      let limit = this.state.limit;
      let hash = this.state.hash;

      let params = {
        offset: offset,
        limit: limit,
        hash: hash,
      };
      console.log(params);

      GetParticipants(getAPI, newChannel, offset, limit, hash)
        .then((res) => {
          console.log(res);
          this.doDisconnectedTelegram(getAPI);
          if (res !== undefined || res !== null || res !== "") {
            let getData = this.state.masterDataUserParticipants;
            let newData = res.users.concat(getData);
            this.setState({
              masterDataUserParticipants: newData,
            });
            let dataParticipants = newData;
            let dataUsetDeleted = dataParticipants.filter(
              (obj, i) => obj.deleted === true
            );
            this.setState({
              countDeleteAccount: dataUsetDeleted.length,
            });
            let total_user_bot = dataParticipants.filter(
              (obj, i) => obj.bot === true
            );
            this.setState({
              countUserBot: total_user_bot.length,
            });
            let dataUserActive = dataParticipants.filter(
              (obj, i) => obj.deleted !== true && obj.status !== null
            );
            let total_user_current_online = dataUserActive.filter(
              (obj, i) =>
                obj.status.className === "UserStatusRecently" ||
                obj.status.className === "UserStatusOnline"
            );
            this.setState({
              countActive: total_user_current_online.length,
            });
            let total_user_offline = dataUserActive.filter(
              (obj, i) => obj.status.className === "UserStatusOffline"
            );
            this.setState({
              countOffline: total_user_offline.length,
            });
            var GetParticipantsList = [];
            for (var i = 0; i < dataUserActive.length; i++) {
              var obj_item = {};
              obj_item.user_id = dataUserActive[i].id;
              obj_item.className = dataUserActive[i].className;
              obj_item.firstName = dataUserActive[i].firstName;
              obj_item.lastName = dataUserActive[i].lastName;
              obj_item.phone = dataUserActive[i].phone;
              obj_item.username = dataUserActive[i].username;
              obj_item.accessHash = dataUserActive[i].accessHash;
              obj_item.isChecked = true;
              GetParticipantsList.push(obj_item);
            }
            var selectAll = !this.state.selectAll;
            this.setState({ selectAll: selectAll });
            var checkedCopy = [];
            GetParticipantsList.forEach(function (e, index) {
              checkedCopy.push(selectAll);
            });
            this.setState({
              checked: checkedCopy,
            });

            this.setState({
              arrDataUserParticipants: GetParticipantsList,
              countAllGetParticipants: GetParticipantsList.length,
              countAllParticipants:
                this.state.countAllParticipants !== 0
                  ? this.state.countAllParticipants
                  : res.count,
            });

            // let offsets = res.users.length !== 0 ? res.users.length : 1;

            // let newOffset = this.state.offset + offsets;

            let newOffset = this.state.offset + res.users.length;

            console.log(newOffset);
            console.log(
              this.state.countAllParticipants !== 0
                ? this.state.countAllParticipants
                : res.count
            );
            console.log(res.count);

            let AllParticipans =
              this.state.countAllParticipants !== 0
                ? this.state.countAllParticipants
                : res.count;

            this.setState({
              offset: newOffset,
            });
            if (newOffset > res.count) {
              console.log("selesai");

              setTimeout(() => {
                this.setState({ showLoading: false });
              }, 5000);
            } else {
              console.log("lanjut");
              if (this.state.showLoading === true) {
                setTimeout(() => {
                  this.doGetParticipants(newChannel);
                }, 5000);
              }
            }
          }
        })
        .catch((err) => {
          console.log(err.toString());
          console.log(err);
          console.log(err.errorMessage);
          this.doDisconnectedTelegram(getAPI);
          if (
            err.toString() ===
            "RPCError: 406: AUTH_KEY_DUPLICATED (caused by InvokeWithLayer)"
          ) {
            this.doUpdateSession(getAPI.manage_id);
            this.setState({
              openValidation: true,
              titleValidation: "Error Code " + err.toString(),
              titleBody:
                "account" + getAPI.alias === ""
                  ? getAPI.phonenumber
                  : getAPI.alias + "must be updated session",
            });
          } else {
            if (err.code === 401) {
              this.doUpdateBanned(getAPI.manage_id);
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody:
                  "The temporary auth key must be binded to the permanent auth key to use these methods.",
              });
              // this.handleCloseAddNew();
            } else if (err.code === 406) {
              this.doUpdateSession(getAPI.manage_id);
              this.setState({
                openValidation: true,
                titleValidation: "Error Code " + err.toString(),
                titleBody:
                  "account" + getAPI.alias === ""
                    ? getAPI.phonenumber
                    : getAPI.alias + "must be updated session",
              });
              console.log("keluar dari rotasi");
            } else if (err.code === 420) {
              const format1 = "YYYY-MM-DD HH:mm:ss";
              let dt = new Date();
              dt.setSeconds(dt.getSeconds() + 43200);
              let DateAddNow = moment(dt).format(format1);
              console.log(DateAddNow);
              this.doUpdateFlood(getAPI.manage_id, DateAddNow);
              this.setState({
                openValidation: true,
                titleValidation: "Error Code " + err.code,
                titleBody:
                  err.toString() + " " + getAPI.alias !== ""
                    ? getAPI.alias
                    : getAPI.phonenumber,
              });
              // if (err.errorMessage === "FLOOD") {
              //   const format1 = "YYYY-MM-DD HH:mm:ss";
              //   let dt = new Date();
              //   dt.setSeconds(dt.getSeconds() + 43200);
              //   let DateAddNow = moment(dt).format(format1);
              //   console.log(DateAddNow);
              //   this.doUpdateFlood(getAPI.manage_id, DateAddNow);
              //   this.setState({
              //     openValidation: true,
              //     titleValidation: "Error Code " + err.code,
              //     titleBody:
              //       err.toString() + " " + getAPI.alias !== ""
              //         ? getAPI.alias
              //         : getAPI.phonenumber,
              //   });
              // } else {
              //   const format1 = "YYYY-MM-DD HH:mm:ss";
              //   let dt = new Date();
              //   dt.setSeconds(dt.getSeconds() + 43200);
              //   let DateAddNow = moment(dt).format(format1);
              //   console.log(DateAddNow);
              //   this.doUpdateFlood(getAPI.manage_id, DateAddNow);
              //   this.setState({
              //     openValidation: true,
              //     titleValidation: "Error Code " + err.code,
              //     titleBody:
              //       err.toString() + " " + getAPI.alias !== ""
              //         ? getAPI.alias
              //         : getAPI.phonenumber,
              //   });
              // }
            } else if (err.code === 400) {
              if (err.errorMessage === "CHANNEL_INVALID") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "The provided channel is invalid.",
                });
              } else if (err.errorMessage === "CHANNEL_PRIVATE") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "You haven't joined this channel/supergroup.",
                });
              } else if (err.errorMessage === "CHAT_ADMIN_REQUIRED") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "You must be an admin in this chat to do this.",
                });
              } else if (err.errorMessage === "INPUT_CONSTRUCTOR_INVALID") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "The provided constructor is invalid.",
                });
              }
            } else if (err.code === undefined) {
              this.setState({
                openValidation: true,
                titleValidation: "Error Code Channel/group is invalid",
                titleBody: "Channel/group not available.",
              });
            } else {
              this.setState({
                openValidation: true,
                titleValidation: "Error Code " + err.code,
                titleBody: err.errorMessage,
              });
            }
          }
        });
    }
  };

  doDisconnectedTelegram = (getApi) => {
    DisconnectedWS(getApi)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  async GetParticipants(newChannel, getAPI, dataAccount) {
    if (this.state.totalAccount === this.state.getRoundRobinIdx) {
      this.setState({
        getRoundRobinIdx: 0,
      });
    } else {
      this.setState({
        getRoundRobinIdx: this.state.getRoundRobinIdx + 1,
      });
    }

    console.log(
      "Account Telegram:",
      getAPI.alias === "" ? getAPI.phonenumber : getAPI.alias
    );

    const apiId = getAPI.api_id;
    const apiHash = getAPI.api_hash;
    const session = new StringSession(getAPI.string_token);
    const client = new TelegramClient(session, apiId, apiHash, {
      connectionRetries: 5,
    });
    await client.session.setDC(5, "flora.web.telegram.org", 443);
    await client.connect();
    let params = {
      channel: newChannel,
      filter: new Api.ChannelParticipantsRecent({}),
      offset: this.state.offset,
      limit: this.state.limit,
      hash: this.state.hash,
    };
    console.log(params);
    await client
      .invoke(new Api.channels.GetParticipants(params))
      .then((res) => {
        console.log(res);
        if (res !== undefined || res !== null || res !== "") {
          // console.log("GetParticipants:", res);
          let getData = this.state.masterDataUserParticipants;
          // console.log("lama: ", getData);
          // console.log("baru: ", res.users);
          let newData = res.users.concat(getData);
          this.setState({
            masterDataUserParticipants: newData,
          });

          let dataParticipants = newData;
          // console.log("total data:", dataParticipants.length);

          let dataUsetDeleted = dataParticipants.filter(
            (obj, i) => obj.deleted === true
          );
          // console.log("total user delete account: ", dataUsetDeleted.length);

          this.setState({
            countDeleteAccount: dataUsetDeleted.length,
          });

          let total_user_bot = dataParticipants.filter(
            (obj, i) => obj.bot === true
          );
          // console.log("total user bot: ", total_user_bot.length);

          this.setState({
            countUserBot: total_user_bot.length,
          });

          let dataUserActive = dataParticipants.filter(
            (obj, i) => obj.deleted !== true && obj.status !== null
          );
          // console.log("total user active: ", dataUserActive);

          let total_user_current_online = dataUserActive.filter(
            (obj, i) =>
              obj.status.className === "UserStatusRecently" ||
              obj.status.className === "UserStatusOnline"
          );
          // console.log("total user online: ", total_user_current_online.length);

          this.setState({
            countActive: total_user_current_online.length,
          });

          let total_user_offline = dataUserActive.filter(
            (obj, i) => obj.status.className === "UserStatusOffline"
          );
          // console.log("total user offline: ", total_user_offline.length);

          this.setState({
            countOffline: total_user_offline.length,
          });

          var GetParticipantsList = [];
          for (var i = 0; i < dataUserActive.length; i++) {
            var obj_item = {};
            obj_item.user_id = dataUserActive[i].id;
            obj_item.className = dataUserActive[i].className;
            obj_item.firstName = dataUserActive[i].firstName;
            obj_item.lastName = dataUserActive[i].lastName;
            obj_item.phone = dataUserActive[i].phone;
            obj_item.username = dataUserActive[i].username;
            obj_item.accessHash = dataUserActive[i].accessHash;
            obj_item.isChecked = true;
            GetParticipantsList.push(obj_item);
          }
          // console.log("result akhir:", GetParticipantsList);

          var selectAll = !this.state.selectAll;
          this.setState({ selectAll: selectAll });
          var checkedCopy = [];
          GetParticipantsList.forEach(function (e, index) {
            checkedCopy.push(selectAll);
          });
          this.setState({
            checked: checkedCopy,
          });

          this.setState({
            arrDataUserParticipants: GetParticipantsList,
            countAllGetParticipants: GetParticipantsList.length,
            countAllParticipants: res.count,
          });

          let newOffset = this.state.offset + res.users.length;
          // console.log("new offset: ", newOffset);
          this.setState({
            offset: newOffset,
          });
          if (newOffset >= res.count) {
            setTimeout(() => {
              this.setState({ showLoading: false });
            }, 3000);
          } else {
            if (this.state.showLoading === true) {
              // let dataAccountNew = this.state.selectGroupAccountTelegramId;
              // if (dataAccountNew.info_linked.length > 0) {
              // let getApiNew = shuffle(dataAccountNew.info_linked);
              let getApiNew = getRoundRobin(
                this.state.getRoundRobinIdx,
                dataAccount
              );
              // console.log(getApiNew);
              if (getApiNew.length > 0) {
                setTimeout(() => {
                  this.GetParticipants(newChannel, getApiNew[0], dataAccount);
                }, 5000);
              }
              // }
            }
          }
        }
      })
      .catch((err) => {
        this.setState({ showLoading: false });
        console.log(err.code);
        console.log(err.errorMessage);
        if (err.code === 400) {
          if (err.errorMessage === "CHANNEL_INVALID") {
            this.setState({
              openValidation: true,
              titleValidation:
                "Error Code " + err.code + ": " + err.errorMessage,
              titleBody: "The provided channel is invalid.",
            });
          } else if (err.errorMessage === "CHANNEL_PRIVATE") {
            this.setState({
              openValidation: true,
              titleValidation:
                "Error Code " + err.code + ": " + err.errorMessage,
              titleBody: "You haven't joined this channel/supergroup.",
            });
          } else if (err.errorMessage === "CHAT_ADMIN_REQUIRED") {
            this.setState({
              openValidation: true,
              titleValidation:
                "Error Code " + err.code + ": " + err.errorMessage,
              titleBody: "You must be an admin in this chat to do this.",
            });
          } else if (err.errorMessage === "INPUT_CONSTRUCTOR_INVALID") {
            this.setState({
              openValidation: true,
              titleValidation:
                "Error Code " + err.code + ": " + err.errorMessage,
              titleBody: "The provided constructor is invalid.",
            });
          }
        } else if (err.code === undefined) {
          this.setState({
            openValidation: true,
            titleValidation: "Error Code Channel/group is invalid",
            titleBody: "Channel/group not available.",
          });
        } else {
          this.setState({
            openValidation: true,
            titleValidation: "Error Code " + err.code,
            titleBody: err.errorMessage,
          });
        }
      });
  }

  toggleSelectAll(e) {
    let tmp = this.state.arrDataUserParticipants;
    for (let i = 0; i < tmp.length; i++) {
      tmp[i].isChecked = e.target.checked;
    }
    this.setState({
      arrDataUserParticipants: tmp,
      selectAll: e.target.checked,
    });
    var selectAll = !this.state.selectAll;
    this.setState({ selectAll: selectAll });
    var checkedCopy = [];
    tmp.forEach(function (e, index) {
      checkedCopy.push(selectAll);
    });
    this.setState({
      checked: checkedCopy,
    });
  }

  handleCheck = (e, i) => {
    let tmp = this.state.arrDataUserParticipants;
    tmp[i].isChecked = e.target.checked;
    this.setState({ arrDataUserParticipants: tmp });
    this.setState({ checked: tmp[i].isChecked });

    var checkedCopy = this.state.checked;
    checkedCopy[i] = !this.state.checked[i];
    if (checkedCopy[i] === false) {
      this.setState({ selectAll: false });
    }
    this.setState({
      checked: checkedCopy,
    });
  };

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth="xs"
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  renderListParticipants = () => {
    if (this.state.arrDataUserParticipants.length > 0) {
      return (
        <List className="list-form">
          <div style={{ marginBottom: 0 }}>
            {this.state.arrDataUserParticipants.map((dt, i) => {
              return (
                <div>
                  <ListItem alignItems="flex-start" button>
                    <ListItemText
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            style={
                              (stylesListComent.inline,
                              { fontSize: 15, color: "#000", fontWeight: 800 })
                            }
                          >
                            {dt.firstName} {dt.lastName}
                          </Typography>
                          <br></br>

                          {dt.phone !== null ? (
                            <Typography
                              component="span"
                              variant="body2"
                              style={
                                (stylesListComent.inline,
                                { fontSize: 15, color: "#000" })
                              }
                            >
                              {dt.phone}
                            </Typography>
                          ) : dt.username !== null ? (
                            <Typography
                              component="span"
                              variant="body2"
                              style={
                                (stylesListComent.inline,
                                { fontSize: 15, color: "#000" })
                              }
                            >
                              @{dt.username}
                            </Typography>
                          ) : null}
                        </React.Fragment>
                      }
                    />
                    <div style={{ alignSelf: "center", textAlign: "center" }}>
                      <ListItemSecondaryAction>
                        <Checkbox
                          style={{
                            width: 50,
                            height: 50,
                            color: "#07213c",
                          }}
                          edge="end"
                          onChange={(e) => this.handleCheck(e, i)}
                          checked={dt.isChecked}
                          inputProps={{
                            "aria-labelledby": "checkbox-list-secondary-label",
                          }}
                        />
                      </ListItemSecondaryAction>
                    </div>
                  </ListItem>
                  <Divider />
                </div>
              );
            })}
          </div>
        </List>
      );
    }
  };

  pressUploadtoDatabase = () => {
    const result = this.state.arrDataUserParticipants.filter(
      (v) => v.isChecked === true
    );
    let count = result.length;

    if (result.length > 0) {
      this.setState({
        setOpenAddNew: true,
        totalUpload: count,
      });
    } else {
      this.setState({
        openValidation: true,
        titleValidation: "Empty Participants?",
        titleBody: "Select participants.",
      });
    }
  };

  handleCloseAddNew = () => {
    this.setState({
      setOpenAddNew: false,
    });
  };

  handleChangeOptionGroup = (selectedGroupId) => {
    this.setState({ selectedGroupId });
  };

  renderDialogAddNew = () => {
    return (
      <Dialog
        onClose={this.handleCloseAddNew}
        aria-labelledby="customized-dialog-title"
        open={this.state.setOpenAddNew}
        fullWidth="md"
        maxWidth="md"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography variant="h5" style={stylesDialog.title}>
              Upload Participants to Database
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseAddNew()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers style={{ height: "300px" }}>
          <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 60, marginTop: 20 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  component="span"
                  variant="h1"
                  style={
                    (stylesListComent.inline,
                    {
                      fontSize: 20,
                      color: "#000",
                      fontWeight: "bold",
                    })
                  }
                >
                  Total Participants Selected : {this.state.totalUpload}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    // fontSize: 16,
                    float: "left",
                    marginTop: 6,
                    color: "#000",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  to Group
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Select
                  // isClearable
                  classNamePrefix="select"
                  placeholder="Select For group"
                  value={this.state.selectedGroupId}
                  onChange={this.handleChangeOptionGroup}
                  options={this.state.groupShow}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="medium"
            style={{
              backgroundColor: "#d0021b",
            }}
            startIcon={<Cancel />}
            onClick={() => this.handleCloseAddNew()}
          >
            <Typography
              variant="button"
              style={{
                fontSize: 12,
                color: "#fff",
                textTransform: "capitalize",
                marginLeft: -6,
              }}
            >
              Cancel
            </Typography>
          </Button>
          &nbsp;&nbsp;
          <Button
            disabled={this.state.loadingUpload}
            variant="contained"
            size="medium"
            style={{
              backgroundColor: "#004dcf",
            }}
            startIcon={<Save />}
            onClick={() => this.checkData()}
          >
            {this.state.loadingUpload === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  fontSize: 12,
                  color: "#fff",
                  textTransform: "capitalize",
                  marginLeft: -6,
                }}
              >
                Save
              </Typography>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  closeStackSuccess = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
    });
  };

  renderSuccess = () => {
    if (this.state.openSuccess === true) {
      setTimeout(() => this.pressReset(), 2000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  // onClick={() => this.reset()}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openSuccessText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  handleChangeAccount = (selectGroupAccountTelegramId) => {
    const format1 = "YYYY-MM-DD HH:mm:ss";
    var date = new Date();
    var DateTimeNow = moment(date).format(format1);

    if (selectGroupAccountTelegramId.info_linked.length > 0) {
      let dataFilterFlood = selectGroupAccountTelegramId.info_linked.filter(
        (obj) => obj.status === 4 && obj.flood >= DateTimeNow
      );

      console.log("DATA ACCOUNT FLOOD", dataFilterFlood);

      let dataFloodToActive = selectGroupAccountTelegramId.info_linked.filter(
        (obj) => obj.status === 4 && obj.flood < DateTimeNow
      );

      console.log("DATA ACCOUNT FLOOD TO ACTIVE", dataFloodToActive);

      if (dataFloodToActive.length > 0) {
        dataFloodToActive.forEach((obj) => {
          console.log(obj);
          return this.doUpdateAccountActive(obj.manage_id);
        });
      }

      let dataUnFlood = selectGroupAccountTelegramId.info_linked.filter(
        (val) => !dataFilterFlood.includes(val)
      );
      console.log("DATA ACCOUNT UNFLOOD", dataUnFlood);

      let arrGroupAccount = [];
      arrGroupAccount.push(selectGroupAccountTelegramId);
      for (var i = 0; i < arrGroupAccount.length; i++) {
        arrGroupAccount[i].info_linked = dataUnFlood;
      }

      console.log("REAL ACCOUNT", arrGroupAccount[0]);

      this.setState({ selectGroupAccountTelegramId: arrGroupAccount[0] });
    } else {
      console.log("normal");
      this.setState({ selectGroupAccountTelegramId });
    }
    this.setState({
      showLoading: false,
      showTextGetParticipants: false,
      countUserBot: 0,
      countDeleteAccount: 0,
      countActive: 0,
      countOffline: 0,
      masterDataUserParticipants: [],
      arrDataUserParticipants: [],
      countAllParticipants: 0,
      countAllGetParticipants: 0,
      offset: 0,
      limit: 100,
      hash: 0,
      selectAll: false,
      setOpenAddNew: false,
    });
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  color: "#FFF",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <Typography
                  component="span"
                  variant="h1"
                  style={
                    (stylesListComent.inline,
                    {
                      fontSize: 20,
                      color: "#000",
                      fontWeight: "bold",
                    })
                  }
                >
                  Grab Contact From Group
                </Typography>
                <br></br>
                <br></br>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <br></br>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Grid item xs={12}>
                <Paper
                  style={{
                    padding: "10px",
                    backgroundColor: "#fff",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div
                        style={{
                          marginRight: 16,
                          width: "100%",
                          position: "relative",
                          zIndex: 2,
                        }}
                      >
                        <Select
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          classNamePrefix="select"
                          placeholder="Select a group account telegram"
                          value={this.state.selectGroupAccountTelegramId}
                          onChange={this.handleChangeAccount}
                          options={this.state.DataGroupAccountTelegram}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        autoComplete="off"
                        type="text"
                        name="channel"
                        id="channel"
                        placeholder="Enter link group telegram"
                        innerRef={this.channelText}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        disabled={this.state.showLoading}
                        variant="contained"
                        fullWidth
                        size="large"
                        style={{
                          backgroundColor: "#07213c",
                        }}
                        startIcon={<Contacts />}
                        onClick={() => this.pressGetParticipants()}
                      >
                        {this.state.showLoading === true ? (
                          <CircularProgress
                            style={{ color: "#fff" }}
                            size={24}
                          />
                        ) : (
                          <Typography
                            variant="button"
                            style={{
                              color: "#fff",
                              textTransform: "uppercase",
                            }}
                          >
                            Get Participants
                          </Typography>
                        )}
                      </Button>{" "}
                    </Grid>
                    <br></br>
                    <Grid item xs={12}>
                      {this.state.showTextGetParticipants === true ? (
                        <>
                          <div className="left-result">
                            <div style={{ marginTop: 20, marginBottom: 20 }}>
                              <Typography
                                variant="button"
                                style={{
                                  fontSize: 16,
                                  color: "#07213c",
                                  textTransform: "uppercase",
                                  fontWeight: "bold",
                                }}
                              >
                                Total Participants :{" "}
                                {this.state.countAllParticipants}
                              </Typography>
                              <br></br>
                              <Typography
                                variant="button"
                                style={{
                                  fontSize: 16,
                                  color: "#07213c",
                                  textTransform: "uppercase",
                                  fontWeight: "bold",
                                }}
                              >
                                Total Get Participants :{" "}
                                {this.state.countAllGetParticipants}
                              </Typography>

                              <br></br>
                              <Typography
                                variant="button"
                                style={{
                                  fontSize: 16,
                                  color: "#07213c",
                                  textTransform: "uppercase",
                                  fontWeight: "bold",
                                }}
                              >
                                Total User Bot : {this.state.countUserBot}
                              </Typography>
                              <br></br>
                              <Typography
                                variant="button"
                                style={{
                                  fontSize: 16,
                                  color: "#07213c",
                                  textTransform: "uppercase",
                                  fontWeight: "bold",
                                }}
                              >
                                Total Delete Account :{" "}
                                {this.state.countDeleteAccount}
                              </Typography>
                              <br></br>
                              <Typography
                                variant="button"
                                style={{
                                  fontSize: 16,
                                  color: "#07213c",
                                  textTransform: "uppercase",
                                  fontWeight: "bold",
                                }}
                              >
                                Total User Online : {this.state.countActive}
                              </Typography>
                              <br></br>
                              <Typography
                                variant="button"
                                style={{
                                  fontSize: 16,
                                  color: "#07213c",
                                  textTransform: "uppercase",
                                  fontWeight: "bold",
                                }}
                              >
                                Total User Offline : {this.state.countOffline}
                              </Typography>
                            </div>
                          </div>
                          <div className="center-loader">
                            {this.state.showLoading === true ? (
                              <>
                                <CircularProgress
                                  style={{ color: "#07213c" }}
                                  size={80}
                                />
                                <div style={{ marginTop: 20 }}>
                                  <Button
                                    variant="contained"
                                    // fullWidth
                                    style={{
                                      backgroundColor: "#07213c",
                                    }}
                                    startIcon={<StopCircle />}
                                    onClick={() => this.pressStop()}
                                  >
                                    <Typography
                                      variant="button"
                                      style={{
                                        fontSize: 12,
                                        color: "#fff",
                                        textTransform: "uppercase",
                                      }}
                                    >
                                      Stop {this.state.countAllGetParticipants}{" "}
                                      / {this.state.countAllParticipants}
                                    </Typography>
                                  </Button>
                                </div>
                              </>
                            ) : null}
                          </div>
                        </>
                      ) : null}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid item xs={12}>
                <Paper
                  style={{
                    padding: "10px",
                    backgroundColor: "#fff",
                    // height: "75vh",
                  }}
                >
                  <Typography
                    component="span"
                    variant="h1"
                    style={
                      (stylesListComent.inline,
                      {
                        fontSize: 20,
                        color: "#000",
                        fontWeight: "bold",
                      })
                    }
                  >
                    List Participants
                  </Typography>
                  <br></br>
                  <div className="contentDate">
                    <div style={{ marginRight: 0 }}>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#008b02",
                        }}
                        startIcon={<AddBox />}
                        onClick={() => this.pressUploadtoDatabase()}
                      >
                        <Typography
                          variant="button"
                          style={{
                            fontSize: 14,
                            color: "#fff",
                            textTransform: "capitalize",
                          }}
                        >
                          Upload to Database
                        </Typography>
                      </Button>
                    </div>
                  </div>
                  <br></br>
                  <br></br>
                </Paper>
              </Grid>
              <br></br>
              <div className="box-container">
                <ReactTable
                  ref={(r) => (this.reactTable = r)}
                  data={this.state.arrDataUserParticipants}
                  columns={[
                    {
                      Header: (
                        <Checkbox
                          style={{
                            width: 30,
                            height: 30,
                            color: "#3a6cf6",
                          }}
                          onChange={(e) => this.toggleSelectAll(e)}
                          checked={this.state.selectAll}
                          inputProps={{
                            "aria-labelledby": "checkbox-list-secondary-label",
                          }}
                        />
                      ),
                      Cell: (row) => (
                        <Checkbox
                          style={{
                            width: 30,
                            height: 30,
                            color: "#3a6cf6",
                          }}
                          defaultChecked={this.state.checked[row.index]}
                          checked={this.state.checked[row.index]}
                          onChange={(e) => this.handleCheck(e, row.index)}
                          inputProps={{
                            "aria-labelledby": "checkbox-list-secondary-label",
                          }}
                        />
                      ),
                      headerStyle: { fontWeight: "bold" },
                      sortable: false,
                      filterable: true,
                      style: { textAlign: "center" },
                      width: 100,
                    },
                    {
                      Header: "Name",
                      headerStyle: { fontWeight: "bold" },
                      accessor: "firstName",
                      style: { textAlign: "left" },
                    },
                    {
                      Header: "@username",
                      headerStyle: { fontWeight: "bold" },
                      accessor: "username",
                      style: { textAlign: "center" },
                    },
                    {
                      Header: "Phone Number",
                      headerStyle: { fontWeight: "bold" },
                      accessor: "phone",
                      style: { textAlign: "center" },
                    },
                  ]}
                  style={{ backgroundColor: "#f2f2f2" }}
                  filterable
                  defaultFilterMethod={(filter, row) =>
                    String(row[filter.id])
                      .toLowerCase()
                      .includes(filter.value.toLowerCase())
                  }
                  defaultPageSize={5}
                />
              </div>
            </Grid>
          </Grid>
        </Box>
        {this.renderValidationAlert()}
        {this.renderDialogAddNew()}
        {this.renderSuccess()}
      </Box>
    );
  }
}
export default FromGroupPage;

import React, { Component } from "react";
import {
  apiGroupContactList,
  apiAllContactList,
  apiAllContactGroupIdNoLimit,
} from "../../Service/api";
import { Api, TelegramClient } from "telegram";
import { StringSession } from "telegram/sessions";
import "react-table/react-table.css";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  DialogContentText,
  DialogTitle,
  Slide,
  Checkbox,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Stack,
  Alert,
} from "@mui/material";
import Select from "react-select";
import { Input } from "reactstrap";
import { Contacts, Close, StopCircle, AddBox, Send } from "@mui/icons-material";
import { Data_Config } from "../../../global";
const stylesListComent = {
  inline: {
    display: "inline",
  },
};

const stylesListDialog = {
  inline: {
    display: "inline",
  },
};

const stylesDialog = {
  appBar: {
    position: "relative",
    backgroundColor: "#03101e",
  },
  title: {
    marginLeft: 0,
    flex: 1,
    fontSize: 16,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const apiId = Data_Config.api_id;
const apiHash = Data_Config.api_hash;
const session = new StringSession(Data_Config.string_session);

const client = new TelegramClient(session, apiId, apiHash, {
  connectionRetries: 5,
});

class FromContactsPage extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.state = {
      tableData: [],
      tableDisplay: [],
      filter: "",
      channel: "",
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      showLoading: false,
      showTextGetParticipants: false,
      arrDataUserParticipants: [],
      selectedGroupId: null,
      groupShow: [],
      AllContact: [],
      selectAll: false,
      pushData: [],
      openSuccess: false,
      openSuccessText: "",
    };
  }

  componentDidMount() {
    this.getDataGroup();
    // this.getAllContact();
  }

  getDataGroup = () => {
    apiGroupContactList()
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          var temp = this.state.groupShow;
          temp = data.records;
          for (var i = 0; i < temp.length; i++) {
            temp[i].value = temp[i].group_id;
            temp[i].label = temp[i].group_name;
          }
          this.setState({
            selectedGroupId: temp[0],
          });
          this.setState({ groupShow: temp });
          this.getAllContactByGroup(temp[0].value);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getAllContactByGroup = (group_id) => {
    this.props.doLoading();
    apiAllContactGroupIdNoLimit(group_id)
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          var temp = this.state.AllContact;
          temp = data.records;
          for (var i = 0; i < temp.length; i++) {
            temp[i].isChecked = false;
          }
          this.setState({ AllContact: temp });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  getAllContact = () => {
    // this.props.doLoading();
    apiAllContactList(this.state.filter)
      .then((res) => {
        // this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          var temp = this.state.AllContact;
          temp = data.records;
          for (var i = 0; i < temp.length; i++) {
            temp[i].isChecked = false;
          }
          this.setState({ AllContact: temp });
        }
      })
      .catch((err) => {
        // this.props.doLoading();
        console.log(err);
      });
  };

  handleCheck(e, i) {
    let dataParticipants = this.state.AllContact;
    dataParticipants[i].isChecked = e.target.checked;
    this.setState({
      AllContact: dataParticipants,
    });
  }

  toggleSelectAll(e) {
    let dataParticipants = this.state.AllContact;
    for (let i = 0; i < dataParticipants.length; i++) {
      dataParticipants[i].isChecked = e.target.checked;
    }
    this.setState({
      AllContact: dataParticipants,
      selectAll: e.target.checked,
    });
  }

  handleChangeOptionGroup = (selectedGroupId) => {
    this.setState({ selectedGroupId });
    this.getAllContactByGroup(selectedGroupId.value);
  };

  pressCreateChatGroup = () => {
    const result = this.state.AllContact.filter((v) => v.isChecked === true);
    let data = [];
    result.map((obj, i) => {
      data.push(obj.username !== "" ? obj.username : obj.phone);
    });

    if (this.state.channel === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Channel empty?",
        titleBody: "Please enter channel group.",
      });
    } else if (data.length === 0) {
      this.setState({
        openValidation: true,
        titleValidation: "Select Contact?",
        titleBody: "Select contact for invite to group.",
      });
    } else {
      // this.setState({ showLoading: true, showTextGetParticipants: true });
      // this.GetParticipants();
      const result = this.state.AllContact.filter((v) => v.isChecked === true);
      let data = [];
      result.map((obj, i) => {
        data.push(obj.username !== "" ? obj.username : obj.phone);
      });
      this.CreateChat(data);
      console.log(data);
    }
  };

  pressStop = () => {
    this.setState({
      showLoading: false,
    });
  };

  async CreateChat(data) {
    // await client.session.setDC(2, "vesta.web.telegram.org", 80);
    await client.session.setDC(5, "flora.web.telegram.org", 443);
    await client.connect();
    const result = await client.invoke(
      new Api.messages.CreateChat({
        users: data,
        title: this.state.channel,
      })
    );
    console.log(result);
    if (result !== undefined || result !== null || result !== "") {
      this.setState({
        openSuccess: true,
        openSuccessText: "Successfully",
      });
    }
  }

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth="xs"
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  renderListParticipants = () => {
    if (this.state.AllContact.length > 0) {
      return (
        <List className="list-form">
          <div style={{ marginBottom: 0 }}>
            {this.state.AllContact.map((dt, i) => {
              return (
                <div>
                  <ListItem alignItems="flex-start" button>
                    <ListItemText
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            style={
                              (stylesListComent.inline,
                              { fontSize: 15, color: "#000", fontWeight: 800 })
                            }
                          >
                            {dt.first_name} {dt.last_name}
                          </Typography>
                          <br></br>
                          {dt.username !== "" ? (
                            <Typography
                              component="span"
                              variant="body2"
                              style={
                                (stylesListComent.inline,
                                { fontSize: 15, color: "#000" })
                              }
                            >
                              @{dt.username}
                            </Typography>
                          ) : (
                            <Typography
                              component="span"
                              variant="body2"
                              style={
                                (stylesListComent.inline,
                                { fontSize: 15, color: "#000" })
                              }
                            >
                              {dt.phone}
                            </Typography>
                          )}
                        </React.Fragment>
                      }
                    />
                    <div style={{ alignSelf: "center", textAlign: "center" }}>
                      <ListItemSecondaryAction>
                        <Checkbox
                          style={{
                            width: 50,
                            height: 50,
                            color: "#07213c",
                          }}
                          edge="end"
                          onChange={(e) => this.handleCheck(e, i)}
                          checked={dt.isChecked}
                          inputProps={{
                            "aria-labelledby": "checkbox-list-secondary-label",
                          }}
                        />
                      </ListItemSecondaryAction>
                    </div>
                  </ListItem>
                  <Divider />
                </div>
              );
            })}
          </div>
        </List>
      );
    }
  };

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth="xs"
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  closeStackSuccess = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
    });
  };

  pressReset = () => {
    this.setState({
      channel: "",
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      showLoading: false,
      showTextGetParticipants: false,
      selectedGroupId: null,
      selectAll: false,
      openSuccess: false,
      openSuccessText: "",
    });
  };

  renderSuccess = () => {
    if (this.state.openSuccess === true) {
      setTimeout(() => this.pressReset(), 2000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  // onClick={() => this.reset()}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openSuccessText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  color: "#FFF",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <Typography
                  component="span"
                  variant="h1"
                  style={
                    (stylesListComent.inline,
                    {
                      fontSize: 20,
                      color: "#000",
                      fontWeight: "bold",
                    })
                  }
                >
                  Invite to Group From Contacts
                </Typography>
                <br></br>
                <br></br>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <br></br>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Grid item xs={12}>
                <Paper
                  style={{
                    padding: "10px",
                    backgroundColor: "#fff",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        component="span"
                        variant="subtitle1"
                        style={{
                          fontSize: 16,
                          float: "left",
                          marginTop: 6,
                          color: "#000",
                          fontWeight: "bold",
                          textTransform: "capitalize",
                        }}
                      >
                        Title Group
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        autoComplete="off"
                        type="text"
                        name="channel"
                        id="channel"
                        placeholder="Enter title group"
                        value={this.state.channel}
                        onChange={(event) =>
                          this.setState({ channel: event.target.value })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        fullWidth
                        style={{
                          backgroundColor: "#07213c",
                        }}
                        startIcon={<Send />}
                        onClick={() => this.pressCreateChatGroup()}
                      >
                        <Typography
                          variant="button"
                          style={{
                            fontSize: 12,
                            color: "#fff",
                            textTransform: "uppercase",
                          }}
                        >
                          Invite to Group
                        </Typography>
                      </Button>{" "}
                    </Grid>
                    <br></br>
                  </Grid>
                </Paper>
                <br></br>
                {this.renderSuccess()}
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid item xs={12}>
                <Paper
                  style={{
                    padding: "10px",
                    backgroundColor: "#fff",
                    // height: "75vh",
                  }}
                >
                  <Typography
                    component="span"
                    variant="h1"
                    style={
                      (stylesListComent.inline,
                      {
                        fontSize: 20,
                        color: "#000",
                        fontWeight: "bold",
                      })
                    }
                  >
                    All Contact
                  </Typography>
                  <br></br>
                  <div className="contentDate">
                    <div style={{ marginRight: 0, width: 250 }}>
                      <Select
                        isClearable
                        classNamePrefix="select"
                        placeholder="Select For group"
                        value={this.state.selectedGroupId}
                        onChange={this.handleChangeOptionGroup}
                        options={this.state.groupShow}
                      />
                    </div>
                  </div>
                  <br></br>
                  <br></br>
                </Paper>
              </Grid>
              <div className="content-item-list">
                {this.state.AllContact.length > 0 ? (
                  <div style={{ marginRight: 16, textAlign: "end" }}>
                    <Typography
                      variant="button"
                      style={{
                        fontSize: 15,
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      Select All
                    </Typography>
                    <Checkbox
                      style={{
                        width: 50,
                        height: 50,
                        color: "#07213c",
                      }}
                      edge="end"
                      onChange={(e) => this.toggleSelectAll(e)}
                      checked={this.state.selectAll}
                      inputProps={{
                        "aria-labelledby": "checkbox-list-secondary-label",
                      }}
                    />
                  </div>
                ) : null}
                {this.renderListParticipants()}
              </div>
            </Grid>
          </Grid>
        </Box>
        {this.renderValidationAlert()}
      </Box>
    );
  }
}
export default FromContactsPage;

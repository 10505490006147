import React, { Component } from "react";
import {
  apiGroupContactList,
  apiAllContactListNoLimit,
  apiAllContactGroupIdNoLimit,
  apiManageApiAccount,
  apiGroupAccountList,
  apiUpdateAccountBanned,
  apiUpdateAccountSession,
  apiUpdateAccountFlood,
  apiUpdateAccountActive,
} from "../../Service/api";
import { Api, TelegramClient } from "telegram";
import { StringSession } from "telegram/sessions";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  DialogContentText,
  DialogTitle,
  Slide,
  Checkbox,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Stack,
  Alert,
} from "@mui/material";
import {
  DisconnectedWS,
  SendMessageMember,
} from "../../TelegramApi/ClinetApiTelegram";
import Select from "react-select";
import { Input } from "reactstrap";
import { Close, Send } from "@mui/icons-material";
import moment from "moment";
import { shuffle, getRoundRobin } from "../../../global";
const stylesListComent = {
  inline: {
    display: "inline",
  },
};

const stylesListDialog = {
  inline: {
    display: "inline",
  },
};

const stylesDialog = {
  appBar: {
    position: "relative",
    backgroundColor: "#03101e",
  },
  title: {
    marginLeft: 0,
    flex: 1,
    fontSize: 16,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class SendMessageBulk extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.searchContact = React.createRef();
    this.messageText = React.createRef();
    this.state = {
      tableData: [],
      tableDisplay: [],
      filter: "",
      message: "",
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      showLoading: false,
      showTextGetParticipants: false,
      arrDataUserParticipants: [],
      selectedGroupId: null,
      groupShow: [],
      AllContact: [],
      selectAll: false,
      pushData: [],
      openSuccess: false,
      openSuccessText: "",
      openFailed: false,
      openFailedText: "",
      selectAll: false,
      checked: [],
      selectRowData: [],
      loadingMessage: false,
      accountShow: [],
      DataGroupAccountTelegram: [],
      selectGroupAccountTelegramId: null,
      totalAccount: 0,
      getRoundRobinIdx: 0,
    };
  }

  componentDidMount() {
    this.getDataGroupTelegramAccount();
    this.getDataAccounts();
    this.getDataGroup();
  }

  getDataGroupTelegramAccount = () => {
    apiGroupAccountList()
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          var temp = this.state.tableData;
          temp = data.records;
          for (var i = 0; i < temp.length; i++) {
            temp[i].id = i + 1;
            temp[i].total = temp[i].info_linked.length;
            temp[i].status = temp[i].is_available === 0 ? "Hide" : "Show";
            temp[i].value = temp[i].group_account_id;
            temp[i].label = temp[i].group_account_name;
          }

          if (this.state.selectGroupAccountTelegramId !== null) {
            let updateData = temp.filter(
              (obj) =>
                obj.value === this.state.selectGroupAccountTelegramId.value
            );

            console.log("Mount Data", updateData[0]);

            const format1 = "YYYY-MM-DD HH:mm:ss";
            var date = new Date();
            var DateTimeNow = moment(date).format(format1);

            if (updateData[0].info_linked.length > 0) {
              let dataFilterFlood = updateData[0].info_linked.filter(
                (obj) => obj.status === 4 && obj.flood >= DateTimeNow
              );

              console.log("DATA ACCOUNT FLOOD", dataFilterFlood);

              let dataFloodToActive = updateData[0].info_linked.filter(
                (obj) => obj.status === 4 && obj.flood < DateTimeNow
              );

              console.log("DATA ACCOUNT FLOOD TO ACTIVE", dataFloodToActive);

              if (dataFloodToActive.length > 0) {
                dataFloodToActive.map((obj) => {
                  return this.doUpdateAccountActive(obj.manage_id);
                });
              }

              let dataUnFlood = updateData[0].info_linked.filter(
                (val) => !dataFilterFlood.includes(val)
              );
              console.log("DATA ACCOUNT UNFLOOD", dataUnFlood);

              let arrGroupAccount = [];
              arrGroupAccount.push(updateData[0]);
              for (var i = 0; i < arrGroupAccount.length; i++) {
                arrGroupAccount[i].info_linked = dataUnFlood;
              }

              console.log("REAL ACCOUNT", arrGroupAccount[0]);

              this.setState({
                selectGroupAccountTelegramId: arrGroupAccount[0],
                DataGroupAccountTelegram: temp,
              });
            } else {
              console.log("normal");
              this.setState({
                selectGroupAccountTelegramId: updateData[0],
                DataGroupAccountTelegram: temp,
              });
            }
          } else {
            console.log("Mount Data", temp[0]);

            const format1 = "YYYY-MM-DD HH:mm:ss";
            var date = new Date();
            var DateTimeNow = moment(date).format(format1);

            if (temp[0].info_linked.length > 0) {
              let dataFilterFlood = temp[0].info_linked.filter(
                (obj) => obj.status === 4 && obj.flood >= DateTimeNow
              );

              console.log("DATA ACCOUNT FLOOD", dataFilterFlood);

              let dataFloodToActive = temp[0].info_linked.filter(
                (obj) => obj.status === 4 && obj.flood < DateTimeNow
              );

              console.log("DATA ACCOUNT FLOOD TO ACTIVE", dataFloodToActive);

              if (dataFloodToActive.length > 0) {
                dataFloodToActive.map((obj) => {
                  return this.doUpdateAccountActive(obj.manage_id);
                });
              }

              let dataUnFlood = temp[0].info_linked.filter(
                (val) => !dataFilterFlood.includes(val)
              );
              console.log("DATA ACCOUNT UNFLOOD", dataUnFlood);

              let arrGroupAccount = [];
              arrGroupAccount.push(temp[0]);
              for (var i = 0; i < arrGroupAccount.length; i++) {
                arrGroupAccount[i].info_linked = dataUnFlood;
              }

              console.log("REAL ACCOUNT", arrGroupAccount[0]);

              this.setState({
                selectGroupAccountTelegramId: arrGroupAccount[0],
                DataGroupAccountTelegram: temp,
              });
            } else {
              console.log("normal");
              this.setState({
                selectGroupAccountTelegramId: temp[0],
                DataGroupAccountTelegram: temp,
              });
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getDataAccounts = () => {
    apiManageApiAccount()
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          var temp = this.state.accountShow;
          temp = data.records;
          for (var i = 0; i < temp.length; i++) {
            temp[i].value = temp[i].manage_id;
            temp[i].label = temp[i].alias;
          }
          this.setState({ accountShow: temp });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doUpdateAccountActive = (manage_id) => {
    apiUpdateAccountActive(manage_id)
      .then((res) => {
        // console.log(res);
        let data = res.data;
        if (data.status === "OK") {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doUpdateSession = (manage_id) => {
    apiUpdateAccountSession(manage_id)
      .then((res) => {
        console.log(res);
        let data = res.data;
        if (data.status === "OK") {
          this.getDataGroupTelegramAccount();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doUpdateBanned = (manage_id) => {
    apiUpdateAccountBanned(manage_id)
      .then((res) => {
        console.log(res);
        let data = res.data;
        if (data.status === "OK") {
          this.getDataGroupTelegramAccount();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doUpdateFlood = (manage_id, flood_time) => {
    let params = {
      manage_id: manage_id,
      flood_time: flood_time,
    };
    apiUpdateAccountFlood(params)
      .then((res) => {
        console.log(res);
        let data = res.data;
        if (data.status === "OK") {
          this.getDataGroupTelegramAccount();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getDataGroup = () => {
    apiGroupContactList()
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          var temp = this.state.groupShow;
          temp = data.records;
          for (var i = 0; i < temp.length; i++) {
            temp[i].value = temp[i].group_id;
            temp[i].label = temp[i].group_name;
          }
          this.setState({ groupShow: temp });
          this.setState({
            selectedGroupId: temp[0],
          });
          this.getAllContactByGroup(temp[0].value);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getAllContact = (newFilter) => {
    this.props.doLoading();
    apiAllContactListNoLimit(newFilter)
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          var temp = this.state.AllContact;
          temp = data.records;
          for (var i = 0; i < temp.length; i++) {
            temp[i].isChecked = false;
            temp[i].id = i + 1;
          }
          this.setState({ AllContact: temp });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  getAllContactByGroup = (group_id) => {
    this.props.doLoading();
    apiAllContactGroupIdNoLimit(group_id)
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          var temp = this.state.AllContact;
          temp = data.records;
          for (var i = 0; i < temp.length; i++) {
            temp[i].isChecked = false;
            temp[i].id = i + 1;
          }
          this.setState({ AllContact: temp });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  doDisconnectedTelegram = (getApi) => {
    DisconnectedWS(getApi)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doSearchContact = () => {
    let newFilter = this.searchContact.current.value;

    this.setState({
      filter: newFilter,
      tableData: [],
    });

    this.getAllContact(newFilter);
  };

  handleCheck = (e, i) => {
    let tmp = this.state.AllContact;
    tmp[i].isChecked = e.target.checked;
    this.setState({ AllContact: tmp });
    this.setState({ checked: tmp[i].isChecked });

    var checkedCopy = this.state.checked;
    checkedCopy[i] = !this.state.checked[i];
    if (checkedCopy[i] === false) {
      this.setState({ selectAll: false });
    }
    this.setState({
      checked: checkedCopy,
    });
  };

  toggleSelectAll(e) {
    let tmp = this.state.AllContact;
    for (let i = 0; i < tmp.length; i++) {
      tmp[i].isChecked = e.target.checked;
    }
    this.setState({ AllContact: tmp, selectAll: e.target.checked });
    var selectAll = !this.state.selectAll;
    this.setState({ selectAll: selectAll });
    var checkedCopy = [];
    tmp.forEach(function (e, index) {
      checkedCopy.push(selectAll);
    });
    this.setState({
      checked: checkedCopy,
    });
  }

  handleChangeOptionGroup = (selectedGroupId) => {
    // this.searchContact.current.value = "";
    this.setState({ selectedGroupId });
    this.getAllContactByGroup(selectedGroupId.value);
  };

  pressSendMessageBulk = () => {
    let newMessage = this.messageText.current.value;
    const result = this.state.AllContact.filter((v) => v.isChecked === true);

    this.setState({
      message: newMessage,
    });

    if (newMessage === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty message?",
        titleBody: "Please write a message.",
      });
    } else if (result.length === 0) {
      this.setState({
        openValidation: true,
        titleValidation: "Select Contact?",
        titleBody: "Select a contact to send the message.",
      });
    } else {
      const result = this.state.AllContact.filter(
        (v) => v.isChecked === true && v.username !== null
      );
      let data = [];
      result.map((obj, i) => {
        data.push(obj.username);
      });

      this.setState({
        loadingMessage: true,
      });

      let dataAccount = this.state.selectGroupAccountTelegramId;
      if (dataAccount.info_linked.length > 0) {
        const promises = data.map(
          (obj, i) =>
            new Promise((resolve, reject) =>
              setTimeout(() => {
                if (
                  this.state.selectGroupAccountTelegramId.info_linked.length ===
                  0
                ) {
                  reject();
                  if (this.state.LoadingInvite === false) {
                    reject();
                  }
                } else {
                  console.log(obj);
                  this.doSendMessageBulk(obj, newMessage);
                  resolve();
                }
              }, 3000 * i)
            )
        );
        Promise.all(promises).then(() => this.handleCloseAll());
      } else {
        this.setState({
          openValidation: true,
          titleValidation: "Empty group account telegram",
          titleBody: "Telegram account not available",
        });
      }
    }
  };

  handleCloseAll = () => {
    this.messageText.current.value = "";
    this.setState({
      loadingMessage: false,
      openSuccess: true,
      openSuccessText: "Message sent successfully.",
    });
  };

  pressStop = () => {
    this.setState({
      showLoading: false,
    });
  };

  makeid = (length) => {
    var result = "";
    var characters = "0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  doSendMessageBulk = (username, newMessage) => {
    let dataAccount = this.state.selectGroupAccountTelegramId;
    if (dataAccount.info_linked.length > 0) {
      let totalAccount = dataAccount.info_linked.length;
      this.setState({
        totalAccount: totalAccount,
      });

      let DataAccountTelegram = dataAccount.info_linked.sort((a, b) => {
        if (a.manage_id < b.manage_id) return -1;
        if (a.manage_id > b.manage_id) return 1;
        return 0;
      });
      let getApi = getRoundRobin(
        this.state.getRoundRobinIdx,
        DataAccountTelegram
      );

      var getAPI = getApi[0];
      console.log(getAPI);

      if (totalAccount === this.state.getRoundRobinIdx) {
        this.setState({
          getRoundRobinIdx: 0,
        });
      } else {
        this.setState({
          getRoundRobinIdx: this.state.getRoundRobinIdx + 1,
        });
      }

      console.log(
        "Account Telegram:",
        getAPI.alias === "" ? getAPI.phonenumber : getAPI.alias
      );

      console.log("Account Full Telegram:", getAPI);

      let randomId = this.makeid(10);

      SendMessageMember(username, getAPI, newMessage, randomId)
        .then((res) => {
          console.log(res);
          this.doDisconnectedTelegram(getAPI);
          this.setState({
            openSuccess: true,
            openSuccessText: "Message sent successfully.",
            loadingMessage: false,
          });
        })
        .catch((err) => {
          this.setState({
            loadingMessage: false,
            openFailed: true,
            openFailedText: "Message failed to sent.",
          });
          console.log(err);
          this.doDisconnectedTelegram(getAPI);
          console.log(err.toString());
          console.log(err);
          console.log(err.errorMessage);

          if (
            err.toString() ===
            "RPCError: 406: AUTH_KEY_DUPLICATED (caused by InvokeWithLayer)"
          ) {
            this.doUpdateSession(getAPI.manage_id);
            this.setState({
              openValidation: true,
              titleValidation: "Error Code " + err.toString(),
              titleBody:
                "account" + getAPI.alias === ""
                  ? getAPI.phonenumber
                  : getAPI.alias + "must be updated session",
            });
          } else {
            if (err.code === 401) {
              if (err.errorMessage === "AUTH_KEY_PERM_EMPTY") {
                this.doUpdateBanned(getAPI.manage_id);
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody:
                    "The temporary auth key must be binded to the permanent auth key to use these methods.",
                });
              } else {
                this.doUpdateBanned(getAPI.manage_id);
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody:
                    "The temporary auth key must be binded to the permanent auth key to use these methods.",
                });
              }
            } else if (err.code === 406) {
              this.doUpdateSession(getAPI.manage_id);
              this.setState({
                openValidation: true,
                titleValidation: "Error Code " + err.toString(),
                titleBody:
                  "account" + getAPI.alias === ""
                    ? getAPI.phonenumber
                    : getAPI.alias + "must be updated session",
              });
              console.log("keluar dari rotasi");
            } else if (err.code === 400) {
              if (err.errorMessage === "BOT_DOMAIN_INVALID") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "Bot domain invalid.",
                });
              } else if (err.errorMessage === "BOT_INVALID") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "This is not a valid bot.",
                });
              } else if (err.errorMessage === "BUTTON_DATA_INVALID") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody:
                    "The data of one or more of the buttons you provided is invalid.",
                });
              } else if (err.errorMessage === "BUTTON_TYPE_INVALID") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody:
                    "The type of one or more of the buttons you provided is invalid.",
                });
              } else if (err.errorMessage === "BUTTON_URL_INVALID") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "Button URL invalid.",
                });
              } else if (err.errorMessage === "CHANNEL_INVALID") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "The provided channel is invalid.",
                });
              } else if (err.errorMessage === "CHANNEL_PRIVATE") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "You haven't joined this channel/supergroup.",
                });
              } else if (err.errorMessage === "CHANNEL_PRIVATE") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "You haven't joined this channel/supergroup.",
                });
              } else if (err.errorMessage === "CHAT_ADMIN_REQUIRED") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "You must be an admin in this chat to do this.",
                });
              } else if (err.errorMessage === "CHAT_ID_INVALID") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "The provided chat id is invalid.",
                });
              } else if (err.errorMessage === "CHAT_RESTRICTED") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody:
                    "You can't send messages in this chat, you were restricted.",
                });
              } else if (err.errorMessage === "ENCRYPTION_DECLINED") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "The secret chat was declined.",
                });
              } else if (err.errorMessage === "ENTITY_MENTION_USER_INVALID") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "You mentioned an invalid user.",
                });
              } else if (err.errorMessage === "FROM_MESSAGE_BOT_DISABLED") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "Bots can't use fromMessage min constructors.",
                });
              } else if (err.errorMessage === "INPUT_USER_DEACTIVATED") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "The specified user was deleted.",
                });
              } else if (err.errorMessage === "MESSAGE_EMPTY") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "The provided message is empty.",
                });
              } else if (err.errorMessage === "MESSAGE_TOO_LONG") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "The provided message is too long.",
                });
              } else if (err.errorMessage === "MSG_ID_INVALID") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "Provided reply_to_msg_id is invalid.",
                });
              } else if (err.errorMessage === "PEER_ID_INVALID") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "The provided peer id is invalid.",
                });
              } else if (err.errorMessage === "PINNED_DIALOGS_TOO_MUCH") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "Too many pinned dialogs.",
                });
              } else if (err.errorMessage === "REPLY_MARKUP_INVALID") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "The provided reply markup is invalid.",
                });
              } else if (err.errorMessage === "SCHEDULE_BOT_NOT_ALLOWED") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "Bots cannot schedule messages.",
                });
              } else if (err.errorMessage === "SCHEDULE_DATE_TOO_LATE") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody:
                    "You can't schedule a message this far in the future.",
                });
              } else if (err.errorMessage === "SCHEDULE_TOO_MUCH") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "There are too many scheduled messages.",
                });
              } else if (err.errorMessage === "SLOWMODE_WAIT_X") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody:
                    "Slowmode is enabled in this chat: you must wait for the specified number of seconds before sending another message to the chat.",
                });
              } else if (err.errorMessage === "USER_BANNED_IN_CHANNEL") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody:
                    "You're banned from sending messages in supergroups/channels.",
                });
              } else if (err.errorMessage === "USER_IS_BLOCKED") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "You were blocked by this user.",
                });
              } else if (err.errorMessage === "USER_IS_BOT") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "Bots can't send messages to other bots.",
                });
              } else if (err.errorMessage === "YOU_BLOCKED_USER") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "You blocked this user.",
                });
              }
            } else if (err.code === 403) {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "You can't write in this chat.",
              });
            } else {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: err.errorMessage,
              });
            }
          }
        });
    }
  };

  async SendMessageBulk(username, getApiHash, newMessage) {
    let getApi = shuffle(getApiHash);

    const apiId = getApi[0].api_id;
    const apiHash = getApi[0].api_hash;
    const session = new StringSession(getApi[0].string_token);
    const client = new TelegramClient(session, apiId, apiHash, {
      connectionRetries: 5,
    });

    if (username.length > 0) {
      await client.session.setDC(5, "flora.web.telegram.org", 443);
      await client.connect();
      await client
        .invoke(
          new Api.messages.SendMessage({
            peer: username,
            message: newMessage,
            randomId: this.makeid(10),
            silent: false,
          })
        )
        .then((res) => {
          console.log(res);
          this.setState({
            openSuccess: true,
            openSuccessText: "Message sent successfully.",
            loadingMessage: false,
          });
        })
        .catch((err) => {
          console.log(err.code);
          console.log(err.errorMessage);
          if (err.code === 401) {
            if (err.errorMessage === "AUTH_KEY_PERM_EMPTY") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody:
                  "The temporary auth key must be binded to the permanent auth key to use these methods.",
              });
            }
          } else if (err.code === 400) {
            if (err.errorMessage === "BOT_DOMAIN_INVALID") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "Bot domain invalid.",
              });
            } else if (err.errorMessage === "BOT_INVALID") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "This is not a valid bot.",
              });
            } else if (err.errorMessage === "BUTTON_DATA_INVALID") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody:
                  "The data of one or more of the buttons you provided is invalid.",
              });
            } else if (err.errorMessage === "BUTTON_TYPE_INVALID") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody:
                  "The type of one or more of the buttons you provided is invalid.",
              });
            } else if (err.errorMessage === "BUTTON_URL_INVALID") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "Button URL invalid.",
              });
            } else if (err.errorMessage === "CHANNEL_INVALID") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "The provided channel is invalid.",
              });
            } else if (err.errorMessage === "CHANNEL_PRIVATE") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "You haven't joined this channel/supergroup.",
              });
            } else if (err.errorMessage === "CHANNEL_PRIVATE") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "You haven't joined this channel/supergroup.",
              });
            } else if (err.errorMessage === "CHAT_ADMIN_REQUIRED") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "You must be an admin in this chat to do this.",
              });
            } else if (err.errorMessage === "CHAT_ID_INVALID") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "The provided chat id is invalid.",
              });
            } else if (err.errorMessage === "CHAT_RESTRICTED") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody:
                  "You can't send messages in this chat, you were restricted.",
              });
            } else if (err.errorMessage === "ENCRYPTION_DECLINED") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "The secret chat was declined.",
              });
            } else if (err.errorMessage === "ENTITY_MENTION_USER_INVALID") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "You mentioned an invalid user.",
              });
            } else if (err.errorMessage === "FROM_MESSAGE_BOT_DISABLED") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "Bots can't use fromMessage min constructors.",
              });
            } else if (err.errorMessage === "INPUT_USER_DEACTIVATED") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "The specified user was deleted.",
              });
            } else if (err.errorMessage === "MESSAGE_EMPTY") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "The provided message is empty.",
              });
            } else if (err.errorMessage === "MESSAGE_TOO_LONG") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "The provided message is too long.",
              });
            } else if (err.errorMessage === "MSG_ID_INVALID") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "Provided reply_to_msg_id is invalid.",
              });
            } else if (err.errorMessage === "PEER_ID_INVALID") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "The provided peer id is invalid.",
              });
            } else if (err.errorMessage === "PINNED_DIALOGS_TOO_MUCH") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "Too many pinned dialogs.",
              });
            } else if (err.errorMessage === "REPLY_MARKUP_INVALID") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "The provided reply markup is invalid.",
              });
            } else if (err.errorMessage === "SCHEDULE_BOT_NOT_ALLOWED") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "Bots cannot schedule messages.",
              });
            } else if (err.errorMessage === "SCHEDULE_DATE_TOO_LATE") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody:
                  "You can't schedule a message this far in the future.",
              });
            } else if (err.errorMessage === "SCHEDULE_TOO_MUCH") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "There are too many scheduled messages.",
              });
            } else if (err.errorMessage === "SLOWMODE_WAIT_X") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody:
                  "Slowmode is enabled in this chat: you must wait for the specified number of seconds before sending another message to the chat.",
              });
            } else if (err.errorMessage === "USER_BANNED_IN_CHANNEL") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody:
                  "You're banned from sending messages in supergroups/channels.",
              });
            } else if (err.errorMessage === "USER_IS_BLOCKED") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "You were blocked by this user.",
              });
            } else if (err.errorMessage === "USER_IS_BOT") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "Bots can't send messages to other bots.",
              });
            } else if (err.errorMessage === "YOU_BLOCKED_USER") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "You blocked this user.",
              });
            }
          } else if (err.code === 403) {
            this.setState({
              openValidation: true,
              titleValidation:
                "Error Code " + err.code + ": " + err.errorMessage,
              titleBody: "You can't write in this chat.",
            });
          } else {
            this.setState({
              openValidation: true,
              titleValidation:
                "Error Code " + err.code + ": " + err.errorMessage,
              titleBody: err.errorMessage,
            });
          }
        });
    } else {
      this.setState({
        loadingMessage: false,
        openFailed: true,
        openFailedText: "Message failed to send.",
      });
    }
  }

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth="xs"
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  renderListParticipants = () => {
    if (this.state.AllContact.length > 0) {
      return (
        <List className="list-form">
          <div style={{ marginBottom: 0 }}>
            {this.state.AllContact.map((dt, i) => {
              return (
                <div>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            style={
                              (stylesListComent.inline,
                              { fontSize: 15, color: "#000", fontWeight: 800 })
                            }
                          >
                            {dt.first_name} {dt.last_name}
                          </Typography>
                          <br></br>
                          {dt.username !== "" ? (
                            <Typography
                              component="span"
                              variant="body2"
                              style={
                                (stylesListComent.inline,
                                { fontSize: 15, color: "#000" })
                              }
                            >
                              @{dt.username}
                            </Typography>
                          ) : (
                            <Typography
                              component="span"
                              variant="body2"
                              style={
                                (stylesListComent.inline,
                                { fontSize: 15, color: "#000" })
                              }
                            >
                              {dt.phone}
                            </Typography>
                          )}
                        </React.Fragment>
                      }
                    />
                    <div style={{ alignSelf: "center", textAlign: "center" }}>
                      <ListItemSecondaryAction>
                        <Checkbox
                          style={{
                            width: 50,
                            height: 50,
                            color: "#07213c",
                          }}
                          edge="end"
                          onChange={(e) => this.handleCheck(e, i)}
                          checked={dt.isChecked}
                          inputProps={{
                            "aria-labelledby": "checkbox-list-secondary-label",
                          }}
                        />
                      </ListItemSecondaryAction>
                    </div>
                  </ListItem>
                  <Divider />
                </div>
              );
            })}
          </div>
        </List>
      );
    } else {
      return (
        <List className="list-form">
          <div style={{ marginBottom: 0, padding: 20, textAlign: "center" }}>
            <Typography
              component="span"
              variant="body2"
              style={(stylesListComent.inline, { fontSize: 20, color: "#000" })}
            >
              Contact not available
            </Typography>
          </div>
        </List>
      );
    }
  };

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth="xs"
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  closeStackSuccess = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
    });
  };

  pressReset = () => {
    // this.messageText.current.value = "";
    this.setState({
      filter: "",
      message: "",
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      showLoading: false,
      showTextGetParticipants: false,
      selectedGroupId: null,
      selectAll: false,
      openSuccess: false,
      openSuccessText: "",
      openFailed: false,
      openFailedText: "",
    });
  };

  renderSuccess = () => {
    if (this.state.openSuccess === true) {
      setTimeout(() => this.pressReset(), 2000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  // onClick={() => this.reset()}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openSuccessText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  renderFailed = () => {
    if (this.state.openFailed === true) {
      setTimeout(() => this.pressReset(), 2000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="error"
              action={
                <IconButton aria-label="close" color="inherit" size="small">
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openFailedText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  handleChangeAccount = (selectGroupAccountTelegramId) => {
    const format1 = "YYYY-MM-DD HH:mm:ss";
    var date = new Date();
    var DateTimeNow = moment(date).format(format1);

    if (selectGroupAccountTelegramId.info_linked.length > 0) {
      let dataFilterFlood = selectGroupAccountTelegramId.info_linked.filter(
        (obj) => obj.status === 4 && obj.flood >= DateTimeNow
      );

      console.log("DATA ACCOUNT FLOOD", dataFilterFlood);

      let dataFloodToActive = selectGroupAccountTelegramId.info_linked.filter(
        (obj) => obj.status === 4 && obj.flood < DateTimeNow
      );

      console.log("DATA ACCOUNT FLOOD TO ACTIVE", dataFloodToActive);

      if (dataFloodToActive.length > 0) {
        dataFloodToActive.forEach((obj) => {
          console.log(obj);
          return this.doUpdateAccountActive(obj.manage_id);
        });
      }

      let dataUnFlood = selectGroupAccountTelegramId.info_linked.filter(
        (val) => !dataFilterFlood.includes(val)
      );
      console.log("DATA ACCOUNT UNFLOOD", dataUnFlood);

      let arrGroupAccount = [];
      arrGroupAccount.push(selectGroupAccountTelegramId);
      for (var i = 0; i < arrGroupAccount.length; i++) {
        arrGroupAccount[i].info_linked = dataUnFlood;
      }

      console.log("REAL ACCOUNT", arrGroupAccount[0]);

      this.setState({ selectGroupAccountTelegramId: arrGroupAccount[0] });
    } else {
      console.log("normal");
      this.setState({ selectGroupAccountTelegramId });
    }

    this.setState({ selectedGroupJoin: null });
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  color: "#FFF",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <Typography
                  component="span"
                  variant="h1"
                  style={
                    (stylesListComent.inline,
                    {
                      fontSize: 20,
                      color: "#000",
                      fontWeight: "bold",
                    })
                  }
                >
                  Send Message to Bulk
                </Typography>
                <br></br>
                <br></br>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <br></br>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Grid item xs={12}>
                <Paper
                  style={{
                    padding: "10px",
                    backgroundColor: "#fff",
                    // height: "75vh",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div
                        style={{
                          marginRight: 16,
                          width: "100%",
                          position: "relative",
                          zIndex: 2,
                        }}
                      >
                        <Select
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          classNamePrefix="select"
                          placeholder="Select a group account telegram"
                          value={this.state.selectGroupAccountTelegramId}
                          onChange={this.handleChangeAccount}
                          options={this.state.DataGroupAccountTelegram}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        autoComplete="off"
                        type="textarea"
                        name="message"
                        id="message"
                        rows={5}
                        placeholder="Write a message"
                        innerRef={this.messageText}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        fullWidth
                        size="large"
                        style={{
                          backgroundColor: "#07213c",
                        }}
                        endIcon={
                          this.state.loadingMessage === true ? null : <Send />
                        }
                        onClick={() => this.pressSendMessageBulk()}
                      >
                        {this.state.loadingMessage === true ? (
                          <CircularProgress
                            style={{ color: "#fff" }}
                            size={24}
                          />
                        ) : (
                          <Typography
                            variant="button"
                            style={{
                              color: "#fff",
                              textTransform: "uppercase",
                            }}
                          >
                            Send
                          </Typography>
                        )}
                      </Button>{" "}
                    </Grid>
                    <br></br>
                  </Grid>
                </Paper>
                <br></br>
                {this.renderSuccess()}
                {this.renderFailed()}
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid item xs={12}>
                <Paper
                  style={{
                    padding: "10px",
                    backgroundColor: "#fff",
                    // height: "75vh",
                  }}
                >
                  <Typography
                    component="span"
                    variant="h1"
                    style={
                      (stylesListComent.inline,
                      {
                        fontSize: 20,
                        color: "#000",
                        fontWeight: "bold",
                      })
                    }
                  >
                    All Contact
                  </Typography>
                  <br></br>
                  <div className="contentDate">
                    <div style={{ marginRight: 0, width: 250 }}>
                      <Select
                        // isClearable
                        classNamePrefix="select"
                        placeholder="Select a group contact"
                        value={this.state.selectedGroupId}
                        onChange={this.handleChangeOptionGroup}
                        options={this.state.groupShow}
                      />
                    </div>
                  </div>
                  <br></br>
                  <br></br>
                </Paper>
              </Grid>
              <br></br>
              <div className="box-container">
                <ReactTable
                  ref={(r) => (this.reactTable = r)}
                  data={this.state.AllContact}
                  columns={[
                    {
                      Header: (
                        <Checkbox
                          style={{
                            width: 30,
                            height: 30,
                            color: "#3a6cf6",
                          }}
                          onChange={(e) => this.toggleSelectAll(e)}
                          checked={this.state.selectAll}
                          inputProps={{
                            "aria-labelledby": "checkbox-list-secondary-label",
                          }}
                        />
                      ),
                      Cell: (row) => (
                        <Checkbox
                          style={{
                            width: 30,
                            height: 30,
                            color: "#3a6cf6",
                          }}
                          defaultChecked={this.state.checked[row.index]}
                          checked={this.state.checked[row.index]}
                          onChange={(e) => this.handleCheck(e, row.index)}
                          inputProps={{
                            "aria-labelledby": "checkbox-list-secondary-label",
                          }}
                        />
                      ),
                      headerStyle: { fontWeight: "bold" },
                      sortable: false,
                      filterable: true,
                      style: { textAlign: "center" },
                      width: 100,
                    },
                    {
                      Header: "No",
                      headerStyle: { fontWeight: "bold" },
                      accessor: "id",
                      style: { textAlign: "center" },
                      width: 100,
                    },
                    {
                      Header: "Name",
                      headerStyle: { fontWeight: "bold" },
                      accessor: "full_name",
                      style: { textAlign: "left" },
                    },
                    {
                      Header: "@Username",
                      headerStyle: { fontWeight: "bold" },
                      accessor: "username",
                      style: { textAlign: "center" },
                    },
                    {
                      Header: "Phone Number",
                      headerStyle: { fontWeight: "bold" },
                      accessor: "phone",
                      style: { textAlign: "center" },
                    },
                  ]}
                  style={{ backgroundColor: "#f2f2f2" }}
                  filterable
                  defaultFilterMethod={(filter, row) =>
                    String(row[filter.id])
                      .toLowerCase()
                      .includes(filter.value.toLowerCase())
                  }
                  defaultPageSize={5}
                />
              </div>
            </Grid>
          </Grid>
        </Box>
        {this.renderValidationAlert()}
      </Box>
    );
  }
}
export default SendMessageBulk;

import React, { Component } from "react";
// import { Api, TelegramClient } from "telegram";
// import { StringSession } from "telegram/sessions";
import {
  apiJoinGroupDelete,
  apiJoinGroupList,
  apiManageApiAccount,
  apiJoinGroupInsert,
  apiGroupAccountList,
  apiUpdateAccountBanned,
  apiUpdateAccountSession,
  apiUpdateAccountFlood,
  apiUpdateAccountActive,
} from "../../Service/api";
import {
  ResolveUsername,
  DisconnectedWS,
  JoinChannel,
  LeaveChannel,
} from "../../TelegramApi/ClinetApiTelegram";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  DialogActions,
  DialogContent,
  IconButton,
  DialogContentText,
  Stack,
  Alert,
  DialogTitle,
  Slide,
  CircularProgress,
} from "@mui/material";
import { shuffle } from "../../../global";
import { Input, FormGroup, Label } from "reactstrap";
import moment from "moment";
import {
  Refresh,
  Edit,
  Delete,
  AddBox,
  Close,
  GroupAdd,
  Cancel,
  Check,
} from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import Select from "react-select";
const stylesListComent = {
  inline: {
    display: "inline",
  },
};

const stylesListDialog = {
  inline: {
    display: "inline",
  },
};

const stylesDialog = {
  appBar: {
    position: "relative",
    backgroundColor: "#03101e",
  },
  title: {
    marginLeft: 0,
    flex: 1,
    fontSize: 16,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class JoinGroupPage extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.state = {
      tableData: [],
      tableDisplay: [],
      filter: "",
      setOpenEdit: false,
      setOpenAddNew: false,
      openSuccess: false,
      openSuccessText: "",
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      openAlertDelete: false,
      itemDeleted: {},
      accountShow: [],
      selectedAccountId: null,
      group_title: "",
      group_name: "",
      group_id: 0,
      submitLoading: false,
      DataGroupAccountTelegram: [],
      selectGroupAccountTelegramId: null,
    };

    this.tableColumns = [
      {
        Header: "No",
        headerStyle: { fontWeight: "bold" },
        accessor: "id",
        style: { textAlign: "center" },
        width: 100,
      },
      {
        Header: "Group",
        headerStyle: { fontWeight: "bold" },
        accessor: "group_title",
        style: { textAlign: "center" },
      },
      {
        Header: "Username",
        headerStyle: { fontWeight: "bold" },
        accessor: "group_name",
        style: { textAlign: "center" },
      },
      {
        Header: "Action",
        headerStyle: { fontWeight: "bold" },
        accessor: "",
        style: { textAlign: "center" },
        width: 200,
        Cell: (e) => (
          <div>
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#ff0000",
              }}
              startIcon={<LogoutIcon />}
              onClick={() => this.doRowDeleteInfo(e.original)}
            >
              <Typography
                variant="button"
                style={{
                  fontSize: 14,
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Leave Channel
              </Typography>
            </Button>
          </div>
        ),
      },
    ];
  }

  componentDidMount() {
    this.getDataGroup();
    this.getDataAccounts();
    // this.getAllJoinGroup();
  }

  getDataGroup = () => {
    apiGroupAccountList()
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          var temp = this.state.tableData;
          temp = data.records;
          for (var i = 0; i < temp.length; i++) {
            temp[i].id = i + 1;
            temp[i].total = temp[i].info_linked.length;
            temp[i].status = temp[i].is_available === 0 ? "Hide" : "Show";
            temp[i].value = temp[i].group_account_id;
            temp[i].label = temp[i].group_account_name;
          }

          if (this.state.selectGroupAccountTelegramId !== null) {
            let updateData = temp.filter(
              (obj) =>
                obj.value === this.state.selectGroupAccountTelegramId.value
            );

            console.log("Mount Data", updateData[0]);

            const format1 = "YYYY-MM-DD HH:mm:ss";
            var date = new Date();
            var DateTimeNow = moment(date).format(format1);

            if (updateData[0].info_linked.length > 0) {
              let dataFilterFlood = updateData[0].info_linked.filter(
                (obj) => obj.status === 4 && obj.flood >= DateTimeNow
              );

              console.log("DATA ACCOUNT FLOOD", dataFilterFlood);

              let dataFloodToActive = updateData[0].info_linked.filter(
                (obj) => obj.status === 4 && obj.flood < DateTimeNow
              );

              console.log("DATA ACCOUNT FLOOD TO ACTIVE", dataFloodToActive);

              if (dataFloodToActive.length > 0) {
                dataFloodToActive.map((obj) => {
                  return this.doUpdateAccountActive(obj.manage_id);
                });
              }

              let dataUnFlood = updateData[0].info_linked.filter(
                (val) => !dataFilterFlood.includes(val)
              );
              console.log("DATA ACCOUNT UNFLOOD", dataUnFlood);

              let arrGroupAccount = [];
              arrGroupAccount.push(updateData[0]);
              for (var i = 0; i < arrGroupAccount.length; i++) {
                arrGroupAccount[i].info_linked = dataUnFlood;
              }

              console.log("REAL ACCOUNT", arrGroupAccount[0]);

              this.setState({
                selectGroupAccountTelegramId: arrGroupAccount[0],
                DataGroupAccountTelegram: arrGroupAccount,
              });
              this.getAllJoinGroup(arrGroupAccount[0].group_account_id);
            } else {
              console.log("normal");
              this.setState({
                selectGroupAccountTelegramId: updateData[0],
                DataGroupAccountTelegram: updateData,
              });
              this.getAllJoinGroup(updateData[0].group_account_id);
            }
          } else {
            console.log("Mount Data", temp[0]);

            const format1 = "YYYY-MM-DD HH:mm:ss";
            var date = new Date();
            var DateTimeNow = moment(date).format(format1);

            if (temp[0].info_linked.length > 0) {
              let dataFilterFlood = temp[0].info_linked.filter(
                (obj) => obj.status === 4 && obj.flood >= DateTimeNow
              );

              console.log("DATA ACCOUNT FLOOD", dataFilterFlood);

              let dataFloodToActive = temp[0].info_linked.filter(
                (obj) => obj.status === 4 && obj.flood < DateTimeNow
              );

              console.log("DATA ACCOUNT FLOOD TO ACTIVE", dataFloodToActive);

              if (dataFloodToActive.length > 0) {
                dataFloodToActive.map((obj) => {
                  return this.doUpdateAccountActive(obj.manage_id);
                });
              }

              let dataUnFlood = temp[0].info_linked.filter(
                (val) => !dataFilterFlood.includes(val)
              );
              console.log("DATA ACCOUNT UNFLOOD", dataUnFlood);

              let arrGroupAccount = [];
              arrGroupAccount.push(temp[0]);
              for (var i = 0; i < arrGroupAccount.length; i++) {
                arrGroupAccount[i].info_linked = dataUnFlood;
              }

              console.log("REAL ACCOUNT", arrGroupAccount[0]);

              this.setState({
                selectGroupAccountTelegramId: arrGroupAccount[0],
              });
              this.getAllJoinGroup(arrGroupAccount[0].group_account_id);
            } else {
              console.log("normal");
              this.setState({
                selectGroupAccountTelegramId: temp[0],
                DataGroupAccountTelegram: temp,
              });
              this.getAllJoinGroup(temp[0].group_account_id);
            }

            this.getAllJoinGroup(temp[0].group_account_id);
            this.setState({
              DataGroupAccountTelegram: temp,
              selectGroupAccountTelegramId: temp[0],
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getDataAccounts = () => {
    apiManageApiAccount()
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          var temp = this.state.accountShow;
          temp = data.records;
          for (var i = 0; i < temp.length; i++) {
            temp[i].value = temp[i].manage_id;
            temp[i].label = temp[i].alias;
          }

          this.setState({
            selectedAccountId: temp[0],
          });

          this.setState({ accountShow: temp });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doInsertJoinGroup = (params) => {
    apiJoinGroupInsert(params)
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doDeleteGroup = (group_id) => {
    this.props.doLoading();
    apiJoinGroupDelete(group_id)
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          this.setState({
            openSuccess: true,
            openSuccessText: "Group deleted successfully",
            openAlertDelete: false,
            itemDeleted: {},
          });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  doUpdateBanned = (manage_id) => {
    apiUpdateAccountBanned(manage_id)
      .then((res) => {
        console.log(res);
        let data = res.data;
        if (data.status === "OK") {
          this.getDataGroup();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doUpdateSession = (manage_id) => {
    apiUpdateAccountSession(manage_id)
      .then((res) => {
        console.log(res);
        let data = res.data;
        if (data.status === "OK") {
          this.getDataGroup();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doUpdateAccountActive = (manage_id) => {
    apiUpdateAccountActive(manage_id)
      .then((res) => {
        // console.log(res);
        let data = res.data;
        if (data.status === "OK") {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doUpdateFlood = (manage_id, flood_time) => {
    let params = {
      manage_id: manage_id,
      flood_time: flood_time,
    };
    apiUpdateAccountFlood(params)
      .then((res) => {
        console.log(res);
        let data = res.data;
        if (data.status === "OK") {
          this.getDataGroup();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getAllJoinGroup = (group_account_id) => {
    this.props.doLoading();
    apiJoinGroupList(group_account_id)
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          var temp = [];
          temp = data.records;
          for (var i = 0; i < temp.length; i++) {
            temp[i].id = i + 1;
          }
          this.setState({ tableData: temp });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  getResolveUsername = (getApi, dataAccount) => {
    console.log(getApi);
    let channel = this.state.group_name;
    this.props.doLoading();
    ResolveUsername(getApi, channel)
      .then((res) => {
        // this.props.doLoading();
        this.doDisconnectedTelegram(getApi);
        console.log(res);
        if (res !== undefined || res !== null || res !== "") {
          this.setState({
            submitLoading: false,
          });
          this.setState({
            group_title: res.chats[0].title,
          });
          if (dataAccount.length > 0) {
            const promises = dataAccount.map(
              (obj_item, i) =>
                new Promise((resolve) =>
                  setTimeout(() => {
                    console.log(obj_item);
                    this.doJoinChannel(
                      obj_item,
                      res.chats[0].title,
                      res.chats[0].username
                    );
                    resolve();
                  }, 3000 * i)
                )
            );
            Promise.all(promises).then(
              () =>
                this.setState({
                  group_name: "",
                  submitLoading: false,
                  openSuccess: true,
                  openSuccessText: "Account successfully joined channel.",
                  submitLoading: false,
                }),
              this.props.doLoading()
            );
          }
        } else {
          this.props.doLoading();
          this.setState({
            submitLoading: false,
          });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        this.setState({
          submitLoading: false,
        });
        this.handleCloseAddNew();
        this.doDisconnectedTelegram(getApi);
        console.log(err);
        console.log(err.toString());
        if (
          err.toString() ===
          "RPCError: 406: AUTH_KEY_DUPLICATED (caused by InvokeWithLayer)"
        ) {
          this.doUpdateSession(getApi.manage_id);
          this.setState({
            openValidation: true,
            titleValidation: "Error Code " + err.toString(),
            titleBody:
              "account" + getApi.alias === ""
                ? getApi.phonenumber
                : getApi.alias + "must be updated session",
          });
        } else {
          if (err.code === 401) {
            this.doUpdateBanned(getApi.manage_id);
            this.setState({
              openValidation: true,
              titleValidation:
                "Error Code " + err.code + ": " + err.errorMessage,
              titleBody:
                "The temporary auth key must be binded to the permanent auth key to use these methods.",
            });
            this.handleCloseAddNew();
          } else if (err.code === 406) {
            this.doUpdateSession(getApi.manage_id);
            this.setState({
              openValidation: true,
              titleValidation: "Error Code " + err.toString(),
              titleBody:
                "account" + getApi.alias === ""
                  ? getApi.phonenumber
                  : getApi.alias + "must be updated session",
            });
            console.log("keluar dari rotasi");
          } else if (err.code === 420) {
            const format1 = "YYYY-MM-DD HH:mm:ss";
            let dt = new Date();
            dt.setSeconds(dt.getSeconds() + 43200);
            let DateAddNow = moment(dt).format(format1);
            console.log(DateAddNow);
            this.doUpdateFlood(getApi.manage_id, DateAddNow);
            this.setState({
              openValidation: true,
              titleValidation: "Error Code " + err.code,
              titleBody:
                err.toString() + " " + getApi.alias !== ""
                  ? getApi.alias
                  : getApi.phonenumber,
            });

            // if (err.errorMessage === "FLOOD") {
            //   const format1 = "YYYY-MM-DD HH:mm:ss";
            //   let dt = new Date();
            //   dt.setSeconds(dt.getSeconds() + 43200);
            //   let DateAddNow = moment(dt).format(format1);
            //   console.log(DateAddNow);
            //   this.doUpdateFlood(getApi.manage_id, DateAddNow);
            //   this.setState({
            //     openValidation: true,
            //     titleValidation: "Error Code " + err.code,
            //     titleBody:
            //       err.toString() + " " + getApi.alias !== ""
            //         ? getApi.alias
            //         : getApi.phonenumber,
            //   });
            // } else {
            //   const format1 = "YYYY-MM-DD HH:mm:ss";
            //   let dt = new Date();
            //   dt.setSeconds(dt.getSeconds() + 43200);
            //   let DateAddNow = moment(dt).format(format1);
            //   console.log(DateAddNow);
            //   this.doUpdateFlood(getApi.manage_id, DateAddNow);
            //   this.setState({
            //     openValidation: true,
            //     titleValidation: "Error Code " + err.code,
            //     titleBody:
            //       err.toString() + " " + getApi.alias !== ""
            //         ? getApi.alias
            //         : getApi.phonenumber,
            //   });
            // }
          } else {
            if (err.errorMessage === "CONNECTION_DEVICE_MODEL_EMPTY") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "Device model empty.",
              });
            } else if (err.errorMessage === "CONNECTION_LAYER_INVALID") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "Layer invalid.",
              });
            } else if (err.errorMessage === "USERNAME_INVALID") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "The provided username is not valid.",
              });
            } else if (err.errorMessage === "USERNAME_NOT_OCCUPIED") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "The provided username is not occupied.",
              });
            }
          }
        }
      });
  };

  doDisconnectedTelegram = (getApi) => {
    DisconnectedWS(getApi)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doLeaveGroup = (item) => {
    console.log(item);
    let dataAccount = this.state.selectGroupAccountTelegramId;
    if (dataAccount.info_linked.length > 0) {
      console.log(dataAccount.info_linked);
      dataAccount.info_linked.forEach((obj) => {
        return this.doLeaveChannel(obj, item.group_name);
      });
    }
  };

  doLeaveChannel = (obj, item) => {
    this.setState({
      submitLoading: true,
    });
    LeaveChannel(obj, item)
      .then((res) => {
        this.doDisconnectedTelegram(obj);
        console.log(res);
        this.setState({
          submitLoading: false,
        });
        console.log(res);
        this.doDeleteGroup(item);
      })
      .catch((err) => {
        this.doDisconnectedTelegram(obj);
        this.setState({
          submitLoading: false,
        });
        console.log(err.code);
        console.log(err.errorMessage);
        console.log(err.toString());
        if (
          err.toString() ===
          "RPCError: 406: AUTH_KEY_DUPLICATED (caused by InvokeWithLayer)"
        ) {
          this.doUpdateSession(obj.manage_id);
          console.log("keluar dari rotasi");
          this.setState({
            openValidation: true,
            titleValidation: "Error Code " + err.toString(),
            titleBody:
              "account" + obj.alias === ""
                ? obj.phonenumber
                : obj.alias + "must be updated session",
          });
        } else {
          if (err.code === 403) {
            this.setState({
              openValidation: true,
              titleValidation:
                "Error Code " + err.code + ": " + err.errorMessage,
              titleBody: "channel/supergroup not available.",
            });
          } else if (err.code === 400) {
            if (err.errorMessage === "CHANNEL_INVALID") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "The provided channel is invalid.",
              });
            } else if (err.errorMessage === "CHANNEL_PRIVATE") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "You haven't joined this channel/supergroup.",
              });
            } else if (err.errorMessage === "MSG_ID_INVALID") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "Invalid message ID provided.",
              });
            } else if (err.errorMessage === "USER_CREATOR") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody:
                  "You can't leave this channel, because you're its creator.",
              });
            } else if (err.errorMessage === "USER_NOT_PARTICIPANT") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "You're not a member of this supergroup/channel.",
              });
            }
          } else if (err.code === 401) {
            this.doUpdateBanned(obj.manage_id);
            this.setState({
              openValidation: true,
              titleValidation:
                "Error Code " + err.code + ": " + err.errorMessage,
              titleBody:
                "The temporary auth key must be binded to the permanent auth key to use these methods.",
            });
            this.handleCloseAddNew();
          } else if (err.code === 406) {
            this.doUpdateSession(obj.manage_id);
            this.setState({
              openValidation: true,
              titleValidation: "Error Code " + err.toString(),
              titleBody:
                "account" + obj.alias === ""
                  ? obj.phonenumber
                  : obj.alias + "must be updated session",
            });
            console.log("keluar dari rotasi");
          } else if (err.code === 420) {
            const format1 = "YYYY-MM-DD HH:mm:ss";
            let dt = new Date();
            dt.setSeconds(dt.getSeconds() + 43200);
            let DateAddNow = moment(dt).format(format1);
            console.log(DateAddNow);
            this.doUpdateFlood(obj.manage_id, DateAddNow);
            this.setState({
              openValidation: true,
              titleValidation: "Error Code " + err.code,
              titleBody:
                err.toString() + " " + obj.alias !== ""
                  ? obj.alias
                  : obj.phonenumber,
            });

            // if (err.errorMessage === "FLOOD") {
            //   const format1 = "YYYY-MM-DD HH:mm:ss";
            //   let dt = new Date();
            //   dt.setSeconds(dt.getSeconds() + 43200);
            //   let DateAddNow = moment(dt).format(format1);
            //   console.log(DateAddNow);
            //   this.doUpdateFlood(obj.manage_id, DateAddNow);
            //   this.setState({
            //     openValidation: true,
            //     titleValidation: "Error Code " + err.code,
            //     titleBody:
            //       err.toString() + " " + obj.alias !== ""
            //         ? obj.alias
            //         : obj.phonenumber,
            //   });
            // } else {
            //   const format1 = "YYYY-MM-DD HH:mm:ss";
            //   let dt = new Date();
            //   dt.setSeconds(dt.getSeconds() + 43200);
            //   let DateAddNow = moment(dt).format(format1);
            //   console.log(DateAddNow);
            //   this.doUpdateFlood(obj.manage_id, DateAddNow);
            //   this.setState({
            //     openValidation: true,
            //     titleValidation: "Error Code " + err.code,
            //     titleBody:
            //       err.toString() + " " + obj[0].alias !== ""
            //         ? obj[0].alias
            //         : obj[0].phonenumber,
            //   });
            // }
          } else {
            if (err.code !== undefined) {
              this.setState({
                openValidation: true,
                titleValidation: "Error Code " + err.code,
                titleBody: err.errorMessage,
              });
            }
          }
        }
      });
  };

  doJoinChannel = (obj, title, username) => {
    let channel = this.state.group_name;
    JoinChannel(obj, channel)
      .then((res) => {
        this.doDisconnectedTelegram(obj);
        console.log(res);
        if (res !== undefined || res !== null || res !== "") {
          // this.setState({
          //   openSuccess: true,
          //   openSuccessText: "Account successfully joined channel.",
          //   submitLoading: false,
          // });
          this.handleCloseAddNew();
          let params = {
            group_id: this.state.group_id,
            manage_id: obj.manage_id,
            group_account_id: this.state.selectGroupAccountTelegramId.value,
            phonenumber: obj.phonenumber,
            group_name: username,
            group_title: title,
          };
          this.doInsertJoinGroup(params);
        } else {
          this.setState({
            openFailed: true,
            openFailedText: "Account failed to join channel.",
            submitLoading: false,
          });
        }
      })
      .catch((err) => {
        this.handleCloseAddNew();
        this.doDisconnectedTelegram(obj);
        console.log(err.toString());
        console.log(err.code);
        console.log(err.errorMessage);
        this.setState({
          openFailed: true,
          openFailedText: "Account failed to join channel.",
          submitLoading: false,
        });
        if (
          err.toString() ===
          "RPCError: 406: AUTH_KEY_DUPLICATED (caused by InvokeWithLayer)"
        ) {
          this.doUpdateSession(obj.manage_id);
          this.setState({
            openValidation: true,
            titleValidation: "Error Code " + err.toString(),
            titleBody:
              "account" + obj.alias === ""
                ? obj.phonenumber
                : obj.alias + "must be updated session",
          });
          console.log("keluar dari rotasi");
        } else {
          if (err.code === 400) {
            if (err.errorMessage === "CHANNELS_TOO_MUCH") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "You have joined too many channels/supergroups.",
              });
            } else if (err.errorMessage === "CHANNEL_INVALID") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "The provided channel is invalid.",
              });
            } else if (err.errorMessage === "CHANNEL_PRIVATE") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "You haven't joined this channel/supergroup.",
              });
            } else if (err.errorMessage === "INVITE_HASH_EMPTY") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "The invite hash is empty.",
              });
            } else if (err.errorMessage === "INVITE_HASH_EXPIRED") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "The invite link has expired.",
              });
            } else if (err.errorMessage === "INVITE_HASH_INVALID") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "The invite hash is invalid.",
              });
            } else if (err.errorMessage === "MSG_ID_INVALID") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "Invalid message ID provided.",
              });
            } else if (err.errorMessage === "PEER_ID_INVALID") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "The provided peer id is invalid.",
              });
            } else if (err.errorMessage === "USERS_TOO_MUCH") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody:
                  "The maximum number of users has been exceeded (to create a chat, for example).",
              });
            } else if (err.errorMessage === "USER_ALREADY_PARTICIPANT") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody: "The user is already in the group.",
              });
            } else if (err.errorMessage === "USER_CHANNELS_TOO_MUCH") {
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody:
                  "One of the users you tried to add is already in too many channels/supergroups.",
              });
            }
          } else if (err.code === 401) {
            this.doUpdateBanned(obj.manage_id);
            this.setState({
              openValidation: true,
              titleValidation:
                "Error Code " + err.code + ": " + err.errorMessage,
              titleBody:
                "The temporary auth key must be binded to the permanent auth key to use these methods.",
            });
            this.handleCloseAddNew();
          } else if (err.code === 420) {
            const format1 = "YYYY-MM-DD HH:mm:ss";
            let dt = new Date();
            dt.setSeconds(dt.getSeconds() + 43200);
            let DateAddNow = moment(dt).format(format1);
            console.log(DateAddNow);
            this.doUpdateFlood(obj.manage_id, DateAddNow);
            this.setState({
              openValidation: true,
              titleValidation: "Error Code " + err.code,
              titleBody:
                err.toString() + " " + obj.alias !== ""
                  ? obj.alias
                  : obj.phonenumber,
            });

            // if (err.errorMessage === "FLOOD") {
            //   const format1 = "YYYY-MM-DD HH:mm:ss";
            //   let dt = new Date();
            //   dt.setSeconds(dt.getSeconds() + 43200);
            //   let DateAddNow = moment(dt).format(format1);
            //   console.log(DateAddNow);
            //   this.doUpdateFlood(obj.manage_id, DateAddNow);
            //   this.setState({
            //     openValidation: true,
            //     titleValidation: "Error Code " + err.code,
            //     titleBody:
            //       err.toString() + " " + obj.alias !== ""
            //         ? obj.alias
            //         : obj.phonenumber,
            //   });
            // } else {
            //   const format1 = "YYYY-MM-DD HH:mm:ss";
            //   let dt = new Date();
            //   dt.setSeconds(dt.getSeconds() + 43200);
            //   let DateAddNow = moment(dt).format(format1);
            //   console.log(DateAddNow);
            //   this.doUpdateFlood(obj.manage_id, DateAddNow);
            //   this.setState({
            //     openValidation: true,
            //     titleValidation: "Error Code " + err.code,
            //     titleBody:
            //       err.toString() + " " + obj.alias !== ""
            //         ? obj.alias
            //         : obj.phonenumber,
            //   });
            // }
          } else if (err.code === 406) {
            this.doUpdateSession(obj.manage_id);
            this.setState({
              openValidation: true,
              titleValidation: "Error Code " + err.toString(),
              titleBody:
                "account" + obj.alias === ""
                  ? obj.phonenumber
                  : obj.alias + "must be updated session",
            });
            console.log("keluar dari rotasi");
          } else {
            this.setState({
              openValidation: true,
              titleValidation:
                "Error Code " + err.code + ": " + err.errorMessage,
              titleBody: err.errorMessage,
            });
          }
        }
      });
  };

  doSubmit = () => {
    this.setState({
      submitLoading: true,
    });
    let dataAccount = this.state.selectGroupAccountTelegramId;
    if (dataAccount.info_linked.length > 0) {
      console.log(dataAccount.info_linked);
      let getApi = shuffle(dataAccount.info_linked);
      console.log(getApi);
      if (getApi.length > 0) {
        this.getResolveUsername(getApi[0], dataAccount.info_linked);
      }
    } else {
      this.setState({
        openValidation: true,
        titleValidation: "Empty group account telegram",
        titleBody: "Telegram account not available",
      });
      this.setState({
        submitLoading: false,
      });
    }
  };

  checkData = () => {
    const { group_name, selectGroupAccountTelegramId } = this.state;

    if (group_name === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty group telegram?",
        titleBody: "Please enter group",
      });
    } else if (selectGroupAccountTelegramId === null) {
      this.setState({
        openValidation: true,
        titleValidation: "Empty group account telegram?",
        titleBody: "Select group account telegram",
      });
    } else {
      this.doSubmit();
    }
  };

  handleChangeAccount = (selectGroupAccountTelegramId) => {
    // console.log(selectGroupAccountTelegramId);

    const format1 = "YYYY-MM-DD HH:mm:ss";
    var date = new Date();
    var DateTimeNow = moment(date).format(format1);

    if (selectGroupAccountTelegramId.info_linked.length > 0) {
      let dataFilterFlood = selectGroupAccountTelegramId.info_linked.filter(
        (obj) => obj.status === 4 && obj.flood >= DateTimeNow
      );

      console.log("DATA ACCOUNT FLOOD", dataFilterFlood);

      let dataFloodToActive = selectGroupAccountTelegramId.info_linked.filter(
        (obj) => obj.status === 4 && obj.flood < DateTimeNow
      );

      console.log("DATA ACCOUNT FLOOD TO ACTIVE", dataFloodToActive);

      if (dataFloodToActive.length > 0) {
        dataFloodToActive.forEach((obj) => {
          console.log(obj);
          return this.doUpdateAccountActive(obj.manage_id);
        });
      }

      let dataUnFlood = selectGroupAccountTelegramId.info_linked.filter(
        (val) => !dataFilterFlood.includes(val)
      );
      console.log("DATA ACCOUNT UNFLOOD", dataUnFlood);

      let arrGroupAccount = [];
      arrGroupAccount.push(selectGroupAccountTelegramId);
      for (var i = 0; i < arrGroupAccount.length; i++) {
        arrGroupAccount[i].info_linked = dataUnFlood;
      }

      console.log("REAL ACCOUNT", arrGroupAccount[0]);

      this.setState({ selectGroupAccountTelegramId: arrGroupAccount[0] });
      this.getAllJoinGroup(selectGroupAccountTelegramId.value);
    } else {
      console.log("normal");
      this.setState({ selectGroupAccountTelegramId });
      this.getAllJoinGroup(selectGroupAccountTelegramId.value);
    }
  };

  doRowDeleteInfo = (item) => {
    this.setState({
      openAlertDelete: true,
      itemDeleted: item,
    });
  };

  handleCloseItemInfo = () => {
    this.setState({
      openAlertDelete: false,
      itemDeleted: {},
    });
  };

  renderRemoveItemInfo = () => {
    let item = this.state.itemDeleted;
    return (
      <Dialog
        open={this.state.openAlertDelete}
        onClose={this.handleCloseItemInfo}
        aria-labelledby="customized-dialog-title"
        fullWidth="sm"
        maxWidth="sm"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography
              component="span"
              variant="h2"
              style={stylesDialog.title}
            >
              Leave Group?
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseItemInfo()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ marginTop: 10 }}>
          <DialogContentText id="alert-dialog-description">
            <Typography
              component="span"
              variant="body2"
              style={(stylesListDialog.inline, { fontSize: 16, color: "#333" })}
            >
              Are you sure want to leave group {item.group_name}?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#808080",
            }}
            startIcon={<Cancel />}
            onClick={this.handleCloseItemInfo}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>{" "}
          <Button
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#0693e3",
            }}
            startIcon={<Check />}
            onClick={() => this.doLeaveGroup(item)}
          >
            {this.state.submitLoading === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Yes
              </Typography>
            )}
          </Button>{" "}
        </DialogActions>
      </Dialog>
    );
  };

  doRowEdit = (row) => {
    this.setState({
      setOpenEdit: true,
      group_id: row.group_id,
      group_name: row.group_name,
    });
  };

  pressReset = () => {
    // console.log("cek data");
    // this.props.doLoading();
    this.setState({ openSuccess: false, openSuccessText: "" });
    // this.getAllJoinGroup();
    this.getAllJoinGroup(this.state.selectGroupAccountTelegramId.value);
  };

  pressAddNew = () => {
    this.setState({
      setOpenAddNew: true,
    });
  };

  handleCloseAddNew = () => {
    this.setState({
      setOpenAddNew: false,
      setOpenEdit: false,
    });
  };

  renderDialogAddNew = () => {
    return (
      <Dialog
        onClose={this.handleCloseAddNew}
        aria-labelledby="customized-dialog-title"
        open={
          this.state.setOpenAddNew === true
            ? this.state.setOpenAddNew
            : this.state.setOpenEdit
        }
        fullWidth="md"
        maxWidth="md"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography variant="h5" style={stylesDialog.title}>
              {this.state.setOpenAddNew === true
                ? "Join Accounts to Group"
                : this.state.setOpenEdit === true
                ? "Edit Group Contact"
                : this.state.setOpenEdit}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseAddNew()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 60, marginTop: 20 }}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  style={{
                    float: "left",
                    marginTop: 6,
                    color: "#000",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  Group
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Input
                  autoComplete="off"
                  type="text"
                  name="group_name"
                  id="group_name"
                  placeholder="username group"
                  value={this.state.group_name}
                  onChange={(event) =>
                    this.setState({ group_name: event.target.value })
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#d0021b",
            }}
            startIcon={<Cancel />}
            onClick={() => this.handleCloseAddNew()}
          >
            <Typography
              variant="button"
              style={{
                color: "#fff",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Typography>
          </Button>
          &nbsp;&nbsp;
          <Button
            disabled={this.state.submitLoading}
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#004dcf",
            }}
            startIcon={<GroupAdd />}
            onClick={() => this.checkData()}
          >
            {this.state.submitLoading === true ? (
              <CircularProgress style={{ color: "#fff" }} size={24} />
            ) : (
              <Typography
                variant="button"
                style={{
                  color: "#fff",
                  textTransform: "capitalize",
                }}
              >
                Join
              </Typography>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth="xs"
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  closeStackSuccess = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
    });
  };

  renderSuccess = () => {
    if (this.state.openSuccess === true) {
      setTimeout(() => this.pressReset(), 2000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  // onClick={() => this.reset()}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openSuccessText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  color: "#FFF",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <Typography
                  component="span"
                  variant="h1"
                  style={
                    (stylesListComent.inline,
                    {
                      fontSize: 20,
                      color: "#000",
                      fontWeight: "bold",
                    })
                  }
                >
                  Join Accounts to Group
                </Typography>
                <br></br>
                <div className="contentDate">
                  <div
                    style={{
                      marginRight: 16,
                      width: 250,
                      position: "relative",
                      zIndex: 2,
                    }}
                  >
                    <Select
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                      classNamePrefix="select"
                      placeholder="Select a group account"
                      value={this.state.selectGroupAccountTelegramId}
                      onChange={this.handleChangeAccount}
                      options={this.state.DataGroupAccountTelegram}
                    />
                  </div>
                  <div style={{ marginRight: 0 }}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#008b02",
                      }}
                      startIcon={<AddBox />}
                      onClick={() => this.pressAddNew()}
                    >
                      <Typography
                        variant="button"
                        style={{
                          fontSize: 14,
                          color: "#fff",
                          textTransform: "capitalize",
                        }}
                      >
                        Add New
                      </Typography>
                    </Button>
                  </div>
                </div>
                <br></br>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <br></br>
        <div className="box-container">
          <ReactTable
            ref={(r) => (this.reactTable = r)}
            data={this.state.tableData}
            columns={this.tableColumns}
            style={{ backgroundColor: "#f2f2f2" }}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id])
                .toLowerCase()
                .includes(filter.value.toLowerCase())
            }
            defaultPageSize={5}
          />
        </div>
        {this.renderDialogAddNew()}
        {this.renderValidationAlert()}
        {this.renderSuccess()}
        {this.renderRemoveItemInfo()}
      </Box>
    );
  }
}
export default JoinGroupPage;

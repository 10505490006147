var path = window.location.pathname;
var href = window.location.href;

var get_current_ip = href.replace(path, "");

console.log("get_current_ip: ", get_current_ip);

var public_ip = "https://teletool.xyz";
var subdirectory = "/api/";

//path
// var serverUrl_current = get_current_ip + subdirectory;

//ip
var serverUrl_current = public_ip + subdirectory;

// console.log("full url", serverUrl_current);

export var webserviceurl = serverUrl_current;

//DEVEL
// export var webserviceurl =
//   "https://idr45.duniadalamdigital.com/telegram-mkt-tools/api/";

//PROD
// export var webserviceurl =
//   "https://app.duniadalamdigital.com/telegram-mkt-tools/api/";

// export var webserviceurl = "http://128.199.148.63/telegram-mkt-tools/api";
// export var webserviceurl = "https://www.gramtools.xyz/telegram-mkt-tools/api";

import React, { Component } from "react";
import {
  apiGroupContactList,
  apiAllContactListNoLimit,
  apiAllContactGroupIdNoLimit,
  apiJoinGroupList,
  apiManageApiAccount,
  apiGroupAccountList,
  apiUpdateAccountBanned,
  apiUpdateAccountSession,
  apiUpdateAccountFlood,
  apiUpdateAccountActive,
  apiUpdateMaxDate,
  apiUpdateMaxInvite,
  apiUpdateNormalAccount,
} from "../../Service/api";
import {
  DisconnectedWS,
  InviteToChannel,
  ConnectTelegram,
} from "../../TelegramApi/ClinetApiTelegram";
import { Api, TelegramClient } from "telegram";
import { StringSession } from "telegram/sessions";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  DialogContentText,
  DialogTitle,
  Slide,
  Checkbox,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Stack,
  Alert,
  AppBar,
  Toolbar,
} from "@mui/material";
import moment from "moment";
import Select from "react-select";
import { Input } from "reactstrap";
import {
  Contacts,
  Close,
  StopCircle,
  AddBox,
  GroupAdd,
  Search,
} from "@mui/icons-material";
// import { shuffle, getRoundRobin } from "../../../global";
import { shuffle, getRoundRobin } from "../../../global";
const stylesListComent = {
  inline: {
    display: "inline",
  },
};

const stylesListDialog = {
  inline: {
    display: "inline",
  },
};

const stylesDialog = {
  appBar: {
    position: "relative",
    backgroundColor: "#03101e",
  },
  title: {
    marginLeft: 0,
    flex: 1,
    fontSize: 16,
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class InviteChannelPage extends Component {
  constructor(props) {
    super(props);
    this.reactTable = React.createRef();
    this.searchContact = React.createRef();
    this.SetIndexText = React.createRef();
    this.state = {
      tableData: [],
      tableDisplay: [],
      filter: "",
      channel_group: "",
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      showLoading: false,
      showTextGetParticipants: false,
      arrDataUserParticipants: [],
      selectedGroupId: null,
      groupShow: [],
      AllContact: [],
      selectAll: false,
      pushData: [],
      openSuccess: false,
      openSuccessText: "",
      openFailed: false,
      openFailedText: "",
      selectAll: false,
      checked: [],
      selectRowData: [],
      LoadingInvite: false,
      accountShow: [],
      GroupJoinShow: [],
      selectedGroupJoin: null,
      accountShow: [],
      DataGroupAccountTelegram: [],
      selectGroupAccountTelegramId: null,
      openResult: false,
      DataUserNameInvite: [],
      dataAccountReal: [],
      totalAccount: 0,
      getRoundRobinIdx: 0,
      setTimeOver: 0,
    };
  }

  componentDidMount() {
    this.getDataGroupTelegramAccount();
    this.getDataAccounts();
    this.getDataGroup();
    this.getAllContact();

    // let dataAIU = [
    //   { id: 1, name: "A" },
    //   { id: 2, name: "B" },
    //   { id: 3, name: "C" },
    //   { id: 4, name: "D" },
    //   { id: 5, name: "E" },
    // ];

    // let timeOut = 60000 / 10;

    // const promises = dataAIU.map(
    //   (obj, i) =>
    //     new Promise((resolve) =>
    //       setTimeout(
    //         () => {
    //           console.log(obj);
    //           resolve();
    //         },
    //         timeOut + Math.floor(Math.random() * 10000) * i,
    //         console.log(timeOut + Math.floor(Math.random() * 10000) * i)
    //       )
    //     )
    // );
    // Promise.all(promises).then(() => console.log("DONE(BERES)"));
  }

  getDataGroupTelegramAccount = () => {
    apiGroupAccountList()
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          var temp = this.state.tableData;
          temp = data.records;
          for (var i = 0; i < temp.length; i++) {
            temp[i].id = i + 1;
            temp[i].total = temp[i].info_linked.length;
            temp[i].status = temp[i].is_available === 0 ? "Hide" : "Show";
            temp[i].value = temp[i].group_account_id;
            temp[i].label = temp[i].group_account_name;
          }

          if (this.state.selectGroupAccountTelegramId !== null) {
            let updateData = temp.filter(
              (obj) =>
                obj.value === this.state.selectGroupAccountTelegramId.value
            );

            console.log("Mount Data", updateData[0]);

            const format1 = "YYYY-MM-DD HH:mm:ss";
            var date = new Date();
            var DateTimeNow = moment(date).format(format1);

            if (updateData[0].info_linked.length > 0) {
              let dataFilterFlood = updateData[0].info_linked.filter(
                (obj) => obj.status === 4 && obj.flood >= DateTimeNow
              );

              console.log("DATA ACCOUNT FLOOD", dataFilterFlood);

              let dataFloodToActive = updateData[0].info_linked.filter(
                (obj) => obj.status === 4 && obj.flood < DateTimeNow
              );

              console.log("DATA ACCOUNT FLOOD TO ACTIVE", dataFloodToActive);

              if (dataFloodToActive.length > 0) {
                dataFloodToActive.map((obj) => {
                  return this.doUpdateAccountActive(obj.manage_id);
                });
              }

              let dataUnFlood = updateData[0].info_linked.filter(
                (val) => !dataFilterFlood.includes(val)
              );
              console.log("DATA ACCOUNT UNFLOOD", dataUnFlood);

              let dataAccountMinInvite = dataUnFlood.filter(
                (obj) => obj.count_invite !== 5
              );

              console.log("DATA UNFLOOD MIN INVITE", dataAccountMinInvite);

              let dataUnFloodUpdateZero = dataUnFlood.filter(
                (obj) =>
                  obj.count_invite === 5 && obj.count_maxdate < DateTimeNow
              );

              if (dataUnFloodUpdateZero.length > 0) {
                dataUnFloodUpdateZero.forEach((obj) => {
                  // console.log(obj);
                  return this.doUpdateAccountNormal(
                    obj.manage_id,
                    "0000-00-00 00:00:00"
                  );
                });
              }

              let arrGroupAccount = [];
              arrGroupAccount.push(updateData[0]);
              for (var i = 0; i < arrGroupAccount.length; i++) {
                arrGroupAccount[i].info_linked = dataAccountMinInvite;
              }

              console.log("REAL ACCOUNT", arrGroupAccount[0]);

              this.setState({
                selectGroupAccountTelegramId: arrGroupAccount[0],
                DataGroupAccountTelegram: temp,
              });
              this.getAllJoinGroup(arrGroupAccount[0].group_account_id);
            } else {
              console.log("normal");
              this.setState({
                selectGroupAccountTelegramId: updateData[0],
                DataGroupAccountTelegram: temp,
              });
              this.getAllJoinGroup(updateData[0].group_account_id);
            }
          } else {
            console.log("Mount Data", temp[0]);

            const format1 = "YYYY-MM-DD HH:mm:ss";
            var date = new Date();
            var DateTimeNow = moment(date).format(format1);

            if (temp[0].info_linked.length > 0) {
              let dataFilterFlood = temp[0].info_linked.filter(
                (obj) => obj.status === 4 && obj.flood >= DateTimeNow
              );

              console.log("DATA ACCOUNT FLOOD", dataFilterFlood);

              let dataFloodToActive = temp[0].info_linked.filter(
                (obj) => obj.status === 4 && obj.flood < DateTimeNow
              );

              console.log("DATA ACCOUNT FLOOD TO ACTIVE", dataFloodToActive);

              if (dataFloodToActive.length > 0) {
                dataFloodToActive.map((obj) => {
                  return this.doUpdateAccountActive(obj.manage_id);
                });
              }

              let dataUnFlood = temp[0].info_linked.filter(
                (val) => !dataFilterFlood.includes(val)
              );
              console.log("DATA ACCOUNT UNFLOOD", dataUnFlood);

              let dataAccountMinInvite = dataUnFlood.filter(
                (obj) => obj.count_invite !== 5
              );

              console.log("DATA UNFLOOD MIN INVITE", dataAccountMinInvite);

              let dataUnFloodUpdateZero = dataUnFlood.filter(
                (obj) =>
                  obj.count_invite === 5 && obj.count_maxdate < DateTimeNow
              );

              if (dataUnFloodUpdateZero.length > 0) {
                dataUnFloodUpdateZero.forEach((obj) => {
                  // console.log(obj);
                  return this.doUpdateAccountNormal(
                    obj.manage_id,
                    "0000-00-00 00:00:00"
                  );
                });
              }

              let arrGroupAccount = [];
              arrGroupAccount.push(temp[0]);
              for (var i = 0; i < arrGroupAccount.length; i++) {
                arrGroupAccount[i].info_linked = dataAccountMinInvite;
              }

              console.log("REAL ACCOUNT", arrGroupAccount[0]);

              this.setState({
                selectGroupAccountTelegramId: arrGroupAccount[0],
              });
              this.getAllJoinGroup(arrGroupAccount[0].group_account_id);
            } else {
              console.log("normal");
              this.setState({
                selectGroupAccountTelegramId: temp[0],
                DataGroupAccountTelegram: temp,
              });
              this.getAllJoinGroup(temp[0].group_account_id);
            }

            this.getAllJoinGroup(temp[0].group_account_id);
            this.setState({
              DataGroupAccountTelegram: temp,
              selectGroupAccountTelegramId: temp[0],
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getDataAccounts = () => {
    apiManageApiAccount()
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          var temp = this.state.accountShow;
          temp = data.records;
          for (var i = 0; i < temp.length; i++) {
            temp[i].value = temp[i].manage_id;
            temp[i].label = temp[i].alias;
          }
          this.setState({ accountShow: temp });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getAllJoinGroup = (group_account_id) => {
    apiJoinGroupList(group_account_id)
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          var temp = [];
          temp = data.records;
          for (var i = 0; i < temp.length; i++) {
            temp[i].value = temp[i].group_name;
            temp[i].label = temp[i].group_title;
          }
          this.setState({ GroupJoinShow: temp });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getDataGroup = () => {
    apiGroupContactList()
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          var temp = this.state.groupShow;
          temp = data.records;
          for (var i = 0; i < temp.length; i++) {
            temp[i].value = temp[i].group_id;
            temp[i].label = temp[i].group_name;
          }
          this.setState({ groupShow: temp });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getAllContact = () => {
    this.props.doLoading();
    apiAllContactListNoLimit(this.state.filter)
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          var temp = this.state.AllContact;
          temp = data.records;
          for (var i = 0; i < temp.length; i++) {
            temp[i].isChecked = false;
            temp[i].id = i + 1;
          }
          this.setState({ AllContact: temp });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  getAllContactByGroup = (group_id) => {
    this.props.doLoading();
    apiAllContactGroupIdNoLimit(group_id)
      .then((res) => {
        this.props.doLoading();
        let data = res.data;
        if (data.status === "OK") {
          var temp = this.state.AllContact;
          temp = data.records;
          for (var i = 0; i < temp.length; i++) {
            temp[i].isChecked = false;
            temp[i].id = i + 1;
          }
          this.setState({ AllContact: temp });
        }
      })
      .catch((err) => {
        this.props.doLoading();
        console.log(err);
      });
  };

  doUpdateBanned = (manage_id) => {
    apiUpdateAccountBanned(manage_id)
      .then((res) => {
        console.log(res);
        let data = res.data;
        if (data.status === "OK") {
          this.getDataGroupTelegramAccount();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doUpdateSession = (manage_id) => {
    apiUpdateAccountSession(manage_id)
      .then((res) => {
        console.log(res);
        let data = res.data;
        if (data.status === "OK") {
          this.getDataGroupTelegramAccount();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doUpdateAccountActive = (manage_id) => {
    apiUpdateAccountActive(manage_id)
      .then((res) => {
        // console.log(res);
        let data = res.data;
        if (data.status === "OK") {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doUpdateAccountMaxInvite = (manage_id, count_invite) => {
    let params = {
      manage_id: manage_id,
      max_invite: count_invite,
    };

    apiUpdateMaxInvite(params)
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          this.getDataGroupTelegramAccount();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doUpdateAccountNormal = (manage_id, maxDate) => {
    let params = {
      manage_id: manage_id,
      max_date: maxDate,
    };

    apiUpdateNormalAccount(params)
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          this.getDataGroupTelegramAccount();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doUpdateAccountMaxDate = (manage_id, maxDate) => {
    let params = {
      manage_id: manage_id,
      max_date: maxDate,
    };
    apiUpdateMaxDate(params)
      .then((res) => {
        let data = res.data;
        if (data.status === "OK") {
          // this.getDataGroupTelegramAccount();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doUpdateFlood = (manage_id, flood_time) => {
    let params = {
      manage_id: manage_id,
      flood_time: flood_time,
    };
    apiUpdateAccountFlood(params)
      .then((res) => {
        console.log(res);
        let data = res.data;
        if (data.status === "OK") {
          this.getDataGroupTelegramAccount();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doDisconnectedTelegram = (getApi) => {
    DisconnectedWS(getApi)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  doSearchContact = () => {
    this.setState({
      tableData: [],
    });
    this.getAllContact();
  };

  handleCheck = (e, i) => {
    let tmp = this.state.AllContact;
    tmp[i].isChecked = e.target.checked;
    this.setState({ AllContact: tmp });
    this.setState({ checked: tmp[i].isChecked });

    var checkedCopy = this.state.checked;
    checkedCopy[i] = !this.state.checked[i];
    if (checkedCopy[i] === false) {
      this.setState({ selectAll: false });
    }
    this.setState({
      checked: checkedCopy,
    });
  };

  // toggleSelectAll(e) {
  //   let dataParticipants = this.state.AllContact;
  //   for (let i = 0; i < dataParticipants.length; i++) {
  //     dataParticipants[i].isChecked = e.target.checked;
  //   }
  //   this.setState({
  //     AllContact: dataParticipants,
  //     selectAll: e.target.checked,
  //   });
  // }

  toggleSelectAll(e) {
    let tmp = this.state.AllContact;
    for (let i = 0; i < tmp.length; i++) {
      tmp[i].isChecked = e.target.checked;
    }
    this.setState({ AllContact: tmp, selectAll: e.target.checked });
    var selectAll = !this.state.selectAll;
    this.setState({ selectAll: selectAll });
    var checkedCopy = [];
    tmp.forEach(function (e, index) {
      checkedCopy.push(selectAll);
    });
    this.setState({
      checked: checkedCopy,
    });
  }

  handleChangeOptionGroup = (selectedGroupId) => {
    this.setState({ selectedGroupId });
    this.getAllContactByGroup(selectedGroupId.value);
  };

  handleChangeOptionGroupJoin = (selectedGroupJoin) => {
    this.setState({ selectedGroupJoin });
  };

  doConnectedTelegram = (getApi) => {
    ConnectTelegram(getApi)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChangeAccount = (selectGroupAccountTelegramId) => {
    const format1 = "YYYY-MM-DD HH:mm:ss";
    var date = new Date();
    var DateTimeNow = moment(date).format(format1);

    if (selectGroupAccountTelegramId.info_linked.length > 0) {
      let dataFilterFlood = selectGroupAccountTelegramId.info_linked.filter(
        (obj) => obj.status === 4 && obj.flood >= DateTimeNow
      );

      console.log("DATA ACCOUNT FLOOD", dataFilterFlood);

      let dataFloodToActive = selectGroupAccountTelegramId.info_linked.filter(
        (obj) => obj.status === 4 && obj.flood < DateTimeNow
      );

      console.log("DATA ACCOUNT FLOOD TO ACTIVE", dataFloodToActive);

      if (dataFloodToActive.length > 0) {
        dataFloodToActive.forEach((obj) => {
          // console.log(obj);
          return this.doUpdateAccountActive(obj.manage_id);
        });
      }

      let dataUnFlood = selectGroupAccountTelegramId.info_linked.filter(
        (val) => !dataFilterFlood.includes(val)
      );
      console.log("DATA ACCOUNT UNFLOOD", dataUnFlood);

      let dataAccountMinInvite = dataUnFlood.filter(
        (obj) => obj.count_invite !== 5
      );

      console.log("DATA UNFLOOD MIN INVITE", dataAccountMinInvite);

      let dataUnFloodUpdateZero = dataUnFlood.filter(
        (obj) => obj.count_invite === 5 && obj.count_maxdate < DateTimeNow
      );

      if (dataUnFloodUpdateZero.length > 0) {
        dataUnFloodUpdateZero.forEach((obj) => {
          // console.log(obj);
          return this.doUpdateAccountNormal(
            obj.manage_id,
            "0000-00-00 00:00:00"
          );
        });
      }

      // dataUnFlood.map((obj) => {
      //   console.log(obj);
      //   this.doConnectedTelegram(obj);
      // });

      let arrGroupAccount = [];
      arrGroupAccount.push(selectGroupAccountTelegramId);
      for (var i = 0; i < arrGroupAccount.length; i++) {
        arrGroupAccount[i].info_linked = dataAccountMinInvite;
      }

      console.log("REAL ACCOUNT", arrGroupAccount[0]);

      this.setState({
        selectGroupAccountTelegramId: arrGroupAccount[0],
        selectedGroupJoin: null,
      });
      this.getAllJoinGroup(selectGroupAccountTelegramId.value);
    } else {
      console.log("normal");
      this.setState({ selectGroupAccountTelegramId, selectedGroupJoin: null });
      this.getAllJoinGroup(selectGroupAccountTelegramId.value);
    }

    // this.setState({ selectGroupAccountTelegramId, selectedGroupJoin: null, selectedGroupJoin: null });
    // this.getAllJoinGroup(selectGroupAccountTelegramId.value);
  };

  pressStopInvite = () => {
    this.setState({
      LoadingInvite: false,
    });

    let arrGroupAccount = [];
    arrGroupAccount.push(this.state.selectGroupAccountTelegramId);
    for (var i = 0; i < arrGroupAccount.length; i++) {
      arrGroupAccount[i].info_linked = [];
    }

    this.setState({
      selectGroupAccountTelegramId: arrGroupAccount[0],
      selectedGroupJoin: null,
    });

    // setTimeout(() => {
    //   this.getDataGroupTelegramAccount();
    // }, 3000);

    // var getApiHash = this.state.dataAccountReal;
    // const promises = getApiHash.map(
    //   (obj, i) =>
    //     new Promise((resolve) =>
    //       setTimeout(() => {
    //         console.log(obj);
    //         return this.disconnected(obj);
    //         resolve();
    //       }, 3000 * i)
    //     )
    // );
    // Promise.all(promises).then(() => console.log("disconnected"));
  };

  pressInviteChannel = () => {
    this.getDataGroupTelegramAccount();

    let newIndex = this.SetIndexText.current.value;

    console.log(newIndex);

    const result = this.state.AllContact.filter((v) => v.isChecked === true);

    if (this.state.selectedGroupJoin === null) {
      this.setState({
        openValidation: true,
        titleValidation: "Empty group?",
        titleBody: "Select a group/channel telegram.",
      });
    } else if (newIndex === "") {
      this.setState({
        openValidation: true,
        titleValidation: "Empty set index?",
        titleBody: "Enter set index.",
      });
    } else if (result.length === 0) {
      this.setState({
        openValidation: true,
        titleValidation: "Select Contact?",
        titleBody: "Select a contact to invite channel.",
      });
    } else {
      const result = this.state.AllContact.filter(
        (v) => v.isChecked === true && v.username !== null
      );
      let data = [];
      result.map((obj, i) => {
        data.push(obj.username);
      });
      console.log(data);
      console.log(data.slice(newIndex));

      // this.setState({
      //   openResult: true,
      //   LoadingInvite: true,
      // });
      let dataAccount = this.state.selectGroupAccountTelegramId;
      if (dataAccount.info_linked.length > 0) {
        let timeOut = 60000 / dataAccount.info_linked.length + 1000;
        console.log(timeOut);

        this.setState({
          dataAccountReal: dataAccount.info_linked,
        });

        if (this.state.selectGroupAccountTelegramId.info_linked.length > 0) {
          this.setState({
            openResult: true,
            LoadingInvite: true,
          });
          const promises = data.slice(newIndex).map(
            (obj, i) =>
              new Promise((resolve, reject) =>
                setTimeout(() => {
                  if (
                    this.state.selectGroupAccountTelegramId.info_linked
                      .length === 0
                  ) {
                    reject();
                    if (this.state.LoadingInvite === false) {
                      reject();
                    }
                  } else {
                    console.log(obj);
                    this.doInviteToChannel(obj);

                    resolve();
                  }
                }, timeOut * i)
              )
          );
          Promise.all(promises).then(() => console.log("done"));
        }
      } else {
        this.setState({
          openValidation: true,
          titleValidation: "Empty group account telegram",
          titleBody: "Telegram account not available",
        });
      }
    }
  };

  TEst = (obj) => {
    console.log(obj);
  };

  async disconnected(getApi) {
    const apiId = getApi.api_id;
    const apiHash = getApi.api_hash;
    const session = new StringSession(getApi.string_token);
    const client = new TelegramClient(session, apiId, apiHash, {
      connectionRetries: 5,
    });
    await client.session.setDC(5, "flora.web.telegram.org", 443);
    await client.disconnect();
  }

  pressStop = () => {
    this.setState({
      showLoading: false,
    });
  };

  makeid = (length) => {
    var result = "";
    var characters = "0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  doInviteToChannel = (users) => {
    this.props.doLoading();
    var dataHasResultInvite = this.state.DataUserNameInvite;
    let dataAccount = this.state.selectGroupAccountTelegramId;

    console.log(dataAccount);
    if (dataAccount.info_linked.length > 0) {
      let totalAccount = dataAccount.info_linked.length;
      this.setState({
        totalAccount: totalAccount,
      });

      let DataAccountTelegram = dataAccount.info_linked.sort((a, b) => {
        if (a.manage_id < b.manage_id) return -1;
        if (a.manage_id > b.manage_id) return 1;
        return 0;
      });
      let getApi = getRoundRobin(
        this.state.getRoundRobinIdx,
        DataAccountTelegram
      );

      var getAPI = getApi[0];
      console.log(getAPI);

      if (totalAccount === this.state.getRoundRobinIdx) {
        this.setState({
          getRoundRobinIdx: 0,
        });
      } else {
        this.setState({
          getRoundRobinIdx: this.state.getRoundRobinIdx + 1,
        });
      }

      console.log(
        "Account Telegram:",
        getAPI.alias === "" ? getAPI.phonenumber : getAPI.alias
      );

      console.log("Account Full Telegram:", getAPI);

      if (getAPI.count_invite <= 5) {
        let count = parseInt(getAPI.count_invite) + 1;
        this.doUpdateAccountMaxInvite(getAPI.manage_id, count);

        const format1 = "YYYY-MM-DD HH:mm:ss";
        let dt = new Date();
        dt.setSeconds(dt.getSeconds() + 86400);
        let maxDateInvite = moment(dt).format(format1);
        console.log(maxDateInvite);
        this.doUpdateAccountMaxDate(getAPI.manage_id, maxDateInvite);
      }
      //  else if (getAPI.count_invite === 5) {

      // }

      let userparams = [];
      userparams.push(users);
      console.log(userparams);

      let channel = this.state.selectedGroupJoin.value;

      // setTimeout(() => {
      //   this.props.doLoading();
      // }, 3000);

      InviteToChannel(getAPI, channel, userparams)
        .then((res) => {
          this.props.doLoading();
          this.doDisconnectedTelegram(getAPI);
          console.log(res);
          if (res !== undefined || res !== null || res !== "") {
            dataHasResultInvite.push({
              username: users,
              status: "SUCCESS",
            });
            this.setState({
              DataUserNameInvite: dataHasResultInvite,
              openSuccess: true,
              openSuccessText: "Invite to channel is successful.",
            });
          }
        })
        .catch((err) => {
          this.props.doLoading();
          this.doDisconnectedTelegram(getAPI);
          console.log(err.toString());
          console.log(err);
          console.log(err.errorMessage);
          if (
            err.toString() ===
            "RPCError: 406: AUTH_KEY_DUPLICATED (caused by InvokeWithLayer)"
          ) {
            this.doUpdateSession(getAPI.manage_id);
            this.setState({
              openValidation: true,
              titleValidation: "Error Code " + err.toString(),
              titleBody:
                "account" + getAPI.alias === ""
                  ? getAPI.phonenumber
                  : getAPI.alias + "must be updated session",
            });
          } else {
            if (err.code === 401) {
              this.doUpdateBanned(getAPI.manage_id);
              this.setState({
                openValidation: true,
                titleValidation:
                  "Error Code " + err.code + ": " + err.errorMessage,
                titleBody:
                  "The temporary auth key must be binded to the permanent auth key to use these methods.",
              });
            } else if (err.code === 406) {
              this.doUpdateSession(getAPI.manage_id);
              this.setState({
                openValidation: true,
                titleValidation: "Error Code " + err.toString(),
                titleBody:
                  "account" + getAPI.alias === ""
                    ? getAPI.phonenumber
                    : getAPI.alias + "must be updated session",
              });
              console.log("keluar dari rotasi");
            } else if (err.code === 420) {
              const format1 = "YYYY-MM-DD HH:mm:ss";
              let dt = new Date();
              dt.setSeconds(dt.getSeconds() + 43200);
              let DateAddNow = moment(dt).format(format1);
              console.log(DateAddNow);
              this.doUpdateFlood(getAPI.manage_id, DateAddNow);
              this.setState({
                openValidation: true,
                titleValidation: "Error Code " + err.code,
                titleBody:
                  err.toString() + " " + getAPI.alias !== ""
                    ? getAPI.alias
                    : getAPI.phonenumber,
              });
            } else if (err.code === 403) {
              if (err.errorMessage === "CHAT_WRITE_FORBIDDEN") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "You can't write in this chat.",
                });
              } else if (err.errorMessage === "USER_CHANNELS_TOO_MUCH") {
                dataHasResultInvite.push({
                  username: users,
                  status: "USER_CHANNELS_TOO_MUCH",
                });
                this.setState({
                  DataUserNameInvite: dataHasResultInvite,
                });
              } else if (err.errorMessage === "USER_PRIVACY_RESTRICTED") {
                dataHasResultInvite.push({
                  username: users,
                  status: "USER_PRIVACY_RESTRICTED",
                });

                this.setState({
                  DataUserNameInvite: dataHasResultInvite,
                });
              }
            } else if (err.code === 400) {
              if (err.errorMessage === "BOTS_TOO_MUCH") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "There are too many bots in this chat/channel.",
                });
              } else if (err.errorMessage === "BOT_GROUPS_BLOCKED") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "This bot can't be added to groups.",
                });
              } else if (err.errorMessage === "CHANNEL_INVALID") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "The provided channel is invalid.",
                });
              } else if (err.errorMessage === "CHANNEL_PRIVATE") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "You haven't joined this channel/supergroup.",
                });
              } else if (err.errorMessage === "CHAT_ADMIN_REQUIRED") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "You must be an admin in this chat to do this.",
                });
              } else if (err.errorMessage === "CHAT_INVALID") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "Invalid chat.",
                });
              } else if (err.errorMessage === "CHAT_WRITE_FORBIDDEN") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "You can't write in this chat.",
                });
              } else if (err.errorMessage === "INPUT_USER_DEACTIVATED") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "The specified user was deleted.",
                });
              } else if (err.errorMessage === "MSG_ID_INVALID") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "Invalid message ID provided.",
                });
              } else if (err.errorMessage === "USERS_TOO_MUCH") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody:
                    "The maximum number of users has been exceeded (to create a chat, for example).",
                });
              } else if (err.errorMessage === "USER_BANNED_IN_CHANNEL") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody:
                    "You're banned from sending messages in supergroups/channels.",
                });
              } else if (err.errorMessage === "USER_BLOCKED") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "User blocked.",
                });
              } else if (err.errorMessage === "USER_BOT") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "Bots can only be admins in channels.",
                });
              } else if (err.errorMessage === "USER_ID_INVALID") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody: "The provided user ID is invalid.",
                });
              } else if (err.errorMessage === "USER_KICKED") {
                this.setState({
                  openValidation: true,
                  titleValidation:
                    "Error Code " + err.code + ": " + err.errorMessage,
                  titleBody:
                    "This user was kicked from this supergroup/channel.",
                });
              } else if (err.errorMessage === "USER_NOT_MUTUAL_CONTACT") {
                dataHasResultInvite.push({
                  username: users,
                  status: "USER_NOT_MUTUAL_CONTACT",
                });

                this.setState({
                  DataUserNameInvite: dataHasResultInvite,
                });
              }
            } else if (err.code === undefined) {
              this.setState({
                openValidation: true,
                titleValidation: "Error Code Channel/group is invalid",
                titleBody: "Channel/group not available.",
              });
            } else {
              this.setState({
                openValidation: true,
                titleValidation: "Error Code " + err.code,
                titleBody: err.errorMessage,
              });
            }
          }
        });
    }
  };

  async SendInviteChannel(users) {
    var dataHasResultInvite = this.state.DataUserNameInvite;

    const format1 = "YYYY-MM-DD HH:mm:ss";
    var date = new Date();
    var DateTimeNow = moment(date).format(format1);

    var getApiHash = this.state.dataAccountReal;

    if (getApiHash.length > 0) {
      var dataFlood = getApiHash.filter(
        (obj, i) =>
          obj.api_id === getApiHash[0].api_id &&
          obj.status === 4 &&
          obj.flood_time >= DateTimeNow
      );

      console.log("INI DATA ACCOUNT FLOOD", dataFlood);

      if (dataFlood.length > 0) {
        var dataUnFlood = getApiHash.filter(
          (obj) => obj.api_id !== dataFlood[0].api_id
        );
      } else {
        var dataUnFlood = getApiHash;
      }

      console.log("INI DATA ACCOUNT UNFLOOD", dataUnFlood);

      if (dataUnFlood.length > 0) {
        var getApi = shuffle(dataUnFlood);
        console.log(users);
        console.log(getApi);
        console.log(
          "Account Telegram:",
          getApi[0].alias !== "" ? getApi[0].alias : getApi[0].phonenumber
        );

        let userparams = [];
        userparams.push(users);
        console.log(userparams);
        const apiId = getApi[0].api_id;
        const apiHash = getApi[0].api_hash;
        const session = new StringSession(getApi[0].string_token);
        const client = new TelegramClient(session, apiId, apiHash, {
          connectionRetries: 5,
        });

        if (userparams.length > 0) {
          let params = {
            channel: this.state.selectedGroupJoin.value,
            users: userparams,
          };

          console.log("parameter: ", params);
          await client.session.setDC(5, "flora.web.telegram.org", 443);
          await client.connect();
          await client
            .invoke(new Api.channels.InviteToChannel(params))
            .then((res) => {
              console.log(res);

              dataHasResultInvite.push({
                username: users,
                status: "SUCCESS",
              });

              // client.disconnected();

              this.setState({
                DataUserNameInvite: dataHasResultInvite,
                openSuccess: true,
                openSuccessText: "Invite to channel is successful.",
                // LoadingInvite: false,
              });
            })
            .catch((err) => {
              // client.disconnected();
              console.log(err.code);
              console.log(err.errorMessage);
              this.setState({
                // LoadingInvite: false,
              });

              if (err.code === 403) {
                if (err.errorMessage === "CHAT_WRITE_FORBIDDEN") {
                  this.setState({
                    openValidation: true,
                    titleValidation:
                      "Error Code " + err.code + ": " + err.errorMessage,
                    titleBody: "You can't write in this chat.",
                  });
                } else if (err.errorMessage === "USER_CHANNELS_TOO_MUCH") {
                  dataHasResultInvite.push({
                    username: users,
                    status: "USER_CHANNELS_TOO_MUCH",
                  });
                  this.setState({
                    DataUserNameInvite: dataHasResultInvite,
                  });
                } else if (err.errorMessage === "USER_PRIVACY_RESTRICTED") {
                  dataHasResultInvite.push({
                    username: users,
                    status: "USER_PRIVACY_RESTRICTED",
                  });

                  this.setState({
                    DataUserNameInvite: dataHasResultInvite,
                  });
                }
              } else if (err.code === 400) {
                if (err.errorMessage === "BOTS_TOO_MUCH") {
                  this.setState({
                    openValidation: true,
                    titleValidation:
                      "Error Code " + err.code + ": " + err.errorMessage,
                    titleBody: "There are too many bots in this chat/channel.",
                  });
                } else if (err.errorMessage === "BOT_GROUPS_BLOCKED") {
                  this.setState({
                    openValidation: true,
                    titleValidation:
                      "Error Code " + err.code + ": " + err.errorMessage,
                    titleBody: "This bot can't be added to groups.",
                  });
                } else if (err.errorMessage === "CHANNEL_INVALID") {
                  this.setState({
                    openValidation: true,
                    titleValidation:
                      "Error Code " + err.code + ": " + err.errorMessage,
                    titleBody: "The provided channel is invalid.",
                  });
                } else if (err.errorMessage === "CHANNEL_PRIVATE") {
                  this.setState({
                    openValidation: true,
                    titleValidation:
                      "Error Code " + err.code + ": " + err.errorMessage,
                    titleBody: "You haven't joined this channel/supergroup.",
                  });
                } else if (err.errorMessage === "CHAT_ADMIN_REQUIRED") {
                  this.setState({
                    openValidation: true,
                    titleValidation:
                      "Error Code " + err.code + ": " + err.errorMessage,
                    titleBody: "You must be an admin in this chat to do this.",
                  });
                } else if (err.errorMessage === "CHAT_INVALID") {
                  this.setState({
                    openValidation: true,
                    titleValidation:
                      "Error Code " + err.code + ": " + err.errorMessage,
                    titleBody: "Invalid chat.",
                  });
                } else if (err.errorMessage === "CHAT_WRITE_FORBIDDEN") {
                  this.setState({
                    openValidation: true,
                    titleValidation:
                      "Error Code " + err.code + ": " + err.errorMessage,
                    titleBody: "You can't write in this chat.",
                  });
                } else if (err.errorMessage === "INPUT_USER_DEACTIVATED") {
                  this.setState({
                    openValidation: true,
                    titleValidation:
                      "Error Code " + err.code + ": " + err.errorMessage,
                    titleBody: "The specified user was deleted.",
                  });
                } else if (err.errorMessage === "MSG_ID_INVALID") {
                  this.setState({
                    openValidation: true,
                    titleValidation:
                      "Error Code " + err.code + ": " + err.errorMessage,
                    titleBody: "Invalid message ID provided.",
                  });
                } else if (err.errorMessage === "USERS_TOO_MUCH") {
                  this.setState({
                    openValidation: true,
                    titleValidation:
                      "Error Code " + err.code + ": " + err.errorMessage,
                    titleBody:
                      "The maximum number of users has been exceeded (to create a chat, for example).",
                  });
                } else if (err.errorMessage === "USER_BANNED_IN_CHANNEL") {
                  this.setState({
                    openValidation: true,
                    titleValidation:
                      "Error Code " + err.code + ": " + err.errorMessage,
                    titleBody:
                      "You're banned from sending messages in supergroups/channels.",
                  });
                } else if (err.errorMessage === "USER_BLOCKED") {
                  this.setState({
                    openValidation: true,
                    titleValidation:
                      "Error Code " + err.code + ": " + err.errorMessage,
                    titleBody: "User blocked.",
                  });
                } else if (err.errorMessage === "USER_BOT") {
                  this.setState({
                    openValidation: true,
                    titleValidation:
                      "Error Code " + err.code + ": " + err.errorMessage,
                    titleBody: "Bots can only be admins in channels.",
                  });
                } else if (err.errorMessage === "USER_ID_INVALID") {
                  this.setState({
                    openValidation: true,
                    titleValidation:
                      "Error Code " + err.code + ": " + err.errorMessage,
                    titleBody: "The provided user ID is invalid.",
                  });
                } else if (err.errorMessage === "USER_KICKED") {
                  this.setState({
                    openValidation: true,
                    titleValidation:
                      "Error Code " + err.code + ": " + err.errorMessage,
                    titleBody:
                      "This user was kicked from this supergroup/channel.",
                  });
                } else if (err.errorMessage === "USER_NOT_MUTUAL_CONTACT") {
                  dataHasResultInvite.push({
                    username: users,
                    status: "USER_NOT_MUTUAL_CONTACT",
                  });

                  this.setState({
                    DataUserNameInvite: dataHasResultInvite,
                  });
                }
              } else if (err.code === 401) {
                if (err.errorMessage === "USER_DEACTIVATED_BAN") {
                  this.doUpdateBanned(getApi[0].manage_id);

                  console.log("INI AKUN KENA BANNED", getApi[0]);

                  let accountTelegram = getApiHash.filter(
                    (obj) => obj.api_id !== getApi[0].api_id
                  );
                  this.setState({
                    dataAccountReal: accountTelegram,
                  });
                  this.setState({
                    openValidation: true,
                    titleValidation: "Error Code " + err.code,
                    titleBody:
                      err.errorMessage + " " + getApi[0].alias !== ""
                        ? getApi[0].alias
                        : getApi[0].phonenumber,
                  });
                }
              } else if (err.code === 420) {
                if (err.errorMessage === "FLOOD") {
                  // let params = {
                  //   manage_id: getApi[0].manage_id,
                  //   flood_time: 60000,
                  // };
                  // this.doUpdateFlood(params);

                  let changeObjArr = getApiHash.find(
                    (obj) =>
                      obj.manage_id === getApi[0].manage_id &&
                      ((obj.status = 4), true)
                  );

                  console.log("INI AKUN KENA FLOOD", changeObjArr);

                  let newDataAcct = getApiHash.map((obj, i) => ({
                    ...obj,
                    manage_id: changeObjArr[i],
                  }));

                  const format1 = "YYYY-MM-DD HH:mm:ss";
                  var date = new Date();
                  var DateTimeNow = moment(date).format(format1);
                  // console.log(DateTimeNow);

                  let dt = new Date();
                  dt.setSeconds(dt.getSeconds() + 3600);
                  let DateAddNow = moment(dt).format(format1);
                  // console.log(DateAddNow);

                  for (var i = 0; i < newDataAcct.length; i++) {
                    if (newDataAcct[i].manage_id === getApi[0].manage_id) {
                      newDataAcct[i].flood_time = DateAddNow;
                    } else {
                      newDataAcct[i].flood_time = "";
                    }
                  }

                  // let accountTelegram = getApiHash.filter(
                  //   (obj) => obj.api_id !== getApi[0].api_id
                  // );

                  this.setState({
                    dataAccountReal: newDataAcct,
                  });
                  this.setState({
                    openValidation: true,
                    titleValidation: "Error Code " + err.code,
                    titleBody:
                      err.errorMessage + " " + getApi[0].alias !== ""
                        ? getApi[0].alias
                        : getApi[0].phonenumber,
                  });
                }
              } else {
                if (err.errorMessage === "PEER_FLOOD") {
                  // let params = {
                  //   manage_id: getApi[0].manage_id,
                  //   flood_time: 60000,
                  // };
                  // this.doUpdateFlood(params);

                  let changeObjArr = getApiHash.find(
                    (obj) =>
                      obj.manage_id === getApi[0].manage_id &&
                      ((obj.status = 4), true)
                  );

                  console.log("INI AKUN KENA PEER_FLOOD", changeObjArr);

                  let newDataAcct = getApiHash.map((obj, i) => ({
                    ...obj,
                    manage_id: changeObjArr[i],
                  }));

                  const format1 = "YYYY-MM-DD HH:mm:ss";
                  var date = new Date();
                  var DateTimeNow = moment(date).format(format1);
                  // console.log(DateTimeNow);

                  let dt = new Date();
                  dt.setSeconds(dt.getSeconds() + 3600);
                  let DateAddNow = moment(dt).format(format1);
                  // console.log(DateAddNow);

                  for (var i = 0; i < newDataAcct.length; i++) {
                    if (newDataAcct[i].manage_id === getApi[0].manage_id) {
                      newDataAcct[i].flood_time = DateAddNow;
                    } else {
                      newDataAcct[i].flood_time = "";
                    }
                  }

                  // let accountTelegram = getApiHash.filter(
                  //   (obj) => obj.api_id !== getApi[0].api_id
                  // );

                  this.setState({
                    dataAccountReal: newDataAcct,
                  });
                } else {
                  let accountTelegram = getApiHash.filter(
                    (obj) => obj.api_id !== getApi[0].api_id
                  );
                  this.setState({
                    dataAccountReal: accountTelegram,
                  });
                }
                this.setState({
                  openValidation: true,
                  titleValidation: "Error Code " + err.code,
                  titleBody:
                    err.errorMessage + getApi[0].alias !== ""
                      ? getApi[0].alias
                      : getApi[0].phonenumber,
                });
              }
            });
        } else {
          this.setState({
            // LoadingInvite: false,
          });
          this.setState({
            openFailed: true,
            openFailedText: "Invite to channel fail.",
            // LoadingInvite: false,
          });
        }
      }
    }
  }

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth="xs"
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  renderListParticipants = () => {
    if (this.state.AllContact.length > 0) {
      return (
        <List className="list-form">
          <div style={{ marginBottom: 0 }}>
            {this.state.AllContact.map((dt, i) => {
              return (
                <div>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            style={
                              (stylesListComent.inline,
                              { fontSize: 15, color: "#000", fontWeight: 800 })
                            }
                          >
                            {dt.first_name} {dt.last_name}
                          </Typography>
                          <br></br>
                          {dt.username !== "" ? (
                            <Typography
                              component="span"
                              variant="body2"
                              style={
                                (stylesListComent.inline,
                                { fontSize: 15, color: "#000" })
                              }
                            >
                              @{dt.username}
                            </Typography>
                          ) : (
                            <Typography
                              component="span"
                              variant="body2"
                              style={
                                (stylesListComent.inline,
                                { fontSize: 15, color: "#000" })
                              }
                            >
                              {dt.phone}
                            </Typography>
                          )}
                        </React.Fragment>
                      }
                    />
                    <div style={{ alignSelf: "center", textAlign: "center" }}>
                      <ListItemSecondaryAction>
                        <Checkbox
                          style={{
                            width: 50,
                            height: 50,
                            color: "#07213c",
                          }}
                          edge="end"
                          onChange={(e) => this.handleCheck(e, i)}
                          checked={dt.isChecked}
                          inputProps={{
                            "aria-labelledby": "checkbox-list-secondary-label",
                          }}
                        />
                      </ListItemSecondaryAction>
                    </div>
                  </ListItem>
                  <Divider />
                </div>
              );
            })}
          </div>
        </List>
      );
    } else {
      return (
        <List className="list-form">
          <div style={{ marginBottom: 0, padding: 20, textAlign: "center" }}>
            <Typography
              component="span"
              variant="body2"
              style={(stylesListComent.inline, { fontSize: 20, color: "#000" })}
            >
              Contact not available
            </Typography>
          </div>
        </List>
      );
    }
  };

  handleCloseValidationAlert = () => {
    this.setState({
      openValidation: false,
      titleValidation: "",
      titleBody: "",
    });
  };

  renderValidationAlert = () => {
    return (
      <Dialog
        open={this.state.openValidation}
        onClose={this.handleCloseValidationAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={Transition}
        fullWidth="xs"
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="label-title-alert">{this.state.titleValidation}</div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            <div className="title-body-alert"> {this.state.titleBody} </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            style={{ backgroundColor: "#f2f2f2" }}
            onClick={this.handleCloseValidationAlert}
          >
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    );
  };

  closeStackSuccess = () => {
    this.setState({
      openSuccess: false,
      openSuccessText: "",
    });
  };

  pressReset = () => {
    this.setState({
      filter: "",
      channel_group: "",
      openValidation: false,
      titleValidation: "",
      titleBody: "",
      showLoading: false,
      showTextGetParticipants: false,
      selectedGroupId: null,
      selectAll: false,
      openSuccess: false,
      openSuccessText: "",
      openFailed: false,
      openFailedText: "",
    });
  };

  renderSuccess = () => {
    if (this.state.openSuccess === true) {
      setTimeout(() => this.pressReset(), 2000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  // onClick={() => this.reset()}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openSuccessText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  renderFailed = () => {
    if (this.state.openFailed === true) {
      setTimeout(() => this.pressReset(), 2000);

      return (
        <div style={{ margin: 10 }}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert
              variant="filled"
              severity="error"
              action={
                <IconButton aria-label="close" color="inherit" size="small">
                  <Close fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {this.state.openFailedText}
            </Alert>
          </Stack>
        </div>
      );
    }
  };

  handleCloseResult = () => {
    this.setState({
      openResult: false,
    });
  };

  renderDialogResult = () => {
    if (this.state.DataUserNameInvite.length > 0) {
      var data = this.state.DataUserNameInvite;
      for (var i = 0; i < data.length; i++) {
        data[i].id = i + 1;
      }
    }

    return (
      <Dialog
        // onClose={this.handleCloseAddNew}
        aria-labelledby="customized-dialog-title"
        open={this.state.openResult}
        fullWidth="md"
        maxWidth="md"
      >
        <AppBar style={stylesDialog.appBar}>
          <Toolbar>
            <Typography variant="h5" style={stylesDialog.title}>
              Result Invite User
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.handleCloseResult()}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 60, marginTop: 20 }}>
            <div className="box-container">
              <ReactTable
                ref={(r) => (this.reactTable = r)}
                data={
                  this.state.DataUserNameInvite.length > 0
                    ? data
                    : this.state.DataUserNameInvite
                }
                columns={[
                  {
                    Header: "No",
                    headerStyle: { fontWeight: "bold" },
                    accessor: "id",
                    style: { textAlign: "center" },
                    width: 100,
                  },
                  {
                    Header: "@Username",
                    headerStyle: { fontWeight: "bold" },
                    accessor: "username",
                    style: { textAlign: "center" },
                  },
                  {
                    Header: "Status",
                    headerStyle: { fontWeight: "bold" },
                    accessor: "status",
                    style: { textAlign: "center" },
                  },
                ]}
                style={{ backgroundColor: "#f2f2f2" }}
                filterable
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id])
                    .toLowerCase()
                    .includes(filter.value.toLowerCase())
                }
                defaultPageSize={5}
              />
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

  render() {
    return (
      <Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper
                style={{
                  borderLeft: 6,
                  borderColor: "#2f55a2",
                  color: "#FFF",
                  maxHeight: 100,
                  padding: 16,
                  paddingBottom: 24,
                }}
              >
                <Typography
                  component="span"
                  variant="h1"
                  style={
                    (stylesListComent.inline,
                    {
                      fontSize: 20,
                      color: "#000",
                      fontWeight: "bold",
                    })
                  }
                >
                  Invite Contacts to group
                </Typography>
                <br></br>
                <br></br>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <br></br>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Grid item xs={12}>
                <Paper
                  style={{
                    padding: "10px",
                    backgroundColor: "#fff",
                    // height: "75vh",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div
                        style={{
                          marginRight: 16,
                          width: "100%",
                          position: "relative",
                          zIndex: 2,
                        }}
                      >
                        <Select
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          classNamePrefix="select"
                          placeholder="Select a group account telegram"
                          value={this.state.selectGroupAccountTelegramId}
                          onChange={this.handleChangeAccount}
                          options={this.state.DataGroupAccountTelegram}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Select
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        classNamePrefix="select"
                        placeholder="Invite contact to group"
                        value={this.state.selectedGroupJoin}
                        onChange={this.handleChangeOptionGroupJoin}
                        options={this.state.GroupJoinShow}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        autoComplete="off"
                        type="number"
                        name="indexing"
                        id="indexing"
                        placeholder="Set Index"
                        innerRef={this.SetIndexText}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {this.state.LoadingInvite === true ? (
                        <Button
                          variant="contained"
                          fullWidth
                          size="large"
                          style={{
                            backgroundColor: "#07213c",
                          }}
                          endIcon={<StopCircle />}
                          onClick={() => this.pressStopInvite()}
                        >
                          <Typography
                            variant="button"
                            style={{
                              color: "#fff",
                              textTransform: "uppercase",
                            }}
                          >
                            Stop
                          </Typography>
                        </Button>
                      ) : (
                        <Button
                          disabled={this.state.LoadingInvite}
                          variant="contained"
                          fullWidth
                          size="large"
                          style={{
                            backgroundColor: "#07213c",
                          }}
                          endIcon={
                            this.state.LoadingInvite === true ? null : (
                              <GroupAdd />
                            )
                          }
                          onClick={() => this.pressInviteChannel()}
                        >
                          {this.state.LoadingInvite === true ? (
                            <CircularProgress
                              style={{ color: "#fff" }}
                              size={24}
                            />
                          ) : (
                            <Typography
                              variant="button"
                              style={{
                                color: "#fff",
                                textTransform: "uppercase",
                              }}
                            >
                              Invite
                            </Typography>
                          )}
                        </Button>
                      )}
                    </Grid>
                    <br></br>
                  </Grid>
                </Paper>
                <br></br>
                {this.renderSuccess()}
                {this.renderFailed()}
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid item xs={12}>
                <Paper
                  style={{
                    padding: "10px",
                    backgroundColor: "#fff",
                    // height: "75vh",
                  }}
                >
                  <Typography
                    component="span"
                    variant="h1"
                    style={
                      (stylesListComent.inline,
                      {
                        fontSize: 20,
                        color: "#000",
                        fontWeight: "bold",
                      })
                    }
                  >
                    All Contact
                  </Typography>
                  <br></br>
                  <div className="contentDate">
                    <div style={{ marginRight: 0, width: 250 }}>
                      <Select
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        classNamePrefix="select"
                        placeholder="Select a group contact"
                        value={this.state.selectedGroupId}
                        onChange={this.handleChangeOptionGroup}
                        options={this.state.groupShow}
                      />
                    </div>
                  </div>
                  <br></br>
                  <br></br>
                </Paper>
              </Grid>
              <br></br>
              <div className="box-container">
                <ReactTable
                  ref={(r) => (this.reactTable = r)}
                  data={this.state.AllContact}
                  columns={[
                    {
                      Header: (
                        <Checkbox
                          style={{
                            width: 30,
                            height: 30,
                            color: "#3a6cf6",
                          }}
                          onChange={(e) => this.toggleSelectAll(e)}
                          checked={this.state.selectAll}
                          inputProps={{
                            "aria-labelledby": "checkbox-list-secondary-label",
                          }}
                        />
                      ),
                      Cell: (row) => (
                        <Checkbox
                          style={{
                            width: 30,
                            height: 30,
                            color: "#3a6cf6",
                          }}
                          defaultChecked={this.state.checked[row.index]}
                          checked={this.state.checked[row.index]}
                          onChange={(e) => this.handleCheck(e, row.index)}
                          inputProps={{
                            "aria-labelledby": "checkbox-list-secondary-label",
                          }}
                        />
                      ),
                      headerStyle: { fontWeight: "bold" },
                      sortable: false,
                      filterable: true,
                      style: { textAlign: "center" },
                      width: 100,
                    },
                    {
                      Header: "No",
                      headerStyle: { fontWeight: "bold" },
                      accessor: "id",
                      style: { textAlign: "center" },
                      width: 100,
                    },
                    {
                      Header: "Name",
                      headerStyle: { fontWeight: "bold" },
                      accessor: "full_name",
                      style: { textAlign: "left" },
                    },
                    {
                      Header: "@Username",
                      headerStyle: { fontWeight: "bold" },
                      accessor: "username",
                      style: { textAlign: "center" },
                    },
                    {
                      Header: "Phone Number",
                      headerStyle: { fontWeight: "bold" },
                      accessor: "phone",
                      style: { textAlign: "center" },
                    },
                  ]}
                  style={{ backgroundColor: "#f2f2f2" }}
                  filterable
                  defaultFilterMethod={(filter, row) =>
                    String(row[filter.id])
                      .toLowerCase()
                      .includes(filter.value.toLowerCase())
                  }
                  defaultPageSize={5}
                />
              </div>
            </Grid>
          </Grid>
        </Box>
        {this.renderValidationAlert()}
        {this.renderDialogResult()}
      </Box>
    );
  }
}
export default InviteChannelPage;

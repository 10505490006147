import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { serverUrl } from "../../../config.js";
import "./Menu.style.css";
import { activeLanguage } from "../../../config";
import { getLanguage } from "../../../languages";
import { Badge } from "reactstrap";
import {
  Dashboard,
  Contacts,
  AccountBox,
  ManageAccounts,
  AccountCircle,
  ContactPage,
  RecentActors,
  PermContactCalendar,
  Groups,
  Message,
  Settings,
  ContactMail,
  Send,
  Telegram,
  SendTimeExtension,
  SupervisedUserCircle,
  GroupAdd,
  GroupWork,
} from "@mui/icons-material";
import { Typography } from "@mui/material";
const stylesListComent = {
  inline: {
    display: "inline",
  },
};

class Menu extends Component {
  constructor(props) {
    super(props);

    this.language = getLanguage(activeLanguage, "Menu");

    this.state = {
      role: 0,
      menus: [],
      loginInfo: props.loginInfo,
      community: props.community,
      currentParent: 0,
      currentChild: -1,
      count: 0,
    };
    this.selectRole(props.loginInfo);
  }

  componentDidMount = () => {};

  componentWillReceiveProps = (props) => {
    if (props.community !== this.state.community) {
      this.selectRole(props.loginInfo, props.community);
    }
    // this.setState({
    //   count: props.count,
    // });
    this.setState({ loginInfo: props.loginInfo, community: props.community });
  };

  selectRole = (loginInfo, community) => {
    this.setState({ role: loginInfo.logintype });
    this.selectType(loginInfo.logintype, community);
  };

  selectType = (role, community) => {
    if (role === 1) {
      this.menuSuperAdmin(community);
    } else if (role === 2) {
      this.menuSubAdmin(community);
    } else if (role === 3) {
      this.adminMerchant(community);
    }
  };

  toogleSubMenu = (menu, idx) => {
    let menus = this.state.menus;

    menus[idx].openChild = !menus[idx].openChild;
    this.setState({ menus: menus });
  };

  menuSuperAdmin = (community) => {
    let tmpMenu = [];
    tmpMenu.push(
      {
        id: 0,
        label: this.language["dashboard"],
        to: "/panel/dashboard",
        openChild: false,
        childs: [],
      },
      {
        id: 6,
        label: "Telegram",
        to: "",
        openChild: false,
        childs: [
          {
            id: 60,
            label: "Manage API",
            to: "/panel/manage-api",
          },
          {
            id: 61,
            label: "Accounts",
            to: "/panel/Account",
          },
          {
            id: 62,
            label: "Group Accounts",
            to: "/panel/group-accounts",
          },
        ],
      },
      {
        id: 2,
        label: "Contact",
        to: "",
        openChild: false,
        childs: [
          {
            id: 20,
            label: "Group Contact",
            to: "/panel/group-contact",
          },
          {
            id: 22,
            label: "All Contact",
            to: "/panel/all-contact",
          },
        ],
      },
      {
        id: 8,
        label: "Join Group",
        to: "/panel/join-group",
        openChild: false,
        childs: [],
      },
      {
        id: 3,
        label: "Grab Contacts",
        to: "",
        openChild: false,
        childs: [
          {
            id: 30,
            label: "From Group",
            to: "/panel/from-group",
          },
        ],
      },
      {
        id: 4,
        label: "Invite to Group",
        to: "",
        openChild: false,
        childs: [
          {
            id: 41,
            label: "From Contacts",
            to: "/panel/from-contacts",
          },
        ],
      },
      {
        id: 5,
        label: "Send Messages",
        to: "/panel/send-messages",
        openChild: false,
        childs: [
          // {
          //   id: 50,
          //   label: "Personal",
          //   to: "/panel/personal",
          // },
          // {
          //   id: 51,
          //   label: "Bulk",
          //   to: "/panel/Bulk",
          // },
        ],
      },

      {
        id: 7,
        label: "Settings",
        to: "",
        openChild: false,
        childs: [
          {
            id: 70,
            label: "User",
            to: "/panel/admin",
          },
        ],
      }
    );

    this.setState({
      menus: tmpMenu,
    });
  };

  menuSubAdmin = (role) => {
    this.setState({
      menus: [
        {
          id: 0,
          label: this.language["dashboard"],
          to: "/panel/dashboard",
          openChild: false,
          childs: [],
        },
        {
          id: 1,
          label: "Camera",
          to: "",
          openChild: false,
          childs: [
            {
              id: 11,
              label: "Live View",
              to: "/panel/liveView",
            },
            {
              id: 12,
              label: "Playback",
              to: "/panel/playback",
            },
          ],
        },
        {
          id: 2,
          label: "Category",
          to: "",
          openChild: false,
          childs: [],
        },
        {
          id: 2,
          label: "Service",
          to: "",
          openChild: false,
          childs: [],
        },
        {
          id: 3,
          label: "Management",
          to: "",
          openChild: false,
          childs: [],
        },
      ],
    });
  };

  adminMerchant = (role) => {
    this.setState({
      menus: [
        {
          id: 0,
          label: this.language["dashboard"],
          to: "/panel/dashboard",
          openChild: false,
          childs: [],
        },
        {
          id: 1,
          label: "Merchant",
          to: "/panel/merchantAcc",
          openChild: false,
          childs: [],
        },
        {
          id: 2,
          label: "Commodity",
          to: "/panel/commodityAcc",
          openChild: false,
          childs: [],
        },
        {
          id: 3,
          label: "Order List",
          to: "/panel/orderAcc",
          openChild: false,
          childs: [],
        },
        {
          id: 4,
          label: "Payment",
          to: "/panel/paymentAcc",
          openChild: false,
          childs: [],
        },
      ],
    });
  };

  updateParent = (id) => {
    this.setState({ currentParent: id, currentChild: -1 });
  };
  updateChild = (id) => {
    this.setState({ currentChild: id, currentParent: -1 });
  };

  iconBadge = () => {
    if (this.state.count !== 0) {
      return (
        <Badge color="primary" pill>
          {this.state.count}
        </Badge>
      );
    }
  };

  renderforBadge = (submenu) => {
    // console.log(submenu);
    if (submenu.label === "Order List") {
      return (
        <div
          className={`submenu ${
            this.state.currentChild === submenu.id ? "menu-active" : ""
          } `}
        >
          {this.renderSubMenuIcon(submenu.label)}{" "}
          <Typography
            component="span"
            variant="subtitle2"
            style={
              (stylesListComent.inline,
              {
                color: "#fff",
                fontWeight: "bolder",
              })
            }
          >
            {submenu.label}
          </Typography>
          &nbsp;
          {this.iconBadge()}
        </div>
      );
    } else {
      return (
        <div
          className={`submenu ${
            this.state.currentChild === submenu.id ? "menu-active" : ""
          } `}
        >
          {this.renderSubMenuIcon(submenu.label)}{" "}
          <Typography
            component="span"
            variant="subtitle2"
            style={
              (stylesListComent.inline,
              {
                color: "#fff",
                fontWeight: "bolder",
              })
            }
          >
            {submenu.label}
          </Typography>
        </div>
      );
    }
  };

  _renderSubMenu = (menu, idx) => {
    // console.log(menu);
    // this.loadCount();
    if (menu.openChild) {
      if (
        menu.label === "Marketplace" &&
        menu.childs[2].label === "Order List"
      ) {
        return (
          <div className="submenu-container">
            {menu.childs.map((submenu, i) => (
              <Link
                key={submenu.id}
                to={submenu.to}
                onClick={() => this.updateChild(submenu.id)}
                className={`link-custom`}
              >
                {this.renderforBadge(submenu)}
              </Link>
            ))}
          </div>
        );
      }
      return (
        <div className="submenu-container">
          {menu.childs.map((submenu, i) => (
            <Link
              key={submenu.id}
              to={submenu.to}
              onClick={() => this.updateChild(submenu.id)}
              className={`link-custom`}
            >
              <div
                className={`submenu ${
                  this.state.currentChild === submenu.id ? "menu-active" : ""
                } `}
              >
                {this.renderSubMenuIcon(submenu.label)}{" "}
                <Typography
                  component="span"
                  variant="subtitle2"
                  style={
                    (stylesListComent.inline,
                    {
                      color: "#fff",
                      fontWeight: "bolder",
                    })
                  }
                >
                  {submenu.label}
                </Typography>
              </div>
            </Link>
          ))}
        </div>
      );
    } else {
      return "";
    }
  };

  _renderChevronIcon = (menu) => {
    if (menu.openChild) {
      return <FontAwesomeIcon icon="chevron-down" />;
    } else {
      return <FontAwesomeIcon icon="chevron-left" />;
    }
  };

  _renderMenu = (menu, i) => {
    // console.log(menu);

    if (menu.to === "") {
      if (menu.label === "Marketplace") {
        return (
          <div key={menu.id}>
            <div
              className="menu menu-parent"
              onClick={() => this.toogleSubMenu(menu, i)}
            >
              {this.renderIconMenu(menu.label)}{" "}
              <Typography
                component="span"
                variant="subtitle2"
                style={
                  (stylesListComent.inline,
                  {
                    color: "#fff",
                    fontWeight: "bolder",
                  })
                }
              >
                {menu.label}
              </Typography>
              <div className="chevron-container">
                {this._renderChevronIcon(menu)}
              </div>
            </div>
            {this._renderSubMenu(menu, i)}
          </div>
        );
      }
      return (
        <div key={menu.id}>
          <div
            className="menu menu-parent"
            onClick={() => this.toogleSubMenu(menu, i)}
          >
            {this.renderIconMenu(menu.label)}{" "}
            <Typography
              component="span"
              variant="subtitle2"
              style={
                (stylesListComent.inline,
                {
                  color: "#fff",
                  fontWeight: "bolder",
                })
              }
            >
              {menu.label}
            </Typography>
            <div className="chevron-container">
              {this._renderChevronIcon(menu)}
            </div>
          </div>
          {this._renderSubMenu(menu, i)}
        </div>
      );
    } else {
      return (
        <Link
          key={menu.id}
          to={menu.to}
          onClick={() => this.updateParent(menu.id)}
          className={`link-custom`}
        >
          <div
            className={`menu ${
              this.state.currentParent === menu.id ? "menu-active" : ""
            } `}
          >
            {this.renderIconMenu(menu.label)}{" "}
            <Typography
              component="span"
              variant="subtitle2"
              style={
                (stylesListComent.inline,
                {
                  color: "#fff",
                  fontWeight: "bolder",
                })
              }
            >
              {menu.label}
            </Typography>{" "}
            <div className="chevron-container"></div>
          </div>
        </Link>
      );
    }
  };

  renderIconMenu = (label) => {
    if (label === "Dashboard") {
      return <Dashboard />;
    } else if (label === "Telegram") {
      return <Telegram />;
    } else if (label === "Contact") {
      return <Contacts />;
    } else if (label === "Grab Contacts") {
      return <ContactPage />;
    } else if (label === "Invite to Group") {
      return <AccountCircle />;
    } else if (label === "Send Messages") {
      return <Message />;
    } else if (label === "Settings") {
      return <Settings />;
    } else if (label === "Join Group") {
      return <GroupAdd />;
    }
  };

  renderSubMenuIcon = (label) => {
    if (label === "Manage API") {
      return <ManageAccounts />;
    } else if (label === "Accounts") {
      return <AccountBox />;
    } else if (label === "Group Contact") {
      return <RecentActors />;
    } else if (label === "All Contact") {
      return <PermContactCalendar />;
    } else if (label === "From Group") {
      return <Groups />;
    } else if (label === "From Contacts") {
      return <ContactMail />;
    } else if (label === "Personal") {
      return <Send />;
    } else if (label === "Bulk") {
      return <SendTimeExtension />;
    } else if (label === "User") {
      return <SupervisedUserCircle />;
    } else if (label === "Join Group") {
      return <GroupAdd />;
    } else if (label === "Group Accounts") {
      return <GroupWork />;
    }
  };

  render() {
    return (
      <div className="menu-container">
        {this.state.menus.map((menu, i) => this._renderMenu(menu, i))}
      </div>
    );
  }
}
export default Menu;

import React, { Component } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import createBrowserHistory from "history/createBrowserHistory";
import axios from "axios";
import { serverUrl } from "../config";
import "./Default.style.css";
import Header from "./Components/Header/Header";
import Menu from "./Components/Menu/Menu";
import MenuProfile from "./Components/MenuProfile/MenuProfile";
import Loading from "./Components/Loading/Loading";
import Dashboard from "./Pages/Dashboard/Dashboard";
import ListAdmin from "./Pages/ListAdmin/ListAdmin";
import EditAdmin from "./Pages/EditAdmin/EditAdmin";
import InputAdmin from "./Pages/InputAdmin/InputAdmin";
import ChangePassword from "./Pages/ChangePassword/ChangePassword";
import GroupListPage from "./Pages/Group/GroupList";
import AccountsPage from "./Pages/Accounts";
import AllContactPage from "./Pages/AllContact";
import GroupContactPage from "./Pages/GroupContact";
import FromGroupPage from "./Pages/FromGroup";
import FromContactsPage from "./Pages/FromContacts";
import SendMessagePersonal from "./Pages/Personal";
import SendMessageBulk from "./Pages/Bulk";
import TelegramAccountPage from "./Pages/Accounts";
import ManageApiPage from "./Pages/ManageApi/Index";
import InviteChannelPage from "./Pages/FromChannel";
import JoinGroupPage from "./Pages/JoinGroup";
import GroupAccountsPage from "./Pages/GroupAccounts";

class Default extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginInfo: {},
      menuProfileShow: false,
      community: {
        communityid: 0,
        communityname: "- SELECT COMMUNITY -",
        isdefault: 1,
      },
      communityList: [],
      loadingShow: false,
      redirect: false,
    };
    this.flag = {
      menuProfileJustOpen: false,
    };
    this.history = createBrowserHistory();

    this.history.listen((location, action) => {
      //event when change page
    });

    this.menuRef = null;
  }

  componentDidMount = () => {
    let loginInfo;

    if (
      this.props.location.state === undefined ||
      this.props.location.state === null
    ) {
      loginInfo = localStorage.getItem("loginInfo");

      if (
        loginInfo === undefined ||
        loginInfo === null ||
        loginInfo === "" ||
        loginInfo === "undefined" ||
        loginInfo === "null"
      ) {
        this.props.history.replace("/");
      } else {
        loginInfo = JSON.parse(loginInfo);
        this.setState({ loginInfo: loginInfo });
      }
    } else {
      loginInfo = this.props.location.state.loginInfo;
      this.setState({ loginInfo: this.props.location.state.loginInfo });
    }
    //set event click in document
    document.addEventListener("click", this.eventDocumentClick);
    if (loginInfo !== undefined) this.getCommunityAvailable(loginInfo.phoneno);
  };

  getCommunityAvailable = (phoneno) => {
    axios({
      method: "post",
      url: serverUrl + "cp_community_list_available_admin.php",
      data: { phoneno: phoneno },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    })
      .then((response) => {
        let data = response.data;
        let defaultCommunity = {
          communityid: 0,
          communityname: "- SELECT COMMUNITY -",
          isdefault: 1,
        };
        let getDefault = false;
        data.records.map((comm, i) => {
          if (comm.isdefault === 1) {
            defaultCommunity = comm;
            getDefault = true;
          }
        });
        if (!getDefault && data.records.length > 0) {
          defaultCommunity = data.records[0];
        }
        this.setState({
          communityList: data.records,
          community: defaultCommunity,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.eventDocumentClick);
  }

  eventDocumentClick = (event) => {
    //check if menu profile is open then close
    if (this.state.menuProfileShow) {
      if (this.flag.menuProfileJustOpen) {
        this.flag.menuProfileJustOpen = false;
      } else {
        this.setState({ menuProfileShow: !this.state.menuProfileShow });
      }
    }
  };

  onLogout = () => {
    localStorage.removeItem("loginInfo");
    this.props.history.replace("/");
  };
  toogleMenuProfile = () => {
    this.flag.menuProfileJustOpen = true;
    this.setState({ menuProfileShow: !this.state.menuProfileShow });
  };

  changeCommunity = (community) => {
    //console.log('change community : '+community);
    this.menuRef.updateParent(0);
    this.setState({ community: community });

    let { match } = this.props;
    //this.history.replace('/smartcp/panel/dashboard');
    this.history.replace(`${match.path}/dashboard`);
  };

  updateCommunity = () => {
    this.getCommunityAvailable();
  };

  doLoading = () => {
    this.setState({ loadingShow: !this.state.loadingShow });
  };

  render() {
    let { match } = this.props;
    // console.log(`${match.path}/dashboard`);
    return (
      <Router history={this.history}>
        <div>
          <Loading isShow={this.state.loadingShow} />
          <Header
            loginInfo={this.state.loginInfo}
            community={this.state.community}
            communityList={this.state.communityList}
            toogleMenuProfile={this.toogleMenuProfile}
            changeCommunity={this.changeCommunity}
          ></Header>
          <Menu
            ref={(ref) => (this.menuRef = ref)}
            loginInfo={this.state.loginInfo}
            community={this.state.community}
          ></Menu>
          <MenuProfile
            isOpen={this.state.menuProfileShow}
            onLogout={this.onLogout}
          ></MenuProfile>
          <div className="content-container">
            <Switch>
              <Route
                path={`/panel/dashboard`}
                render={(props) => (
                  <Dashboard
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />

              <Route
                path={`/panel/changepassword`}
                render={(props) => (
                  <ChangePassword
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />

              <Route
                path={`/panel/admin`}
                render={(props) => (
                  <ListAdmin
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/inputadmin`}
                render={(props) => (
                  <InputAdmin
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/editadmin/:phoneno`}
                render={(props) => (
                  <EditAdmin
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/all-contact`}
                render={(props) => (
                  <AllContactPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/group-contact`}
                render={(props) => (
                  <GroupContactPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/group-accounts`}
                render={(props) => (
                  <GroupAccountsPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/from-group`}
                render={(props) => (
                  <FromGroupPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              {/* <Route
                path={`telegram-mkt-tools/from-contacts`}
                render={(props) => (
                  <FromContactsPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              /> */}
              <Route
                path={`/panel/from-contacts`}
                render={(props) => (
                  <InviteChannelPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/personal`}
                render={(props) => (
                  <SendMessagePersonal
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/send-messages`}
                render={(props) => (
                  <SendMessageBulk
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/Account`}
                render={(props) => (
                  <TelegramAccountPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/manage-api`}
                render={(props) => (
                  <ManageApiPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
              <Route
                path={`/panel/join-group`}
                render={(props) => (
                  <JoinGroupPage
                    {...props}
                    doLoading={this.doLoading}
                    community={this.state.community}
                  />
                )}
              />
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}

export default Default;

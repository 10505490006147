let Languages = {
  eng: {
    global: {
      add: "Add New",
      edit: "Edit",
      view: "Detail",
      delete: "Delete",
      search: "Search",
      submit: "Submit",
      cancel: "Cancel",
      uploadpicture: "Upload Picture",
      gallery: "Gallery",
      banner1: "Upload Banner Top",
      banner2: "Upload Banner Bottom",
      show: "Show",
      hidden: "Hidden",
      ready: "Ready",
      working: "Working On",
      yes: "Yes",
      no: "No",
      pending: "PENDING",
      paid: "PAID",
      process: "PROCESS",
      done: "DONE",
      cancelled: "Cancelled",
      pay: "Pay",
      delivery: "ON DELIVERY",
      delivered: "DELIVERED",
      booked: "Already Booked",
      noorder: "Not Yet Ordered",
      reset: "Reset",
      export: "Export",
      searchbydate: "Filter",
    },
    header: {
      title: "Smart Community Control Panel",
    },
    selectmulticolumn: {
      placeholder: "- SELECT -",
    },
    Menu: {
      master: "Master",
      dashboard: "Dashboard",
      slide: "Slide Promotion",
      parkinformation: "Park Information",
      parkintroduction: "Park Introduction",
      servicephilosophy: "Service Philosophy",
      parkpositioning: "Park Positioning",
      parkenterprise: "Park Enterprise",
      companyintroduction: "Company Introduction",
      businesscase: "Business Case",
      corporatecontact: "Corporate Contact",
      news: "News",
      newscategory: "News Category",
      category: "Category",
      community: "Community",
      company: "Company",
      admin: "Admin",
      user: "User",
      teknisi: "Technician Service",
      tag: "Tag",
      topslide: "Top Slide",
      bottomslide: "Bottom Slide",
      companycategory: "Company Category",
      product: "Product",
      productcategory: "Product Category",
      service: "Service",
      servicecategory: "Service Category",
      project: "Project",
      projectcategory: "Project Category",
      investment: "Investment",
      investmentcategory: "Investment Category",
      talent: "Talent",
      talentcategory: "Talent Category",
      example: "Example",
      download: "Download",
      downloadcategory: "Download Category",
      merchant: "Merchant",
      merchantcategory: "Merchant Category",
      commodity: "Commodity",
      commoditycategory: "Commodity Category",
      marketplaceevent: "Marketplace Event",
      marketplaceadvertisement: "Advertisement",
      onlinestorecategory: "Online Store Category",
      onlinestore: "Online Store",
      moments: "Moments",
      billing: "Billing",
      billingdebtor: "Billing IPKL",
      billingcategory: "Billing Category",
      servicecenter: "Service Center",
      servicecentercategory: "Service Center Category",
      homeconfig: "Home Config",
      entertainment: "Entertainment",
      userservice: "User Service Order",
      roomcategory: "Room Category",
      room: "Room Bookings",
      roomreservation: "Room Reservation",
      activitycategory: "Activity Category",
      activity: "Activity Bookings",
      activityreservation: "Activity Reservation",
      wedding: "Wedding Bookings",
      weddingreservation: "Wedding Reservation",
      nonwedding: "Facilities Bookings",
      nonweddingreservation: "Facilities Reservation",
      order: "Order",
      uservehicle: "User Vehicle",
      parking: "Parking",
      forweddingcategory: "Facilities Category",
      nonweddingcategory: "Non Wedding Category",
      uservisit: "User Visit",
      callcenter: "Emergency Call",
      directory: "Directory",
      directorycategory: "Directory Category",
      voucher: "Voucher",
      foodgarden: "Food Garden",
      newsactivities: "News Activities",
      ordermanagement: "Order Management",
      servicemanagement: "Service Management",
      marketplace: "Marketplace",
      basicmanagement: "Basic Management",
      payment: "Payment Management",
      foodorder: "Food Order",
      infocovid19: "Informasi Covid 19",
      newscovid: "News Covid-19",
      listcontactpanic: "Emergency Call",
      complaintform: "Complaint Covid-19",
      healthdeclaration: "Contact Tracing",
      dailydeclaration: "Daily Declaration",
      returntrip: "Return Trip",
      springfestival: "Return Mudik",
      callcovidcenter: "Call Center",
      bannermanagement: "Banner Management",
      bannerhome: "Home Banner",
      wfhmanagement: "WFH Management",
      wfh: "WFH (Work From Home)",
    },
    /**
     * Components
     */
    MenuProfile: {
      profile: "Profile",
      changepassword: "Change Password",
      logout: "Logout",
    },
    PictureUploader: {
      placeholder: 'Drag your file here or click "Choose file" button',
      choosefile: "Choose File",
      maxfile: "Max File",
      pictures: "Picture(s)",
      reachlimit: "Already reach limit file",
    },
    FileUploader: {
      placeholder: 'Drag your file here or click "Choose file" button',
      choosefile: "Choose File",
      maxfile: "Max file",
      file: "File(s)",
      reachlimit: "Already reach limit file",
    },
    /**
     * Pages
     */
    Login: {
      title: "Smart Community",
      phoneno: "Phone No",
      password: "Password",
      login: "LOGIN",
      alertphoneno: "Please input your phone no",
      alertpassword: "Please input your password",
    },
    changepassword: {
      title: "Change Password",
      oldpassword: "Old Password",
      newpassword: "New Password",
      confirmpassword: "Confirm New Password",
      emptyold: "Please input old password",
      emptynew: "Please input new password",
      invalidconfirm: "Confirm password is not same with new password",
      success: "Change Password Success",
      failed: "Change Password Failed",
    },
    dashboard: {
      title: "Dashboard",
    },
    example: {
      title: "example",
    },
    parkintroduction: {
      title: "Park Introduction",
      fieldtitle: "Title",
      fielddescription: "Description",
      fieldcommunity: "Community",
    },
    parkpositioning: {
      title: "Park Positioning",
      fieldtitle: "Title",
      fielddescription: "Description",
      fieldcommunity: "Community",
    },
    servicephilosophy: {
      title: "Service Philosophy",
      fieldtitle: "Title",
      fielddescription: "Description",
      fieldcommunity: "Community",
    },
    companyintroduction: {
      title: "Company Introduction",
      fieldtitle: "Title",
      fielddescription: "Description",
      fieldcommunity: "Community",
    },
    businesscase: {
      title: "Business Case",
      columntitle: "Title",
      columndescription: "Description",
      columncommunity: "Community",
      columnaction: "Action",
      confirmdelete: "Delete business case?",
      deletesuccess: "Delete successful",
    },
    inputbusinesscase: {
      title: "Business Case",
      fieldtitle: "Title",
      fielddescription: "Description",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editbusinesscase: {
      title: "Business Case",
      fieldtitle: "Title",
      fielddescription: "Description",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    corporatecontact: {
      title: "Corporate Contact",
      columnname: "Name",
      columnphone: "Phone",
      columncommunity: "Community",
      columnposition: "Position",
      columnaction: "Action",
      confirmdelete: "Delete corporate contact?",
      deletesuccess: "Delete successful",
    },
    inputcorporatecontact: {
      title: "Corporate Contact",
      fieldname: "Name",
      fieldphone: "Phone",
      fieldcommunity: "Community",
      fieldposition: "Position",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editcorporatecontact: {
      title: "Corporate Contact",
      fieldname: "Name",
      fieldphone: "Phone",
      fieldcommunity: "Community",
      fieldposition: "Position",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listcommunity: {
      title: "Community",
      columnname: "Community Name",
      columndefault: "Is Default",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete community?",
      deletesuccess: "Delete successful",
    },
    inputcommunity: {
      title: "Community",
      fieldname: "Community Name",
      fielddefault: "Is Default",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editcommunity: {
      title: "Community",
      fieldname: "Community Name",
      fielddefault: "Is Default",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listcompany: {
      title: "Company",
      columnname: "Company Name",
      columncategory: "Company Category",
      columnshortdesc: "Short Desc",
      columncommunity: "Community",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete company?",
      deletesuccess: "Delete successful",
      upload: "Upload",
      savesuccess: "Save successful",
      modaltitle: "Upload File",
      modalfieldname: "File",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
      validation: "Please upload a file",
    },
    inputcompany: {
      title: "Company",
      fieldname: "Company Name",
      fieldcategory: "Company Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldabout: "About",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Company Category",
      modalfieldname: "Company Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    editcompany: {
      title: "Company",
      fieldname: "Company Name",
      fieldcategory: "Company Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldabout: "About",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Company Category",
      modalfieldname: "Company Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    listcompanycategory: {
      title: "Company Category",
      columnname: "Company Category Name",
      columnaction: "Action",
      confirmdelete: "Delete company category?",
      deletesuccess: "Delete successful",
    },
    inputcompanycategory: {
      title: "Company Category",
      fieldname: "Company Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editcompanycategory: {
      title: "Company Category",
      fieldname: "Company Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listproductcategory: {
      title: "Product Category",
      columnname: "Product Category Name",
      columnaction: "Action",
      confirmdelete: "Delete product category?",
      deletesuccess: "Delete successful",
    },
    inputproductcategory: {
      title: "Product Category",
      fieldname: "Product Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editproductcategory: {
      title: "Product Category",
      fieldname: "Product Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listproduct: {
      title: "Product",
      columnname: "Product Name",
      columncategory: "Product Category",
      columntags: "Tags",
      columncompany: "Company",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete product?",
      deletesuccess: "Delete successful",
    },
    inputproduct: {
      title: "Product",
      fieldname: "Product Name",
      fieldcategory: "Product Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldcompany: "Company",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Product Category",
      modalfieldname: "Product Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    editproduct: {
      title: "Product",
      fieldname: "Product Name",
      fieldcategory: "Product Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldcompany: "Company",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Product Category",
      modalfieldname: "Product Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    listservicecategory: {
      title: "Service Category",
      columnname: "Service Category Name",
      columnaction: "Action",
      confirmdelete: "Delete service category?",
      deletesuccess: "Delete successful",
    },
    inputservicecategory: {
      title: "Service Category",
      fieldname: "Service Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editservicecategory: {
      title: "Service Category",
      fieldname: "Service Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listservice: {
      title: "Service",
      columnname: "Service Name",
      columncategory: "Service Category",
      columntags: "Tags",
      columncompany: "Company",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete service?",
      deletesuccess: "Delete successful",
    },
    inputservice: {
      title: "Service",
      fieldname: "Service Name",
      fieldcategory: "Service Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldcompany: "Company",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Service Category",
      modalfieldname: "Service Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    editservice: {
      title: "Service",
      fieldname: "Service Name",
      fieldcategory: "Service Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldcompany: "Company",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Service Category",
      modalfieldname: "Service Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    listtag: {
      title: "Tag",
      columnname: "Tag Name",
      columnaction: "Action",
      confirmdelete: "Delete tag?",
      deletesuccess: "Delete successful",
    },
    inputtag: {
      title: "Tag",
      fieldname: "Tag Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    edittag: {
      title: "Tag",
      fieldname: "Tag Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listadmin: {
      title: "Admin",
      columnphone: "Phone",
      columnname: "Name",
      columntype: "Type",
      columnemail: "Email",
      columnlastlogin: "Last Login",
      columnsuspend: "Is Suspend",
      columnaction: "Action",
      confirmdelete: "Delete admin?",
      deletesuccess: "Delete successful",
    },
    inputadmin: {
      title: "Admin",
      fieldphone: "Phone",
      fieldname: "Name",
      fieldtype: "Type",
      fieldemail: "Email",
      fieldpassword: "Password",
      fieldconfirmpass: "Confirm Password",
      fieldaccesscommunity: "Access Community",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      invalidpass: "Invalid confirmation password",
    },
    editadmin: {
      title: "Admin",
      fieldphone: "Phone",
      fieldname: "Name",
      fieldtype: "Type",
      fieldemail: "Email",
      fieldaccesscommunity: "Access Community",
      fieldsuspend: "Is Suspend",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listuser: {
      title: "User",
      columnphone: "Phone",
      columnname: "Name",
      columnusertype: "User Type",
      columngender: "Gender",
      columncompany: "Company",
      columnlocation: "Location",
      columnemail: "Email",
      columnlastip: " IP",
      columnlastlogin: "Last Login",
      columnsuspend: "Is Suspend",
      columnaction: "Action",
      confirmdelete: "Delete user?",
      deletesuccess: "Delete successful",
    },
    inputuser: {
      title: "Input User",
      fieldphone: "Phone",
      fieldname: "Name",
      fieldnickname: "Nickname",
      fieldusertype: "User Type",
      fieldgender: "Gender",
      fielddob: "Date of Birth",
      fieldqrcode: "Business QR Code",
      fieldcompany: "Company",
      fieldlocation: "Location",
      fieldemail: "Email",
      fieldpassword: "Password",
      fieldconfirmpass: "Confirm Password",
      fieldcolleague: "Only collagues that can see real information",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      invalidpass: "Invalid confirmation password",
      fieldcustomerid: "Customer ID",
      fieldlabel: "Address Label",
      fieldaddress: "Address",
      fieldcommunity: "Community",
      modaltitle: "User Detail",
      modalcancel: "Cancel",
      modalsubmit: "Add New",
      columnphoneno: "Phone Number",
      columncustomerid: "Customer ID",
      columnlabel: "Address Label",
      columnaddress: "Address",
      columnuserdetailtype: "User Type",
      columncommunity: "Community",
      columnaction: "Action",
      confirmdelete: "Delete user detail?",
      modaledittitle: "Edit User Detail",
      modaledit: "Edit",
    },
    edituser: {
      title: "Edit User",
      fieldphone: "Phone",
      fieldname: "Name",
      fieldnickname: "Nickname",
      fieldusertype: "User Type",
      fieldgender: "Gender",
      fielddob: "Date of Birth",
      fieldqrcode: "Business QR Code",
      fieldcompany: "Company",
      fieldlocation: "Location",
      fieldemail: "Email",
      fieldpassword: "Password",
      fieldconfirmpass: "Confirm Password",
      fieldcolleague: "Only collagues that can see real information",
      fieldsuspend: "Is Suspend",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      fieldcustomerid: "Customer ID",
      fieldlabel: "Address Label",
      fieldaddress: "Address",
      fieldcommunity: "Community",
      modaltitle: "User Detail",
      modalcancel: "Cancel",
      modalsubmit: "Add New",
      columnphoneno: "Phone Number",
      columncustomerid: "Customer ID",
      columnlabel: "Address Label",
      columnaddress: "Address",
      columnuserdetailtype: "User Type",
      columncommunity: "Community",
      columnaction: "Action",
      confirmdelete: "Delete user detail?",
      modaledittitle: "Edit User Detail",
      modaledit: "Edit",
      fieldplate: "Plate No",
      fieldtype: "Vehicle Type",
      columnplateno: "Plate No",
      columnvehicletype: "Vehicle Type",
      confirmdeleteuservehicle: "Delete this user vehicle?",
      deletesuccess: "Delete successful",
      modaluservehicletitle: "Edit User Vehicle",
    },
    listteknisi: {
      title: "Teknisi",
      columnphone: "Phone",
      columnname: "Name",
      columnusertype: "User Type",
      columngender: "Gender",
      columncompany: "Company",
      columnlocation: "Location",
      columnemail: "Email",
      columnposition: " Position",
      columnlastlogin: "Last Login",
      columnavailable: "Is Available",
      columnsuspend: "Is Suspend",
      columnstatus: "Is Status",
      columnaction: "Action",
      confirmdelete: "Delete Teknisi?",
      deletesuccess: "Delete successful",
    },
    inputteknisi: {
      title: "Input Teknisi",
      fieldphone: "Phone",
      fieldname: "Name",
      fieldnickname: "Nickname",
      fieldusertype: "User Type",
      fieldgender: "Gender",
      fieldstatus: "Status",
      fielddob: "Date of Birth",
      fieldqrcode: "Business QR Code",
      fieldcompany: "Company",
      fieldlocation: "Location",
      fieldemail: "Email",
      fieldpassword: "Password",
      fieldconfirmpass: "Confirm Password",
      fieldcolleague: "Only collagues that can see real information",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      invalidpass: "Invalid confirmation password",
      fieldcustomerid: "Customer ID",
      fieldlabel: "Address Label",
      fieldaddress: "Address",
      fieldcommunity: "Community",
      modaltitle: "User Detail",
      modalcancel: "Cancel",
      modalsubmit: "Add New",
      columnphoneno: "Phone Number",
      columncustomerid: "Customer ID",
      columnlabel: "Address Label",
      columnaddress: "Address",
      columnuserdetailtype: "User Type",
      columncommunity: "Community",
      columnaction: "Action",
      confirmdelete: "Delete user detail?",
      modaledittitle: "Edit User Detail",
      modaledit: "Edit",
    },
    editteknisi: {
      title: "Edit User",
      fieldphone: "Phone",
      fieldname: "Name",
      fieldnickname: "Nickname",
      fieldusertype: "User Type",
      fieldgender: "Gender",
      fielddob: "Date of Birth",
      fieldqrcode: "Business QR Code",
      fieldcompany: "Company",
      fieldstatus: "Status",
      fieldlocation: "Location",
      fieldavailable: "Available",
      fieldemail: "Email",
      fieldpassword: "Password",
      fieldconfirmpass: "Confirm Password",
      fieldcolleague: "Only collagues that can see real information",
      fieldsuspend: "Is Suspend",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      fieldcustomerid: "Customer ID",
      fieldlabel: "Address Label",
      fieldaddress: "Address",
      fieldcommunity: "Community",
      modaltitle: "User Detail",
      modalcancel: "Cancel",
      modalsubmit: "Add New",
      columnphoneno: "Phone Number",
      columncustomerid: "Customer ID",
      columnlabel: "Address Label",
      columnaddress: "Address",
      columnuserdetailtype: "User Type",
      columncommunity: "Community",
      columnaction: "Action",
      confirmdelete: "Delete user detail?",
      modaledittitle: "Edit User Detail",
      modaledit: "Edit",
      fieldplate: "Plate No",
      fieldtype: "Vehicle Type",
      columnplateno: "Plate No",
      columnvehicletype: "Vehicle Type",
      confirmdeleteuservehicle: "Delete this user vehicle?",
      deletesuccess: "Delete successful",
      modaluservehicletitle: "Edit User Vehicle",
    },
    listnewscategory: {
      title: "News Category",
      columnname: "News Category Name",
      columnaction: "Action",
      confirmdelete: "Delete news category?",
      deletesuccess: "Delete successful",
    },
    inputnewscategory: {
      title: "News Category",
      fieldname: "News Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editnewscategory: {
      title: "News Category",
      fieldname: "News Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listnews: {
      title: "News Management",
      messagenumber: "News Number",
      columntitle: "Title",
      columncommunity: "Community",
      columnavailable: "Status",
      columnaction: "Action",
      confirmdelete: "Are you sure you want to delete?",
      deletesuccess: "Delete success",
      newscategory: "News category",
      releasetime: "Release date",
      publisher: "Publisher",
      pinned: "Pinned state",
      displaystate: "Display state",
      reading: "Reading",
      approvalstatus: "Audit status",
      time: "Time",
    },
    inputnews: {
      title: "News Management",
      fieldtitle: "Title",
      fieldcategory: "News Category",
      fieldshortdesc: "Sort Desc",
      fieldfulldesc: "Full Desc",
      fieldcommunity: "Communityname",
      fieldavailable: "Status Available",
      fieldexpired: "Expired",
      validation: "Please fill in all inputs",
      savesuccess: "Save success",
      typeofinformation: "News Category",
      headline: "Title",
      subtitle: "Sort Desc",
      bigpicture: "Big Picture",
      locatedinthepark: "Community Name",
      associatedcompanies: "Company",
      details: "Full Desc",
      thumbnail: "Thumbnail Img",
      previewmode: "Preview Mode",
      status: "Status",
      stickystatus: "Sticky Status",
    },
    editnews: {
      title: "News Management",
      fieldtitle: "Title",
      fieldcategory: "News Category",
      fieldshortdesc: "Sort Desc",
      fieldfulldesc: "Full Desc",
      fieldcommunity: "Communityname",
      fieldavailable: "Status Available",
      fieldexpired: "Expired",
      validation: "Please fill in all inputs",
      savesuccess: "Save success",
      typeofinformation: "News Category",
      headline: "Title",
      subtitle: "Sort Desc",
      bigpicture: "Big Picture",
      locatedinthepark: "Community Name",
      associatedcompanies: "Company",
      details: "Full Desc",
      thumbnail: "Thumbnail Img",
      previewmode: "Preview Mode",
      status: "Status",
      stickystatus: "Sticky Status",
    },
    listprojectcategory: {
      title: "Project Category",
      columnname: "Project Category Name",
      columnaction: "Action",
      confirmdelete: "Delete project category?",
      deletesuccess: "Delete successful",
    },
    inputprojectcategory: {
      title: "Project Category",
      fieldname: "Project Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editprojectcategory: {
      title: "Project Category",
      fieldname: "Project Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listproject: {
      title: "Project",
      columnname: "Project Name",
      columncategory: "Project Category",
      columntags: "Tags",
      columncompany: "Company",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete project?",
      deletesuccess: "Delete successful",
    },
    inputproject: {
      title: "Project",
      fieldname: "Project Name",
      fieldcategory: "Project Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldabout: "About",
      fieldcompany: "Company",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Project Category",
      modalfieldname: "Project Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    editproject: {
      title: "Project",
      fieldname: "Project Name",
      fieldcategory: "Project Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldabout: "About",
      fieldcompany: "Company",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Project Category",
      modalfieldname: "Project Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    listinvestmentcategory: {
      title: "Investment Category",
      columnname: "Investment Category Name",
      columnaction: "Action",
      confirmdelete: "Delete investment category?",
      deletesuccess: "Delete successful",
    },
    inputinvestmentcategory: {
      title: "Investment Category",
      fieldname: "Investment Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editinvestmentcategory: {
      title: "Investment Category",
      fieldname: "Investment Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listinvestment: {
      title: "Investment",
      columnname: "Investment Name",
      columncategory: "Investment Category",
      columntags: "Tags",
      columncompany: "Company",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete investment?",
      deletesuccess: "Delete successful",
    },
    inputinvestment: {
      title: "Investment",
      fieldname: "Investment Name",
      fieldcategory: "Investment Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldabout: "About",
      fieldcompany: "Company",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Investment Category",
      modalfieldname: "Investment Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    editinvestment: {
      title: "Investment",
      fieldname: "Investment Name",
      fieldcategory: "Investment Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldabout: "About",
      fieldcompany: "Company",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Investment Category",
      modalfieldname: "Investment Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    listtalentcategory: {
      title: "Talent Category",
      columnname: "Talent Category Name",
      columnaction: "Action",
      confirmdelete: "Delete talent category?",
      deletesuccess: "Delete successful",
    },
    inputtalentcategory: {
      title: "Talent Category",
      fieldname: "Talent Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    edittalentcategory: {
      title: "Talent Category",
      fieldname: "Talent Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listtalent: {
      title: "Talent",
      columnname: "Talent Name",
      columncategory: "Talent Category",
      columntags: "Tags",
      columncompany: "Company",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete talent?",
      deletesuccess: "Delete successful",
    },
    inputtalent: {
      title: "Talent",
      fieldname: "Talent Name",
      fieldcategory: "Talent Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldabout: "About",
      fieldcompany: "Company",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Talent Category",
      modalfieldname: "Talent Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    edittalent: {
      title: "Talent",
      fieldname: "Talent Name",
      fieldcategory: "Talent Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldabout: "About",
      fieldcompany: "Company",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Talent Category",
      modalfieldname: "Talent Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    listdownloadcategory: {
      title: "Download Category",
      columnname: "Download Category Name",
      columnaction: "Action",
      confirmdelete: "Delete download category?",
      deletesuccess: "Delete successful",
    },
    inputdownloadcategory: {
      title: "Download Category",
      fieldname: "Download Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editdownloadcategory: {
      title: "Download Category",
      fieldname: "Download Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listdownload: {
      title: "Download",
      columnname: "Download Name",
      columncategory: "Download Category",
      columntags: "Tags",
      columncommunity: "Community",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete download?",
      deletesuccess: "Delete successful",
    },
    inputdownload: {
      title: "Download",
      fieldname: "Download Name",
      fieldcategory: "Download Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Download Category",
      modalfieldname: "Download Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    editdownload: {
      title: "Download",
      fieldname: "Download Name",
      fieldcategory: "Download Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Download Category",
      modalfieldname: "Download Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    listmerchantcategory: {
      title: "Merchant Category",
      columnname: "Merchant Category Name",
      columnaction: "Action",
      confirmdelete: "Delete merchant category?",
      deletesuccess: "Delete successful",
    },
    inputmerchantcategory: {
      title: "Merchant Category",
      fieldname: "Merchant Category Name",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editmerchantcategory: {
      title: "Merchant Category",
      fieldname: "Merchant Category Name",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listmerchant: {
      title: "Merchant",
      columnname: "Merchant Name",
      columncategory: "Merchant Category",
      columntags: "Tags",
      columncommunity: "Community",
      columnppn: "PPN Tax",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete merchant?",
      deletesuccess: "Delete successful",
    },
    inputmerchant: {
      title: "Merchant",
      fieldname: "Merchant Name",
      fieldcategory: "Merchant Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldabout: "About",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Merchant Category",
      modalfieldname: "Merchant Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    editmerchant: {
      title: "Merchant",
      fieldname: "Merchant Name",
      fieldcategory: "Merchant Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldabout: "About",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Merchant Category",
      modalfieldname: "Merchant Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    listcommoditycategory: {
      title: "Commodity Category",
      columnname: "Commodity Category Name",
      columnaction: "Action",
      confirmdelete: "Delete commodity category?",
      deletesuccess: "Delete successful",
    },
    inputcommoditycategory: {
      title: "Commodity Category",
      fieldname: "Commodity Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editcommoditycategory: {
      title: "Commodity Category",
      fieldname: "Commodity Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listcommodity: {
      title: "Commodity",
      columnname: "Commodity Name",
      columncategory: "Commodity Category",
      columntags: "Tags",
      columnmerchant: "Merchant",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete commodity?",
      deletesuccess: "Delete successful",
    },
    inputcommodity: {
      title: "Commodity",
      fieldname: "Commodity Name",
      fieldcategory: "Commodity Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldmerchant: "Merchant",
      fieldavailable: "Is Available",
      fieldprice: "Price",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editcommodity: {
      title: "Commodity",
      fieldname: "Commodity Name",
      fieldcategory: "Commodity Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldmerchant: "Merchant",
      fieldavailable: "Is Available",
      fieldprice: "Price",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listmarketplaceevent: {
      title: "Marketplace Event",
      columntitle: "Title",
      columndescription: "Description",
      columnstartdate: "Start Date",
      columnenddate: "End Date",
      columnstarttime: "Start Time",
      columnendtime: " End Time",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete marketplace event?",
      deletesuccess: "Delete successful",
    },
    inputmarketplaceevent: {
      title: "Input Marketplace Event",
      fieldlocation: "Location",
      fieldtitle: "Title",
      fielddescription: "Description",
      fieldstartdate: "Start Date",
      fieldenddate: "End Date",
      fieldstarttime: "Start Time",
      fieldendtime: "End Time",
      fieldtype: "Type",
      fieldcommunity: "Community",
      fieldisavailable: "Is Available",
      validation: "Please fill all the input!",
      error: "Please input a valid date!",
      savesuccess: "Save successful",
    },
    editmarketplaceevent: {
      title: "Edit Marketplace Event",
      fieldlocation: "Location",
      fieldtitle: "Title",
      fielddescription: "Description",
      fieldstartdate: "Start Date",
      fieldenddate: "End Date",
      fieldstarttime: "Start Time",
      fieldendtime: "End Time",
      fieldtype: "Type",
      fieldcommunity: "Community",
      fieldisavailable: "Is Available",
      validation: "Please fill all the input!",
      error: "Please input a valid date!",
      savesuccess: "Save successful",
    },
    listmarketplaceadvertisement: {
      title: "Marketplace Advertisement",
      columnname: "Name",
      columnbannerpic: "Banner Picture",
      columnlink: "Link",
      columnposition: "Position",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete marketplace advertisement?",
      deletesuccess: "Delete successful",
    },
    inputmarketplaceadvertisement: {
      title: "Input Marketplace Advertisement",
      fieldname: "Name",
      fieldbannerpic: "Upload Banner Picture",
      fieldlink: "Link",
      fieldposition: "Position",
      fieldcommunity: "Community",
      fieldisavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editmarketplaceadvertisement: {
      title: "Edit Marketplace Advertisement",
      fieldname: "Name",
      fieldbannerpic: "Upload Banner Picture",
      fieldlink: "Link",
      fieldposition: "Position",
      fieldcommunity: "Community",
      fieldisavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listonlinestorecategory: {
      title: "Online Store Category",
      columnname: "Online Store Category Name",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete online store category?",
      deletesuccess: "Delete successful",
    },
    inputonlinestorecategory: {
      title: "Online Store Category",
      fieldname: "Online Store Category Name",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editonlinestorecategory: {
      title: "Online Store Category",
      fieldname: "Online Store Category Name",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listonlinestore: {
      title: "Online Store",
      columnname: "Online Store Name",
      columncategory: "Online Store Category",
      columntags: "Tags",
      columncommunity: "Community",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete online store?",
      deletesuccess: "Delete successful",
    },
    inputonlinestore: {
      title: "Online Store",
      fieldname: "Online Store Name",
      fieldcategory: "Online Store Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      fieldprice: "Price",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Online Store Category",
      modalfieldname: "Online Store Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    editonlinestore: {
      title: "Online Store",
      fieldname: "Online Store Name",
      fieldcategory: "Online Store Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldtags: "Tags",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      fieldprice: "Price",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Online Store Category",
      modalfieldname: "Online Store Category Name",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    listmoments: {
      title: "Moments",
      columnname: "Name",
      columndesc: "Moment Description",
      columntags: "Tags",
      columncommunity: "Community",
      columndate: "Date",
      columnhidden: "Is Hidden",
      columnaction: "Action",
      confirmdelete: "Delete moment?",
      deletesuccess: "Delete successful",
    },
    inputmoments: {
      title: "Moments",
      fieldname: "Name",
      fielddesc: "Description",
      fieldtags: "Tags",
      fieldcommunity: "Community",
      fieldhidden: "Is Hidden",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editmoments: {
      title: "Moments",
      fieldname: "Name",
      fielddesc: "Description",
      fieldtags: "Tags",
      fieldcommunity: "Community",
      fieldhidden: "Is Hidden",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      fieldcomment: "Comment",
      columnname: "Name",
      columncomment: "Comment",
      columnhidden: "Hidden",
      columnaction: "Action",
      confirmdelete: "Delete comment?",
      deletesuccess: "Delete successful",
      modaltitle: "Edit Comment",
      modalfieldname: "Comment",
      modalfieldhidden: "Hidden",
      modalcancel: "Cancel",
      modalsubmit: "Edit",
    },
    listbilling: {
      title: "Billing",
      columnname: "User",
      columncategory: "Billing Category",
      columnmonth: "Month",
      columnyear: "Year",
      columncustomerid: "Customer ID",
      columnbillingtype: "Billing Type",
      columnbillingamount: "Billing Amount",
      columnbillingstatus: "Billing Status",
      columnaction: "Action",
      confirmdelete: "Delete billing?",
      deletesuccess: "Delete successful",
    },
    listbillingdebtor: {
      title: "Billing IPKL",
      columnname: "User",
      columntransaksi: "Transaksi ID",
      columncategory: "External ID",
      columnmonth: "Debtor Acct",
      columnyear: "Billing Amount",
      columncustomerid: "Billing Status",
      columnbillingtype: "Paid Date",
      columnbillingamount: "Paid Date",
      columnbillingstatus: "Phone Number",
      columnaction: "Action",
      confirmdelete: "Delete billing?",
      deletesuccess: "Delete successful",
    },
    inputbilling: {
      title: "Billing",
      fieldphoneno: "User",
      fieldcategory: "Billing Category",
      fieldmonth: "Select Month",
      fieldyear: "Select Year",
      fieldcustomerid: "Customer ID",
      fieldbillingtype: "Billing Type",
      fieldamount: "Billing Amount",
      fieldstatus: "Is Paid",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Billing Category",
      modalfieldname: "Billing Category Name",
      modalfieldavailable: "Is Available",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
      fieldaddress: "Address",
    },
    editbilling: {
      title: "Billing",
      fieldphoneno: "User",
      fieldcategory: "Billing Category",
      fieldmonth: "Select Month",
      fieldyear: "Select Year",
      fieldcustomerid: "Customer ID",
      fieldbillingtype: "Billing Type",
      fieldamount: "Billing Amount",
      fieldstatus: "Is Paid",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Billing Category",
      modalfieldname: "Billing Category Name",
      modalfieldavailable: "Is Available",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    detailbilling: {
      title: "Billing Detail Debtor",
      fieldexternalid: "Invoice Number",
      fieldphone: "Phone Number",
      fieldname_debtor: "Name Debtor",
      fieldtransaki_amount: "Transaksi Amount",
      fieldpayer_email: "Email",
      fieldtransaksi_date: "Transaksi Date",
      fieldtransaksi_id: "Transaksi ID",
      fieldpaid_amount: "Paid Amount",
      fieldpayment_method: "Payment Method",
      fieldpayment_channel: "Payment Channel",
      fieldstatus_payment: "Status Payment",
      fieldpayment_date: "Payment Date",
      fielddebtor: "Debtor Account",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Billing Category",
      modalfieldname: "Billing Category Name",
      modalfieldavailable: "Is Available",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
      invoicenumber: "Invoice Number",
      amountpayment: "Amount Payment",
    },
    listbillingcategory: {
      title: "Billing Category",
      columnname: "Billing Category Name",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete billing category?",
      deletesuccess: "Delete successful",
    },
    inputbillingcategory: {
      title: "Billing Category",
      fieldname: "Billing Category Name",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editbillingcategory: {
      title: "Billing Category",
      fieldname: "Billing Category Name",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listservicecenter: {
      title: "Service Center",
      columnname: "Name",
      columncategory: "Service Center Category",
      columndesc: "Description",
      columnprice: "Price",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete service center?",
      deletesuccess: "Delete successful",
    },
    inputservicecenter: {
      title: "Service Center",
      fieldname: "Name",
      fieldcategory: "Service Center Category",
      fielddesc: "Description",
      fieldprice: "Price",
      fieldavailable: "Is Available",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Service Center Category",
      modalfieldname: "Service Center Name",
      modalfieldavailable: "Is Available",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    editservicecenter: {
      title: "Service Center",
      fieldname: "Name",
      fieldcategory: "Service Center Category",
      fielddesc: "Description",
      fieldprice: "Price",
      fieldavailable: "Is Available",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Service Center Category",
      modalfieldname: "Service Center Category Name",
      modalfieldavailable: "Is Available",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    listservicecentercategory: {
      title: "Service Center Category",
      columnname: "Service Center Category Name",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete service center category?",
      deletesuccess: "Delete successful",
    },
    inputservicecentercategory: {
      title: "Service Center Category",
      fieldname: "Service Center Category Name",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editservicecentercategory: {
      title: "Service Center Category",
      fieldname: "Service Center Category Name",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      columnname: "Service Center Item Name",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete service center item?",
      deletesuccess: "Delete successful",
      modaltitle: "New Service Center Item",
      modalfieldname: "Service Center Item Name",
      modalfieldavailable: "Is Available",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    homeconfig: {
      title: "Home Config",
      fieldnewschecked: "News Feed",
      fieldnewscount: "Show News",
      fieldadvertisechecked: "Advertise",
      fieldadvertisecount: "Show Advertise",
      columntitle: "Title",
      columndesc: "Description",
      columnlink: "Link",
      columnavailable: "Is Available",
      columnaction: "Action",
      modaltitle: "Advertisement",
      modalfieldtitle: "Title",
      modalfielddesc: "Description",
      modalfieldlink: "Link",
      modalfieldavailable: "Is Available",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      confirmdelete: "Delete advertisement?",
      deletesuccess: "Delete successful",
    },
    listentertainment: {
      title: "Entertainment",
      columnlink: "Link",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete entertainment?",
      deletesuccess: "Delete successful",
    },
    inputentertainment: {
      title: "Entertainment",
      fieldlink: "Link",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editentertainment: {
      title: "Entertainment",
      fieldlink: "Link",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listuserservice: {
      title: "User Service",
      columnmain: "Service Center",
      columnprice: "Total Price",
      columnstatuspay: "Status Payment",
      columnname: "User",
      columnphone: "Phone Number",
      columncategory: "Service Center Category",
      columnrequestdate: "Request Date",
      columnnote: "Note",
      columncustomerid: "Customer ID",
      columnassigned: "Assigned People",
      columnstatus: "Status Service",
      columnteknisi: "Technician",
      columnaction: "Action",
      confirmdelete: "Delete user service?",
      deletesuccess: "Delete successful",
    },
    inputuserservice: {
      title: "User Service",
      fieldphoneno: "User",
      fieldcategory: "Service Center Category",
      fieldprice: "Total Price",
      fieldrequestdate: "Request Date",
      fieldnote: "Note",
      fieldcustomerid: "Customer ID",
      fieldassigned: "Assigned People",
      fieldstatus: "Is Paid",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    edituserservice: {
      title: "User Service",
      fieldphoneno: "User",
      fieldcategory: "Service Center Category",
      fieldprice: "Total Price",
      fieldrequestdate: "Request Date",
      fieldnote: "Note",
      fieldcustomerid: "Customer ID",
      fieldassigned: "Assigned People",
      fieldstatus: "Is Paid",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      userservicedetail: "User Service Detail",
      columnservicecenter: "Service Center",
      columnprice: "Price",
      columnquantity: "Quantity",
      columnaction: "Action",
      fieldservicecenter: "Service Center",
      fieldquantity: "Quantity",
      modaltitle: "Edit User Service Detail",
      modalcancel: "Cancel",
      modalsubmit: "Edit",
      confirmdelete: "Delete user service detail?",
      deletesuccess: "Delete successful",
    },
    listroomcategory: {
      title: "Room Category",
      columnname: "Room Category Name",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete room category?",
      deletesuccess: "Delete successful",
    },

    inputroomcategory: {
      title: "Room Category",
      fieldname: "Room Category Name",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editroomcategory: {
      title: "Room Category",
      fieldname: "Room Category Name",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listroom: {
      title: "Room",
      columnname: "Room Name",
      columncategory: "Room Category",
      columnshortdesc: "Short Description",
      columnfulldesc: "Full Description",
      columnprice: "Price",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete room?",
      deletesuccess: "Delete successful",
    },
    listvoucher: {
      title: "Voucher",
      columnname: "Voucher Name",
      columnshortdesc: "Short Description",
      columnfulldesc: "Full Description",
      columnvaliddatein: "Valid Date in",
      columnvaliddateout: "Valid Date out",
      columnprice: "Price",
      columndiscount: "Discount",
      columnlimit: "Limit",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete room?",
      deletesuccess: "Delete successful",
    },
    inputroom: {
      title: "Room",
      fieldname: "Room Name",
      fieldcategory: "Room Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      fieldprice: "Price",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Room Category",
      modalfieldname: "Room Category Name",
      modalfieldavailable: "Is Available",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    inputvoucher: {
      title: "Voucher",
      fieldname: "Voucher Name",
      fielddesc: "Voucher Description",
      fieldvaliddatein: "Valid Date In",
      fieldvaliddateout: "Valid Date Out",
      fieldvoucherprice: "Voucher Price",
      fieldvoucherdisc: "Voucher Discount",
      fieldvoucherlimit: "Voucher Limit",
      fieldvoucherpic: "Voucher Picture",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modalfieldavailable: "Is Available",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    editvoucher: {
      title: "Voucher",
      fieldname: "Voucher Name",
      fielddesc: "Voucher Description",
      fieldvaliddatein: "Valid Date In",
      fieldvaliddateout: "Valid Date Out",
      fieldvoucherprice: "Voucher Price",
      fieldvoucherdisc: "Voucher Discount",
      fieldvoucherlimit: "Voucher Limit",
      fieldvoucherpic: "Voucher Picture",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Room Category",
      modalfieldname: "Room Category Name",
      modalfieldavailable: "Is Available",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    editroom: {
      title: "Room",
      fieldname: "Room Name",
      fieldcategory: "Room Category",
      fieldprice: "Price",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Room Category",
      modalfieldname: "Room Category Name",
      modalfieldavailable: "Is Available",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    listroomreservation: {
      title: "Room Reservation",
      columnphone: "Phone Number",
      columnname: "User",
      columncheckin: "Date Check In",
      columncheckout: "Date Check Out",
      columnroom: "Room",
      columnprice: "Price",
      columnstatus: "Status",
      columnaction: "Action",
      confirmdelete: "Delete room reservation?",
      deletesuccess: "Delete successful",
    },
    listforweddingreservation: {
      title: "Wedding Reservation",
      columnphone: "Phone Number",
      columnname: "User",
      columncheckin: "Date Check In",
      columncheckout: "Date Check Out",
      columnroom: "Facilities Name",
      columnprice: "Price",
      columnstatus: "Status",
      columnaction: "Action",
      confirmdelete: "Delete reservation?",
      deletesuccess: "Delete successful",
    },
    listnonweddingreservation: {
      title: "Facilities Reservation",
      columnphone: "Phone Number",
      columnname: "User",
      columncheckin: "Date Check In",
      columncheckout: "Date Check Out",
      columnroom: "Facilities Name",
      columnprice: "Price",
      columnstatus: "Status",
      columnaction: "Action",
      confirmdelete: "Delete reservation?",
      deletesuccess: "Delete successful",
    },
    inputroomreservation: {
      title: "Room Reservation",
      fieldphoneno: "User",
      fieldstartdate: "Date In",
      fieldenddate: "Date Out",
      fieldstarttime: "Time In",
      fieldendtime: "Time Out",
      fieldroom: "Room",
      fieldprice: "Price",
      fieldstatus: "Status",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      error: "Please input a valid date!",
      savesuccess: "Save successful",
    },
    editroomreservation: {
      title: "Room Reservation",
      fieldphoneno: "Phone Number",
      fielduser: "User",
      fieldstartdate: "Date In",
      fieldenddate: "Date Out",
      fieldstarttime: "Time In",
      fieldendtime: "Time Out",
      fieldroom: "Room",
      fieldprice: "Price",
      fieldstatus: "Status",
      fieldcommunity: "Community",
      alreadybooked:
        "The time has been booked by another user, please book another time",
      validation: "Please fill all the input!",
      error: "Please input a valid date!",
      savesuccess: "Save successful",
    },
    editforweddingreservation: {
      title: "Wedding Reservation",
      fieldphoneno: "Phone Number",
      fielduser: "User",
      fieldstartdate: "Date In",
      fieldenddate: "Date Out",
      fieldstarttime: "Time In",
      fieldendtime: "Time Out",
      fieldroom: "Facilities",
      fieldprice: "Price",
      fieldstatus: "Status",
      fieldcommunity: "Community",
      alreadybooked:
        "The time has been booked by another user, please book another time",
      validation: "Please fill all the input!",
      error: "Please input a valid date!",
      savesuccess: "Save successful",
    },
    editnonweddingreservation: {
      title: "Facilities Reservation",
      fieldphoneno: "Phone Number",
      fielduser: "User",
      fieldstartdate: "Date In",
      fieldenddate: "Date Out",
      fieldstarttime: "Time In",
      fieldendtime: "Time Out",
      fieldroom: "Room",
      fieldprice: "Price",
      fieldstatus: "Status",
      fieldcommunity: "Community",
      alreadybooked:
        "The time has been booked by another user, please book another time",
      validation: "Please fill all the input!",
      error: "Please input a valid date!",
      savesuccess: "Save successful",
    },
    listactivitycategory: {
      title: "Activity Category",
      columnname: "Activity Category Name",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete activity category?",
      deletesuccess: "Delete successful",
    },
    inputactivitycategory: {
      title: "Activity Category",
      fieldname: "Activity Category Name",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editactivitycategory: {
      title: "Activity Category",
      fieldname: "Activity Category Name",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listactivity: {
      title: "Activity",
      columnname: "Activity Name",
      columncategory: "Activity Category",
      columnshortdesc: "Short Description",
      columnfulldesc: "Full Description",
      columnprice: "Price",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete activity?",
      deletesuccess: "Delete successful",
    },
    inputactivity: {
      title: "Activity",
      fieldname: "Activity Name",
      fieldcategory: "Activity Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      fieldprice: "Price",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Activity Category",
      modalfieldname: "Activity Category Name",
      modalfieldavailable: "Is Available",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    editactivity: {
      title: "Activity",
      fieldname: "Activity Name",
      fieldcategory: "Activity Category",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldprice: "Price",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      modaltitle: "New Activity Category",
      modalfieldname: "Activity Category Name",
      modalfieldavailable: "Is Available",
      modalsubmit: "Submit",
      modalcancel: "Cancel",
    },
    listactivityreservation: {
      title: "Activity Reservation",
      columnphone: "Phone Number",
      columnname: "User",
      columncheckin: "Date Check In",
      columncheckout: "Date Check Out",
      columnactivity: "Activity",
      columnprice: "Price",
      columnstatus: "Status",
      columnaction: "Action",
      confirmdelete: "Delete activity reservation?",
      deletesuccess: "Delete successful",
    },
    inputactivityreservation: {
      title: "Activity Reservation",
      fieldphoneno: "User",
      fieldstartdate: "Date In",
      fieldenddate: "Date Out",
      fieldstarttime: "Time In",
      fieldendtime: "Time Out",
      fieldactivity: "Activity",
      fieldprice: "Price",
      fieldstatus: "Status",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      error: "Please input a valid date!",
      savesuccess: "Save successful",
    },
    editactivityreservation: {
      title: "Activity Reservation",
      fieldphoneno: "Phone Number",
      fielduser: "User",
      fieldstartdate: "Date In",
      fieldenddate: "Date Out",
      fieldstarttime: "Time In",
      fieldendtime: "Time Out",
      fieldactivity: "Activity",
      fieldprice: "Price",
      fieldstatus: "Status",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      error: "Please input a valid date!",
      savesuccess: "Save successful",
    },
    listorder: {
      title: "Online Store Order",
      columnname: "User",
      columnaddress: "Address",
      columntotal: "Total Price",
      columnstatus: "Status",
      columnaction: "Action",
      confirmdelete: "Delete this order?",
      deletesuccess: "Delete successful",
      alertpaid: "This order has been paid",
      alertcancelled: "This order has been cancelled",
      confirmpayment: "Mark this order as paid?",
      paymentsuccess: "This order has been marked as paid",
    },
    inputorder: {
      title: "Online Store Order",
      fieldphoneno: "User",
      fieldlabel: "Deliver to",
      fieldaddress: "Address",
      fieldtotal: "Total Price",
      fieldstatus: "Status",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editorder: {
      title: "Online Store Order",
      fieldphoneno: "User",
      fieldlabel: "Deliver to",
      fieldaddress: "Address",
      fieldtotal: "Total Price",
      fieldstatus: "Status",
      fieldcommunity: "Community",
      orderdetail: "Order Detail",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      columnmerchant: "Merchant",
      columncommodity: "Commodity",
      columnprice: "Price",
      columnquantity: "Quantity",
      columnaction: "Action",
      fieldmerchant: "Merchant",
      fieldcommodity: "Commodity",
      fieldprice: "Price",
      fieldquantity: "Quantity",
      modaltitle: "Edit Order Detail",
      modalcancel: "Cancel",
      modalsubmit: "Edit",
      confirmdelete: "Delete order detail?",
      deletesuccess: "Delete successful",
    },
    listuservehicle: {
      title: "User Vehicle",
      columnname: "User",
      columnplate: "Plate No",
      columntype: "Vehicle Type",
      columnaction: "Action",
      confirmdelete: "Delete this user vehicle?",
      deletesuccess: "Delete successful",
    },
    inputuservehicle: {
      title: "User Vehicle",
      fieldphoneno: "User",
      fieldplate: "Plate No",
      fieldtype: "Vehicle Type",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    edituservehicle: {
      title: "User Vehicle",
      fieldphoneno: "User",
      fieldplate: "Plate No",
      fieldtype: "Vehicle Type",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listparking: {
      title: "Parking",
      columnname: "User",
      columnuservehicle: "User Vehicle",
      columnstartdate: "Start Date",
      columnenddate: "End Date",
      columnstarttime: "Start Time",
      columnendtime: " End Time",
      columnamount: "Amount",
      columnstatus: "Status",
      columnaction: "Action",
      confirmdelete: "Delete parking?",
      deletesuccess: "Delete successful",
    },
    inputforweddingcategory: {
      title: "Facilities Category",
      fieldname: "Facilities Category Name",
      fieldusefor: "Facilities Use",
      fieldavailable: "Is Available",
      fieldlocation: "Location",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editforweddingcategory: {
      title: "Wedding Category",
      fieldname: "Wedding Category Name",
      fieldlocation: "Location",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listforweddingcategory: {
      title: "Facilities Category",
      columnname: "Facilities Category Name",
      columnuse: "Use For",
      columnlocation: "Location",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete room category?",
      deletesuccess: "Delete successful",
    },
    listforwedding: {
      title: "Wedding Bookings",
      columnname: "Banquet Facilities Name",
      columncategory: "Facilities Category",
      columnshortdesc: "Short Description",
      columnfulldesc: "Full Description",
      columnprice: "Price",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete room?",
      deletesuccess: "Delete successful",
    },
    listnonwedding: {
      title: "Non Wedding Bookings",
      columnname: "Banquet Facilities Name",
      columncategory: "Facilities Category",
      columnshortdesc: "Short Description",
      columnfulldesc: "Full Description",
      columnprice: "Price",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete room?",
      deletesuccess: "Delete successful",
    },
    inputparking: {
      title: "Parking",
      fieldphoneno: "User",
      fielduservehicle: "User Vehicle",
      fieldstartdate: "Date In",
      fieldenddate: "Date Out",
      fieldstarttime: "Time In",
      fieldendtime: "Time Out",
      fieldamount: "Amount",
      fieldstatus: "Status",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      error: "Please input a valid date!",
      savesuccess: "Save successful",
    },
    editparking: {
      title: "Parking",
      fieldphoneno: "User",
      fielduservehicle: "User Vehicle",
      fieldstartdate: "Date In",
      fieldenddate: "Date Out",
      fieldstarttime: "Time In",
      fieldendtime: "Time Out",
      fieldamount: "Amount",
      fieldstatus: "Status",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      error: "Please input a valid date!",
      savesuccess: "Save successful",
    },
    listuservisit: {
      title: "User Visit",
      columnhost: "Host",
      columnhostaddress: "Host Address",
      columnvisit: "Visit Date",
      columnleave: "Leave Date",
      columnvisitor: "Visitor",
      columnvisitorcontact: "Visitor Contact",
      columnremark: "Remark",
      columnaction: "Action",
      confirmdelete: "Delete user visit?",
      deletesuccess: "Delete successful",
    },
    inputuservisit: {
      title: "User Visit",
      fieldhost: "Host",
      fieldhostaddress: "Host Address",
      fieldvisit: "Visit Date",
      fieldleave: "Leave Date",
      fieldvisitor: "Visitor",
      fieldvisitoric: "ID",
      fieldvisitorname: "Visitor Name",
      fieldvisitorpic: "Visitor Picture",
      fieldvisitorcontact: "Visitor Contact",
      fieldremark: "Remark",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      error: "Please input a valid date!",
      savesuccess: "Save successful",
    },
    edituservisit: {
      title: "User Visit",
      fieldhost: "Host",
      fieldhostaddress: "Host Address",
      fieldvisit: "Visit Date",
      fieldleave: "Leave Date",
      fieldvisitor: "Visitor",
      fieldvisitoric: "ID",
      fieldvisitorname: "Visitor Name",
      fieldvisitorpic: "Visitor Picture",
      fieldvisitorcontact: "Visitor Contact",
      fieldremark: "Remark",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      error: "Please input a valid date!",
      savesuccess: "Save successful",
    },
    listcallcenter: {
      title: "Call Center",
      columnname: "Call Center Name",
      columnphone: "Phone Number",
      columnposition: "Position",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete call center?",
      deletesuccess: "Delete successful",
    },
    inputcallcenter: {
      title: "Call Center",
      fieldname: "Call Center Name",
      fieldphone: "Phone Number",
      fieldposition: "Position",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editcallcenter: {
      title: "Call Center",
      fieldname: "Call Center Name",
      fieldphone: "Phone Number",
      fieldposition: "Position",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listdirectorycategory: {
      title: "Directory Category",
      columnname: "Directory Category Name",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete directory category?",
      deletesuccess: "Delete successful",
    },
    inputdirectorycategory: {
      title: "Directory Category",
      fieldname: "Directory Category Name",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editdirectorycategory: {
      title: "Directory Category",
      fieldname: "Directory Category Name",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listdirectory: {
      title: "Directory",
      columnname: "Directory  Name",
      columncategory: "Directory Category",
      columnaddress: "Address",
      columnwebsite: "Website",
      columnphone: "Phone Number",
      columnfacebook: "Facebook",
      columntwitter: "Twitter",
      columninstagram: "Instagram",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete directory?",
      deletesuccess: "Delete successful",
    },
    inputdirectory: {
      title: "Directory",
      fieldlocation: "Location",
      fieldname: "Directory Name",
      fieldcategory: "Directory Category",
      fieldlatitude: "Latitude",
      fieldlongitude: "Longitude",
      fieldaddress: "Address",
      fieldfacebook: "Facebook",
      fieldtwitter: "Twitter",
      fieldinstagram: "Instagram",
      fieldwebsite: "Website",
      fieldphone: "Phone Number",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editdirectory: {
      title: "Directory",
      fieldlocation: "Location",
      fieldname: "Directory Name",
      fieldcategory: "Directory Category",
      fieldlatitude: "Latitude",
      fieldlongitude: "Longitude",
      fieldaddress: "Address",
      fieldfacebook: "Facebook",
      fieldtwitter: "Twitter",
      fieldinstagram: "Instagram",
      fieldwebsite: "Website",
      fieldphone: "Phone Number",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listfoodgarden: {
      title: "Food Garden",
      columnname: "Food Garden  Name",
      columncategory: "Food Garden Category",
      columnaddress: "Address",
      columnphone: "Phone Number",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete Food Garden?",
      deletesuccess: "Delete successful",
    },
    inputfoodgarden: {
      title: "Food Garden",
      fieldlocation: "Location",
      fieldname: "Food Garden Name",
      fieldcategory: "Directory Category",
      fieldaddress: "Address",
      fieldshortdesc: "Short Description",
      fieldfulldesc: "Full Description",
      fieldphone: "Phone Number",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      modalcancel: "Cancel",
      modalsubmit: "Submit",
      fieldtags: "Tags",
      modalfieldname: "Direct Category Name",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editfoodgarden: {
      title: "Food Garden",
      fieldlocation: "Location",
      fieldname: "Food Garden Name",
      fieldcategory: "Food Garden Category",
      fieldlatitude: "Latitude",
      fieldlongitude: "Longitude",
      fieldaddress: "Address",
      fieldfacebook: "Facebook",
      fieldtwitter: "Twitter",
      fieldinstagram: "Instagram",
      fieldwebsite: "Website",
      fieldphone: "Phone Number",
      fieldcommunity: "Community",
      fieldavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listfoodorder: {
      title: "Food Order",
      columnprice: "Total Price",
      columnstatuspay: "Status Payment",
      columnname: "User",
      columnphone: "Phone Number",
      columncategory: "Merchan Name",
      columnrequestdate: "Request Date",
      columnnote: "Note",
      columncustomerid: "Customer ID",
      columnassigned: "Assigned People",
      columnstatus: "Status Order",
      columnteknisi: "Driver",
      columnaction: "Action",
      confirmdelete: "Delete user food order?",
      deletesuccess: "Delete successful",
    },
    inputfoodorder: {
      title: "Food Order",
      fieldphoneno: "User",
      fieldcategory: "Service Center Category",
      fieldprice: "Total Price",
      fieldrequestdate: "Request Date",
      fieldnote: "Note",
      fieldcustomerid: "Customer ID",
      fieldassigned: "Assigned People",
      fieldstatus: "Is Paid",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    editfoodorder: {
      title: "Food Order",
      fieldphoneno: "User Order",
      fieldcategory: "Merchant Name",
      fieldprice: "Total Price",
      fieldrequestdate: "Request Date Order",
      fieldnote: "Note",
      fieldstatus: "Is Paid",
      fieldcommunity: "Community",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
      userservicedetail: "User Order List",
      columnservicecenter: "Order Name",
      columnprice: "Price",
      columnquantity: "Quantity",
      columnaction: "Action",
      fieldservicecenter: "Service Center",
      fieldprice: "Price",
      fieldquantity: "Quantity",
      modaltitle: "Edit User Service Detail",
      modalcancel: "Cancel",
      modalsubmit: "Edit",
      confirmdelete: "Delete user service detail?",
      deletesuccess: "Delete successful",
    },

    listcomplaintform: {
      title: "Complaint Covid-19",
      complaintname: "Complaint Name",
      complaintdesc: "Description Detail",
      tanggalcomplaint: "Complaint Date",
      phonenumber: "Phone Number",
      nama: "Nama User",
      statuscomplaint: "Status Complaint",
      columnaction: "Action",
      confirmdelete: "Delete user complaint?",
      deletesuccess: "Delete successful",
    },

    detailcomplaintform: {
      title: "Complaint Covid-19",
      complaintname: "Complaint Name",
      complaintdesc: "Description Detail",
      tanggalcomplaint: "Complaint Date",
      phonenumber: "Phone Number",
      nama: "Nama User",
      statuscomplaint: "Status Complaint",
      columnaction: "Action",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },

    listhealthdeclaration: {
      title: "Daily Declaration",
      name: "Name",
      date: "Tanggal",
      phonenumber: "No. Hp",
      company: "Nama Perusahaan",
      cluster: "Cluster",
      city: "City",
      combo1: "Apakah Anda adalah termasuk dalam kelompok berikut?",
      combo2: "Adakah anda batuk, sesak dada, demam, lelah, pilek, diare?",
      combo3:
        "Jika anda mempunyai gejala pada pertanyaan no 2 diatas, apakah anda sebelumnya bertemu saudara atau teman?",
      combo4: "Sudahkah Anda menghubungi orang-orang berikut pada hari itu?",
      note: "Note",
      export: "Export",
    },

    listreturntrip: {
      title: "Return Trip",
      columnname: "Name",
      columncompany: "Nama Perusahaan",
      columncluster: "Cluster",
      columnphone: "No. Hp",
      columndate: "Tangga;",
      columnoffice: "Lokasi Kantor",
      columnbacktoofficehour: "Waktu kembali ke kantor",
      columnstartworkinghour: "Mulai Jam Kerja (Termasuk Remote Office)",
      columndeparture: "Titik Awal Keberangkatan",
      columnarrival: "Titik Kedatangan",
      columntransporation: "Transfortasi yang di anda gunakan",
      columnflightnumber: "Nomor plat transfortasi umum",
      columnhubei: "Apakah melewati area PSBB?",
      columnnote: "Note",
      columnaction: "Action",
      deletesuccess: "Delete successful",
      confirmdelete: "Do you want to delete ?",
      export: "Export",
    },
    listspringfestival: {
      title: "Return Mudik",
      columnname: "Nama",
      columncompany: "Nama Perusahaan",
      columncluster: "Cluster",
      columnphone: "No. Hp",
      columndate: "Tanggal",
      columnoffice: "Lokasi Kantor",
      columncity: "Kota yang dikunjungi",
      columncombo1:
        "Apakah Anda pernah ke atau melalui area PSBB selama dua minggu ini?",
      columncombo2:
        "Sudahkah Anda menghubungi orang-orang yang telah mengunjungi atau melewati area PSBB dalam dua minggu terakhir?",
      columncombo3:
        "Apakah kerabat atau teman mengalami demam, kelelahan, pilek, diare, batuk, sesak dada, dalam dua minggu terakhir?",
      columncombo4:
        "Pernahkah Anda mengunjungi dokter dalam dua minggu terakhir?",
      columncombo5: "Jika ada perawatan medis",
      columnnote: "Note",
      columnaction: "Action",
      deletesuccess: "Delete successful",
      confirmdelete: "Do you want to delete ?",
      export: "Export",
    },
    listhomebannerpage: {
      title: "Home Banner",
      columnname: "Name",
      columnbannerpic: "Banner Picture",
      columnlink: "Link",
      columnposition: "Position",
      columnavailable: "Is Available",
      columnaction: "Action",
      confirmdelete: "Delete banner?",
      deletesuccess: "Delete successful",
    },
    inputhomebannerpage: {
      title: "Input Home Banner",
      fieldname: "Name",
      fieldbannerpic: "Upload Banner Picture",
      fieldlink: "Link",
      fieldposition: "Position",
      fieldcommunity: "Community",
      fieldisavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    edithomebannerpage: {
      title: "Edit Home Banner",
      fieldname: "Name",
      fieldbannerpic: "Upload Banner Picture",
      fieldlink: "Link",
      fieldposition: "Position",
      fieldcommunity: "Community",
      fieldisavailable: "Is Available",
      validation: "Please fill all the input!",
      savesuccess: "Save successful",
    },
    listwfh: {
      title: "WFH (Work From Home)",
      messagenumber: "News Number",
      columntitle: "Title",
      columncommunity: "Community",
      columnavailable: "Status",
      columnaction: "Action",
      confirmdelete: "Are you sure you want to delete?",
      deletesuccess: "Delete success",
      newscategory: "News category",
      releasetime: "Release date",
      publisher: "Publisher",
      pinned: "Pinned state",
      displaystate: "Display state",
      reading: "Reading",
      approvalstatus: "Audit status",
      time: "Time",
    },
    inputwfh: {
      title: "WFH (Work From Home)",
      fieldtitle: "Title",
      fieldcategory: "News Category",
      fieldshortdesc: "Sort Desc",
      fieldfulldesc: "Full Desc",
      fieldcommunity: "Communityname",
      fieldavailable: "Status Available",
      fieldexpired: "Expired",
      validation: "Please fill in all inputs",
      savesuccess: "Save success",
      typeofinformation: "News Category",
      headline: "Title",
      subtitle: "Sort Desc",
      bigpicture: "Big Picture",
      locatedinthepark: "Community Name",
      associatedcompanies: "Company",
      details: "Full Desc",
      thumbnail: "Thumbnail Img",
      previewmode: "Preview Mode",
      status: "Status",
      stickystatus: "Sticky Status",
    },
    editwfh: {
      title: "WFH (Work From Home)",
      fieldtitle: "Title",
      fieldcategory: "News Category",
      fieldshortdesc: "Sort Desc",
      fieldfulldesc: "Full Desc",
      fieldcommunity: "Communityname",
      fieldavailable: "Status Available",
      fieldexpired: "Expired",
      validation: "Please fill in all inputs",
      savesuccess: "Save success",
      typeofinformation: "News Category",
      headline: "Title",
      subtitle: "Sort Desc",
      bigpicture: "Big Picture",
      locatedinthepark: "Community Name",
      associatedcompanies: "Company",
      details: "Full Desc",
      thumbnail: "Thumbnail Img",
      previewmode: "Preview Mode",
      status: "Status",
      stickystatus: "Sticky Status",
    },
  },

  chn: {
    global: {
      add: "添新",
      edit: "编辑",
      delete: "删除",
      search: "搜索",
      submit: "提交",
      cancel: "取消",
      uploadpicture: "上传图片",
      gallery: "画廊",
      show: "节目",
      hidden: "隐",
      yes: "是",
      no: "不",
      pending: "有待",
      paid: "支付",
      cancelled: "取消",
      pay: "工资",
    },
    header: {
      title: "智能社区的控制面板",
    },
    selectmulticolumn: {
      placeholder: "- 选择 -",
    },
    Menu: {
      master: "主菜单",
      dashboard: "仪表板",
      parkinformation: "园区信息",
      parkintroduction: "园区介绍",
      servicephilosophy: "园区理念",
      parkpositioning: "园区定位",
      parkenterprise: "园区企业",
      companyintroduction: "企业介绍",
      businesscase: "企业案例",
      corporatecontact: "企业联系人",
      news: "新闻",
      newscategory: "新闻类别",
      category: "类别",
      example: "例子",
      community: "定位区域",
      admin: "管理者",
      user: "用户",
      tag: "标签",
      company: "公司",
      companycategory: "公司类别",
      product: "产品",
      productcategory: "产品类别",
      service: "服务",
      servicecategory: "服务类别",
      project: "项目",
      projectcategory: "项目类别",
      investment: "投资",
      investmentcategory: "投资类别",
      talent: "人才",
      talentcategory: "人才类别",
      download: "下载",
      downloadcategory: "下载类别",
      merchant: "商人",
      merchantcategory: "商家类别",
      commodity: "商品",
      commoditycategory: "商品类别",
      marketplaceevent: "市场活动",
      marketplaceadvertisement: "市场广告",
      onlinestorecategory: "在线商店类别",
      onlinestore: "网上商城",
      moments: "时刻",
      billing: "计费",
      billingcategory: "计费 类别",
      servicecenter: "服务中心",
      servicecentercategory: "服务中心类别",
      homeconfig: "家庭配置",
      entertainment: "娱乐",
      userservice: "用户服务",
      roomcategory: "房间类别",
      room: "房间",
      roomreservation: "预订房间",
      activitycategory: "活动类别",
      activity: "活动",
      activityreservation: "活动预订",
      order: "订购",
      uservehicle: "用户车辆",
      parking: "停车处",
      uservisit: "用户访问",
      callcenter: "呼叫中心",
      directory: "目录",
      directorycategory: "目录类别",
    },
    /**
     * Components
     */
    MenuProfile: {
      profile: "轮廓",
      changepassword: "更改密码",
      logout: "登出",
    },
    PictureUploader: {
      placeholder: "在此拖动文件或单击“选择文件”按钮",
      choosefile: "选择文件",
      maxfile: "最大文件",
      pictures: "图片",
      reachlimit: "已达到限制文件",
    },
    FileUploader: {
      placeholder: "在此拖动文件或单击“选择文件”按钮",
      choosefile: "选择文件",
      maxfile: "最大文件",
      file: "文件",
      reachlimit: "已达到限制文件",
    },
    /**
     * Pages
     */
    Login: {
      title: "登录",
      phoneno: "电话号码",
      password: "密码",
      login: "登录",
      alertphoneno: "请输入你的手机号码",
      alertpassword: "请输入您的密码",
    },
    changepassword: {
      title: "更改密码",
      oldpassword: "旧密码",
      newpassword: "新密码",
      confirmpassword: "确认新密码",
      emptyold: "请填写旧密码",
      emptynew: "请填写新密码",
      invalidconfirm: "无效的确认密码",
      success: "更改密码成功",
      failed: "更改密码失败",
    },
    dashboard: {
      title: "仪表板页面",
    },
    example: {
      title: "例子",
    },
    parkintroduction: {
      title: "园区介绍",
      fieldtitle: "标题",
      fielddescription: "描述",
      fieldcommunity: "定位区域",
      validation: "请填写所有输入",
    },
    parkpositioning: {
      title: "园区定位",
      fieldtitle: "标题",
      fielddescription: "描述",
      fieldcommunity: "定位区域",
      validation: "请填写所有输入",
    },
    servicephilosophy: {
      title: "园区理念",
      fieldtitle: "标题",
      fielddescription: "描述",
      fieldcommunity: "定位区域",
      validation: "请填写所有输入",
    },
    companyintroduction: {
      title: "企业介绍",
      fieldtitle: "标题",
      fielddescription: "描述",
      fieldcommunity: "定位区域",
      validation: "请填写所有输入",
    },
    businesscase: {
      title: "企业案例",
      columntitle: "标题",
      columndescription: "描述",
      columncommunity: "定位区域",
      columnaction: "行动",
      confirmdelete: "你确定要删除吗?",
      deletesuccess: "删除成功",
    },
    inputbusinesscase: {
      title: "企业案例",
      fieldtitle: "标题",
      fielddescription: "描述",
      fieldcommunity: "定位区域",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
    },
    editbusinesscase: {
      title: "企业案例",
      fieldtitle: "标题",
      fielddescription: "描述",
      fieldcommunity: "定位区域",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
    },
    corporatecontact: {
      title: "企业联系人",
      columnname: "名称",
      columnphone: "电话",
      columncommunity: "定位区域",
      columnposition: "工作职位",
      columnaction: "行动",
      confirmdelete: "你确定要删除吗?",
      deletesuccess: "删除成功",
    },
    inputcorporatecontact: {
      title: "企业联系人",
      fieldname: "名称",
      fieldphone: "电话",
      fieldcommunity: "定位区域",
      fieldposition: "工作职位",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
    },
    editcorporatecontact: {
      title: "企业联系人",
      fieldname: "名称",
      fieldphone: "电话",
      fieldcommunity: "定位区域",
      fieldposition: "工作职位",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
    },
    listcommunity: {
      title: "定位区域列表",
      columnname: "定位区域名称",
      columndefault: "默认",
      columnavailable: "可得到",
      columnaction: "行动",
      confirmdelete: "你确定要删除吗?",
      deletesuccess: "删除成功",
    },
    inputcommunity: {
      title: "定位区域",
      fieldname: "定位区域名称",
      fielddefault: "默认",
      fieldavailable: "可得到",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
    },
    editcommunity: {
      title: "定位区域",
      fieldname: "定位区域名称",
      fielddefault: "默认",
      fieldavailable: "可得到",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
    },
    listcompany: {
      title: "公司",
      columnname: "公司名称",
      columncategory: "公司类别",
      columntags: "标签",
      columncommunity: "定位区域",
      columnavailable: "可得到",
      columnaction: "行动",
      confirmdelete: "你确定要删除吗?",
      deletesuccess: "删除成功",
    },
    inputcompany: {
      title: "公司",
      fieldname: "公司名称",
      fieldcategory: "公司类别",
      fieldshortdesc: "公司简介",
      fieldfulldesc: "公司详细介绍",
      fieldtags: "标签",
      fieldabout: "公司经历",
      fieldcommunity: "定位区域",
      fieldavailable: "有效信息",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
      modaltitle: "公司类别",
      modalfieldname: "公司类别名称",
      modalsubmit: "提交",
      modalcancel: "取消",
    },
    editcompany: {
      title: "公司",
      fieldname: "公司名称",
      fieldcategory: "公司类别",
      fieldshortdesc: "公司简介",
      fieldfulldesc: "公司详细介绍",
      fieldtags: "标签",
      fieldabout: "公司经历",
      fieldcommunity: "定位区域",
      fieldavailable: "有效信息",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
      modaltitle: "公司类别",
      modalfieldname: "公司类别名称",
      modalsubmit: "提交",
      modalcancel: "取消",
    },
    listcompanycategory: {
      title: "公司类别列表",
      columnname: "公司类别名称",
      columnaction: "行动",
      confirmdelete: "你确定要删除吗?",
      deletesuccess: "删除成功",
    },
    inputcompanycategory: {
      title: "公司类别",
      fieldname: "公司类别名称",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
    },
    editcompanycategory: {
      title: "公司类别",
      fieldname: "公司类别名称",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
    },
    listproductcategory: {
      title: "产品类别",
      columnname: "产品类别名称",
      columnaction: "行动",
      confirmdelete: "你确定要删除吗?",
      deletesuccess: "删除成功",
    },
    inputproductcategory: {
      title: "产品类别",
      fieldname: "产品类别名称",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
    },
    editproductcategory: {
      title: "产品类别",
      fieldname: "产品类别名称",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
    },
    listproduct: {
      title: "产品",
      columnname: "产品名称",
      columncategory: "产品类别",
      columntags: "标签",
      columncompany: "公司",
      columnavailable: "可得到",
      columnaction: "行动",
      confirmdelete: "你确定要删除吗?",
      deletesuccess: "删除成功",
    },
    inputproduct: {
      title: "产品",
      fieldname: "产品名称",
      fieldcategory: "产品类别",
      fieldshortdesc: "产品简介",
      fieldfulldesc: "产品详细介绍",
      fieldtags: "标签",
      fieldabout: "产品经历",
      fieldcompany: "公司",
      fieldavailable: "有效信息",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
      modaltitle: "新产品类别",
      modalfieldname: "产品类别名称",
      modalsubmit: "提交",
      modalcancel: "取消",
    },
    editproduct: {
      title: "产品",
      fieldname: "产品名称",
      fieldcategory: "产品类别",
      fieldshortdesc: "产品简介",
      fieldfulldesc: "产品详细介绍",
      fieldtags: "标签",
      fieldabout: "产品经历",
      fieldcompany: "公司",
      fieldavailable: "有效信息",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
      modaltitle: "新产品类别",
      modalfieldname: "产品类别名称",
      modalsubmit: "提交",
      modalcancel: "取消",
    },
    listservicecategory: {
      title: "服务类别",
      columnname: "服务类别名称",
      columnaction: "行动",
      confirmdelete: "你确定要删除吗?",
      deletesuccess: "删除成功",
    },
    inputservicecategory: {
      title: "服务类别",
      fieldname: "服务类别名称",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
    },
    editservicecategory: {
      title: "服务类别",
      fieldname: "服务类别名称",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
    },
    listservice: {
      title: "服务",
      columnname: "服务名称",
      columncategory: "服务类别",
      columntags: "标签",
      columncompany: "公司",
      columnavailable: "可得到",
      columnaction: "行动",
      confirmdelete: "你确定要删除吗?",
      deletesuccess: "删除成功",
    },
    inputservice: {
      title: "服务",
      fieldname: "服务名称",
      fieldcategory: "服务类别",
      fieldshortdesc: "服务简介",
      fieldfulldesc: "服务详细介绍",
      fieldtags: "标签",
      fieldabout: "服务经历",
      fieldcompany: "公司",
      fieldavailable: "有效信息",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
      modaltitle: "新服务类别",
      modalfieldname: "服务类别名称",
      modalsubmit: "提交",
      modalcancel: "取消",
    },
    editservice: {
      title: "服务",
      fieldname: "服务名称",
      fieldcategory: "服务类别",
      fieldshortdesc: "服务简介",
      fieldfulldesc: "服务详细介绍",
      fieldtags: "标签",
      fieldabout: "服务经历",
      fieldcompany: "公司",
      fieldavailable: "有效信息",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
      modaltitle: "新服务类别",
      modalfieldname: "服务类别名称",
      modalsubmit: "提交",
      modalcancel: "取消",
    },
    listtag: {
      title: "标签",
      columnname: "标签名称",
      columnaction: "行动",
      confirmdelete: "你确定要删除吗?",
      deletesuccess: "删除成功",
    },
    inputtag: {
      title: "标签",
      fieldname: "标签名称",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
    },
    edittag: {
      title: "标签",
      fieldname: "标签名称",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
    },
    listadmin: {
      title: "管理者",
      columnphone: "电话",
      columnname: "名称",
      columntype: "类型",
      columnemail: "电子邮件",
      columnlastlogin: "上次登录",
      columnsuspend: "暂停",
      columnaction: "行动",
      confirmdelete: "你确定要删除吗?",
      deletesuccess: "删除成功",
    },
    inputadmin: {
      title: "管理者",
      fieldphone: "电话",
      fieldname: "名称",
      fieldtype: "类型",
      fieldemail: "电子邮件",
      fieldpassword: "密码",
      fieldconfirmpass: "确认密码",
      fieldaccesscommunity: "访问社区",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
      invalidpass: "无效的确认密码",
    },
    editadmin: {
      title: "管理者",
      fieldphone: "电话",
      fieldname: "名称",
      fieldtype: "类型",
      fieldemail: "电子邮件",
      fieldsuspend: "暂停",
      fieldaccesscommunity: "访问社区",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
    },
    listuser: {
      title: "用户",
      columnphone: "电话",
      columnname: "名称",
      columnusertype: "用户类型",
      columngender: "性别",
      columncompany: "公司",
      columnlocation: "地点",
      columnemail: "电子邮件",
      columnlastip: " IP",
      columnlastlogin: "上次登录",
      columnsuspend: "暂停",
      columnaction: "行动",
      confirmdelete: "删除用户？",
      deletesuccess: "删除成功",
    },
    inputuser: {
      title: "用户",
      fieldphone: "电话",
      fieldname: "名称",
      fieldnickname: "昵称",
      fieldusertype: "用户类型",
      fieldgender: "性别",
      fielddob: "出生日期",
      fieldqrcode: "商业二维码",
      fieldcompany: "公司",
      fieldlocation: "地点",
      fieldemail: "电子邮件",
      fieldpassword: "密码",
      fieldconfirmpass: "确认密码",
      fieldcolleague: "只有可以看到真实信息的拼贴画",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
      invalidpass: "无效的确认密码",
      fieldcustomerid: "顾客 ID",
      fieldlabel: "地址标签",
      fieldaddress: "地址",
      fieldcommunity: "社区",
      modaltitle: "用户细节",
      modalcancel: "取消",
      modalsubmit: "提交",
      columnphoneno: "电话号码",
      columncustomerid: "顾客 ID",
      columnlabel: "地址标签",
      columnaddress: "地址",
      columnuserdetailtype: "用户类型",
      columncommunity: "社区",
      columnaction: "行动",
      confirmdelete: "删除用户详细信息？",
      modaledittitle: "编辑用户详细信息",
      modaledit: "编辑",
    },
    edituser: {
      title: "用户",
      fieldphone: "电话",
      fieldname: "名称",
      fieldnickname: "昵称",
      fieldusertype: "用户类型",
      fieldgender: "性别",
      fielddob: "出生日期",
      fieldqrcode: "商业二维码",
      fieldcompany: "公司",
      fieldlocation: "地点",
      fieldemail: "电子邮件",
      fieldpassword: "密码",
      fieldconfirmpass: "确认密码",
      fieldcolleague: "只有可以看到真实信息的拼贴画",
      fieldsuspend: "暂停",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
      fieldcustomerid: "顾客 ID",
      fieldlabel: "地址标签",
      fieldaddress: "地址",
      fieldcommunity: "社区",
      modaltitle: "用户细节",
      modalcancel: "取消",
      modalsubmit: "提交",
      columnphoneno: "电话号码",
      columncustomerid: "顾客 ID",
      columnlabel: "地址标签",
      columnaddress: "地址",
      columnuserdetailtype: "用户类型",
      columncommunity: "社区",
      columnaction: "行动",
      confirmdelete: "删除用户详细信息？",
      modaledittitle: "编辑用户详细信息",
      modaledit: "编辑",
      fieldplate: "板号",
      fieldtype: "车辆类型",
      columnplate: "板号",
      columntype: "车辆类型",
      columnaction: "行动",
      confirmdelete: "删除此用户车辆？",
      deletesuccess: "删除成功",
      modaluservehicletitle: "编辑用户车辆",
    },
    listnewscategory: {
      title: "新闻类别",
      columnname: "新闻类别名称",
      columnaction: "行动",
      confirmdelete: "删除新闻类别？",
      deletesuccess: "删除成功",
    },
    inputnewscategory: {
      title: "新闻类别",
      fieldname: "新闻类别名称",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    editnewscategory: {
      title: "新闻类别",
      fieldname: "新闻类别名称",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    listnews: {
      title: "新闻",
      columntitle: "标题",
      columncommunity: "定位区域",
      columnavailable: "可得到",
      columnaction: "行动",
      confirmdelete: "你确定要删除吗?",
      deletesuccess: "删除成功",
    },
    inputnews: {
      title: "新闻",
      fieldtitle: "标题",
      fieldcategory: "新闻类别",
      fieldshortdesc: "简介",
      fieldfulldesc: "详细介绍",
      fieldcommunity: "定位区域",
      fieldavailable: "有效信息",
      fieldexpired: "确认密码",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
      invalidpass: "无效的确认密码",
      modaltitle: "新闻类别",
      modalfieldname: "新闻类别名称",
      modalsubmit: "提交",
      modalcancel: "取消",
    },
    editnews: {
      title: "新闻",
      fieldtitle: "标题",
      fieldcategory: "新闻类别",
      fieldshortdesc: "简介",
      fieldfulldesc: "详细介绍",
      fieldcommunity: "定位区域",
      fieldavailable: "有效信息",
      fieldexpired: "确认密码",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
      invalidpass: "无效的确认密码",
      modaltitle: "新闻类别",
      modalfieldname: "新闻类别名称",
      modalsubmit: "提交",
      modalcancel: "取消",
    },
    listprojectcategory: {
      title: "项目类别",
      columnname: "项目类别名称",
      columnaction: "行动",
      confirmdelete: "删除项目类别？",
      deletesuccess: "删除成功",
    },
    inputprojectcategory: {
      title: "项目类别",
      fieldname: "项目类别名称",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    editprojectcategory: {
      title: "项目类别",
      fieldname: "项目类别名称",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    listproject: {
      title: "项目",
      columnname: "项目名",
      columncategory: "项目类别",
      columntags: "标签",
      columncompany: "公司",
      columnavailable: "可用",
      columnaction: "行动",
      confirmdelete: "删除项目？",
      deletesuccess: "删除成功",
    },
    inputproject: {
      title: "项目",
      fieldname: "项目名",
      fieldcategory: "项目类别",
      fieldshortdesc: "简短的介绍",
      fieldfulldesc: "详细描述",
      fieldtags: "标签",
      fieldabout: "关于",
      fieldcompany: "公司",
      fieldavailable: "可用",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
      modaltitle: "新项目类别",
      modalfieldname: "项目类别名称",
      modalsubmit: "提交",
      modalcancel: "取消",
    },
    editproject: {
      title: "项目",
      fieldname: "项目名",
      fieldcategory: "项目类别",
      fieldshortdesc: "简短的介绍",
      fieldfulldesc: "详细描述",
      fieldtags: "标签",
      fieldabout: "关于",
      fieldcompany: "公司",
      fieldavailable: "可用",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
      modaltitle: "新项目类别",
      modalfieldname: "项目类别名称",
      modalsubmit: "提交",
      modalcancel: "取消",
    },
    listinvestmentcategory: {
      title: "投资类别",
      columnname: "投资类别名称",
      columnaction: "行动",
      confirmdelete: "删除投资类别？",
      deletesuccess: "删除成功",
    },
    inputinvestmentcategory: {
      title: "投资类别",
      fieldname: "投资类别名称",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    editinvestmentcategory: {
      title: "投资类别",
      fieldname: "投资类别名称",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    listinvestment: {
      title: "投资",
      columnname: "投资名称",
      columncategory: "投资类别",
      columntags: "标签",
      columncompany: "公司",
      columnavailable: "可用",
      columnaction: "行动",
      confirmdelete: "删除投资？",
      deletesuccess: "删除成功",
    },
    inputinvestment: {
      title: "投资",
      fieldname: "投资名称",
      fieldcategory: "投资类别",
      fieldshortdesc: "简短的介绍",
      fieldfulldesc: "详细描述",
      fieldtags: "标签",
      fieldabout: "关于",
      fieldcompany: "公司",
      fieldavailable: "可用",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
      modaltitle: "新投资类别",
      modalfieldname: "投资类别名称",
      modalsubmit: "提交",
      modalcancel: "取消",
    },
    editinvestment: {
      title: "投资",
      fieldname: "投资名称",
      fieldcategory: "投资类别",
      fieldshortdesc: "简短的介绍",
      fieldfulldesc: "详细描述",
      fieldtags: "标签",
      fieldabout: "关于",
      fieldcompany: "公司",
      fieldavailable: "可用",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
      modaltitle: "新投资类别",
      modalfieldname: "投资类别名称",
      modalsubmit: "提交",
      modalcancel: "取消",
    },
    listtalentcategory: {
      title: "人才类别",
      columnname: "人才类别名称",
      columnaction: "行动",
      confirmdelete: "删除人才类别？",
      deletesuccess: "删除成功",
    },
    inputtalentcategory: {
      title: "人才类别",
      fieldname: "人才类别名称",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    edittalentcategory: {
      title: "人才类别",
      fieldname: "人才类别名称",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    listtalent: {
      title: "天赋",
      columnname: "人才名称",
      columncategory: "人才类别",
      columntags: "标签",
      columncompany: "公司",
      columnavailable: "可用",
      columnaction: "行动",
      confirmdelete: "删除人才？",
      deletesuccess: "删除成功",
    },
    inputtalent: {
      title: "天赋",
      fieldname: "人才名称",
      fieldcategory: "人才类别",
      fieldshortdesc: "简短的介绍",
      fieldfulldesc: "详细描述",
      fieldtags: "标签",
      fieldabout: "关于",
      fieldcompany: "公司",
      fieldavailable: "可用",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
      modaltitle: "新人才类",
      modalfieldname: "人才分类名称",
      modalsubmit: "提交",
      modalcancel: "取消",
    },
    edittalent: {
      title: "天赋",
      fieldname: "人才名称",
      fieldcategory: "人才类别",
      fieldshortdesc: "简短的介绍",
      fieldfulldesc: "详细描述",
      fieldtags: "标签",
      fieldabout: "关于",
      fieldcompany: "公司",
      fieldavailable: "可用",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
      modaltitle: "新人才类",
      modalfieldname: "人才分类名称",
      modalsubmit: "提交",
      modalcancel: "取消",
    },
    listdownloadcategory: {
      title: "下载类别",
      columnname: "下载类别名称",
      columnaction: "行动",
      confirmdelete: "删除下载类别？",
      deletesuccess: "删除成功",
    },
    inputdownloadcategory: {
      title: "下载类别",
      fieldname: "下载类别名称",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    editdownloadcategory: {
      title: "下载类别",
      fieldname: "下载类别名称",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    listdownload: {
      title: "下载",
      columnname: "下载名称",
      columncategory: "下载类别",
      columntags: "标签",
      fieldcommunity: "社区",
      columnavailable: "可用",
      columnaction: "行动",
      confirmdelete: "删除人才？",
      deletesuccess: "删除成功",
    },
    inputdownload: {
      title: "下载",
      fieldname: "下载名称",
      fieldcategory: "下载类别",
      fieldshortdesc: "简短的介绍",
      fieldfulldesc: "详细描述",
      fieldtags: "标签",
      fieldabout: "关于",
      fieldcommunity: "社区",
      fieldavailable: "可用",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
      modaltitle: "新下载类别",
      modalfieldname: "下载分类名称",
      modalsubmit: "提交",
      modalcancel: "取消",
    },
    editdownload: {
      title: "下载",
      fieldname: "下载名称",
      fieldcategory: "下载类别",
      fieldshortdesc: "简短的介绍",
      fieldfulldesc: "详细描述",
      fieldtags: "标签",
      fieldabout: "关于",
      fieldcommunity: "社区",
      fieldavailable: "可用",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
      modaltitle: "新下载类别",
      modalfieldname: "下载分类名称",
      modalsubmit: "提交",
      modalcancel: "取消",
    },
    listmerchantcategory: {
      title: "商家类别",
      columnname: "商家类别名称",
      columnaction: "行动",
      confirmdelete: "删除商家类别？",
      deletesuccess: "删除成功",
    },
    inputmerchantcategory: {
      title: "商家类别",
      fieldname: "商家类别名称",
      fieldavailable: "可用",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    editmerchantcategory: {
      title: "商家类别",
      fieldname: "商家类别名称",
      fieldavailable: "可用",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    listmerchant: {
      title: "商人",
      columnname: "商户名称",
      columncategory: "商家类别",
      columntags: "标签",
      columncommunity: "定位区域",
      columnavailable: "可得到",
      columnaction: "行动",
      confirmdelete: "删除商家？",
      deletesuccess: "删除成功",
    },
    inputmerchant: {
      title: "商人",
      fieldname: "商户名称",
      fieldcategory: "商家类别",
      fieldshortdesc: "公司简介",
      fieldfulldesc: "公司详细介绍",
      fieldtags: "标签",
      fieldabout: "公司经历",
      fieldcommunity: "定位区域",
      fieldavailable: "有效信息",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
      modaltitle: "新商人类别",
      modalfieldname: "商家类别名称",
      modalsubmit: "提交",
      modalcancel: "取消",
    },
    editmerchant: {
      title: "商人",
      fieldname: "商户名称",
      fieldcategory: "商家类别",
      fieldshortdesc: "公司简介",
      fieldfulldesc: "公司详细介绍",
      fieldtags: "标签",
      fieldabout: "公司经历",
      fieldcommunity: "定位区域",
      fieldavailable: "有效信息",
      validation: "请填写所有输入",
      savesuccess: "保存成功",
      modaltitle: "新商人类别",
      modalfieldname: "商家类别名称",
      modalsubmit: "提交",
      modalcancel: "取消",
    },
    listcommoditycategory: {
      title: "商品类别",
      columnname: "商品类别名称",
      columnaction: "行动",
      confirmdelete: "删除商家类别？",
      deletesuccess: "删除成功",
    },
    inputcommoditycategory: {
      title: "商品类别",
      fieldname: "商品类别名称",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    editcommoditycategory: {
      title: "商品类别",
      fieldname: "商品类别名称",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    listcommodity: {
      title: "商品",
      columnname: "商品名称",
      columncategory: "商品类别",
      columntags: "标签",
      columnmerchant: "商人",
      columnavailable: "是可用的",
      columnaction: "行动",
      confirmdelete: "删除商品？",
      deletesuccess: "删除成功",
    },
    inputcommodity: {
      title: "商品",
      fieldname: "商品名称",
      fieldcategory: "商品类别",
      fieldshortdesc: "简短的介绍",
      fieldfulldesc: "详细描述",
      fieldtags: "标签",
      fieldmerchant: "商人",
      fieldavailable: "可用",
      fieldprice: "价钱",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    editcommodity: {
      title: "商品",
      fieldname: "商品名称",
      fieldcategory: "商品类别",
      fieldshortdesc: "简短的介绍",
      fieldfulldesc: "详细描述",
      fieldtags: "标签",
      fieldmerchant: "商人",
      fieldavailable: "可用",
      fieldprice: "价钱",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    listmarketplaceevent: {
      title: "市场活动",
      columntitle: "标题",
      columndescription: "描述",
      columnstartdate: "开始日期",
      columnenddate: "结束日期",
      columnstarttime: "开始时间",
      columnendtime: "时间结束",
      columnavailable: "是可用的",
      columnaction: "行动",
      confirmdelete: "删除市场活动？",
      deletesuccess: "删除成功",
    },
    inputmarketplaceevent: {
      title: "输入市场活动",
      fieldlocation: "地点",
      fieldtitle: "标题",
      fielddescription: "描述",
      fieldstartdate: "开始日期",
      fieldenddate: "结束日期",
      fieldstarttime: "开始时间",
      fieldendtime: "时间结束",
      fieldtype: "类型",
      fieldcommunity: "定位区域",
      fieldisavailable: "是可用的",
      validation: "请填写所有输入！",
      error: "请输入有效日期！",
      savesuccess: "保存成功",
    },
    editmarketplaceevent: {
      title: "编辑市场活动",
      fieldlocation: "地点",
      fieldtitle: "标题",
      fielddescription: "描述",
      fieldstartdate: "开始日期",
      fieldenddate: "结束日期",
      fieldstarttime: "开始时间",
      fieldendtime: "时间结束",
      fieldtype: "类型",
      fieldcommunity: "定位区域",
      fieldisavailable: "是可用的",
      validation: "请填写所有输入！",
      error: "请输入有效日期！",
      savesuccess: "保存成功",
    },
    listmarketplaceadvertisement: {
      title: "市场广告",
      columnname: "名称",
      columnbannerpic: "横幅图片",
      columnlink: "链接",
      columnposition: "位置",
      columnavailable: "是可用的",
      columnaction: "行动",
      confirmdelete: "删除市场广告？",
      deletesuccess: "删除成功",
    },
    inputmarketplaceadvertisement: {
      title: "输入市场广告",
      fieldname: "名称",
      fieldbannerpic: "上传横幅图片",
      fieldlink: "链接",
      fieldposition: "位置",
      fieldcommunity: "定位区域",
      fieldisavailable: "是可用的",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    editmarketplaceadvertisement: {
      title: "编辑市场广告",
      fieldname: "名称",
      fieldbannerpic: "上传横幅图片",
      fieldlink: "链接",
      fieldposition: "位置",
      fieldcommunity: "定位区域",
      fieldisavailable: "是可用的",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    listonlinestorecategory: {
      title: "在线商店类别",
      columnname: "在线商店类别名称",
      columnavailable: "可用",
      columnaction: "行动",
      confirmdelete: "删除在线商店类别？",
      deletesuccess: "删除成功",
    },
    inputonlinestorecategory: {
      title: "在线商店类别",
      fieldname: "在线商店类别名称",
      fieldavailable: "可用",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    editonlinestorecategory: {
      title: "在线商店类别",
      fieldname: "在线商店类别名称",
      fieldavailable: "可用",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    listonlinestore: {
      title: "网上商城",
      columnname: "在线商店名称",
      columncategory: "在线商店类别",
      columntags: "标签",
      columncommunity: "社区",
      columnavailable: "是可用的",
      columnaction: "行动",
      confirmdelete: "删除在线商店？",
      deletesuccess: "删除成功",
    },
    inputonlinestore: {
      title: "网上商城",
      fieldname: "在线商店名称",
      fieldcategory: "在线商店类别",
      fieldshortdesc: "简短的介绍",
      fieldfulldesc: "详细描述",
      fieldtags: "标签",
      fieldcommunity: "社区",
      fieldisavailable: "是可用的",
      fieldprice: "价钱",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
      modalsubmit: "提交",
      modalcancel: "取消",
    },
    editonlinestore: {
      title: "网上商城",
      fieldname: "在线商店名称",
      fieldcategory: "在线商店类别",
      fieldshortdesc: "简短的介绍",
      fieldfulldesc: "详细描述",
      fieldtags: "标签",
      fieldcommunity: "社区",
      fieldisavailable: "是可用的",
      fieldprice: "价钱",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
      modalsubmit: "提交",
      modalcancel: "取消",
    },
    listmoments: {
      title: "时刻",
      columnname: "名称",
      columndesc: "描述",
      columntags: "标签",
      columncommunity: "社区",
      columndate: "日期",
      columnhidden: "隐",
      columnaction: "行动",
      confirmdelete: "删除时刻？",
      deletesuccess: "删除成功",
    },
    inputmoments: {
      title: "时刻",
      fieldname: "名称",
      fielddesc: "描述",
      fieldtags: "标签",
      fieldcommunity: "社区",
      fieldhidden: "隐",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    editmoments: {
      title: "时刻",
      fieldname: "名称",
      fielddesc: "描述",
      fieldtags: "标签",
      fieldcommunity: "社区",
      fieldhidden: "隐",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
      fieldcomment: "评论",
      columncomment: "评论",
      columnhidden: "隐",
      columnaction: "行动",
      confirmdelete: "删除评论？",
      deletesuccess: "删除成功",
      modaltitle: "编辑评论",
      modalfieldname: "评论",
      modalhidden: "隐",
      modalcancel: "取消",
      modalsubmit: "提交",
    },
    listbilling: {
      title: "计费",
      columnname: "计费方式",
      columncategory: "结算类别",
      columnmonth: "月",
      columnyear: "年",
      columncustomerid: "顾客 ID",
      columnbillingtype: "账单类型",
      columnbillingamount: "结算金额",
      columnbillingstatus: "结算状态",
      columnaction: "行动",
      confirmdelete: "删除结算？",
      deletesuccess: "删除成功",
    },
    inputbilling: {
      title: "计费",
      fieldphoneno: "用户",
      fieldcategory: "结算类别",
      fieldmonth: "选择月",
      fieldyear: "选择年",
      fieldcustomerid: "顾客 ID",
      fieldbillingtype: "账单类型",
      fieldamount: "结算金额",
      fieldstatus: "已经付清",
      fieldcommunity: "社区",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
      modaltitle: "新的结算类别",
      modalfieldname: "帐单类别名称",
      modalfieldavailable: "可用",
      modalsubmit: "提交",
      modalcancel: "取消",
      fieldaddress: "地址",
    },
    editbilling: {
      title: "计费",
      fieldphoneno: "用户",
      fieldcategory: "结算类别",
      fieldmonth: "选择 月",
      fieldyear: "选择 年",
      fieldcustomerid: "顾客 ID",
      fieldbillingtype: "账单类型",
      fieldamount: "结算金额",
      fieldstatus: "已经付清",
      fieldcommunity: "社区",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
      modaltitle: "新的结算类别",
      modalfieldname: "帐单类别名称",
      modalfieldavailable: "可用",
      modalsubmit: "提交",
      modalcancel: "取消",
    },
    listbillingcategory: {
      title: "计费类别",
      columnname: "计费类别名称",
      columnavailable: "可得到",
      columnaction: "行动",
      confirmdelete: "删除计费类别？",
      deletesuccess: "删除成功",
    },
    inputbillingcategory: {
      title: "计费类别",
      fieldname: "计费类别名称",
      fieldavailable: "可用",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    editbillingcategory: {
      title: "计费类别",
      fieldname: "计费类别名称",
      fieldavailable: "可用",
      validation: "请填写所有输入！",
    },
    listservicecenter: {
      title: "服务中心",
      columnname: "名称",
      columncategory: "服务中心类别",
      columndesc: "描述",
      columnprice: "价钱",
      columnavailable: "可用",
      columnaction: "行动",
      confirmdelete: "删除服务中心？",
      deletesuccess: "删除成功",
    },
    inputservicecenter: {
      title: "服务中心",
      fieldname: "名称",
      fieldcategory: "服务中心类别",
      fielddesc: "描述",
      fieldamount: "量",
      fieldavailable: "可用",
      fieldcommunity: "社区",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
      modaltitle: "新服务中心类别",
      modalfieldname: "服务中心类别名称",
      modalfieldavailable: "可用",
      modalsubmit: "提交",
      modalcancel: "取消",
    },
    editservicecenter: {
      title: "服务中心",
      fieldname: "名称",
      fieldcategory: "服务中心类别",
      fielddesc: "描述",
      fieldamount: "量",
      fieldavailable: "可用",
      fieldcommunity: "社区",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
      modaltitle: "新服务中心类别",
      modalfieldname: "服务中心类别名称",
      modalfieldavailable: "可用",
      modalsubmit: "提交",
      modalcancel: "取消",
    },
    listservicecentercategory: {
      title: "服务中心类别",
      columnname: "服务中心类别名称",
      columnavailable: "可用",
      columnaction: "行动",
      confirmdelete: "删除服务中心类别？",
      deletesuccess: "删除成功",
    },
    inputservicecentercategory: {
      title: "服务中心类别",
      fieldname: "S服务中心类别名称",
      fieldavailable: "可用",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    editservicecentercategory: {
      title: "服务中心类别",
      fieldname: "服务中心类别名称",
      fieldavailable: "可用",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
      columnname: "服务中心项目名称",
      columnavailable: "可用",
      columnaction: "行动",
      confirmdelete: "删除服务中心项目？",
      deletesuccess: "删除成功",
      modaltitle: "新的服务中心项目",
      modalfieldname: "服务中心项目名称",
      modalfieldavailable: "可用",
      modalsubmit: "提交",
      modalcancel: "取消",
    },
    homeconfig: {
      title: "家庭配置",
      fieldnewschecked: "新闻提要",
      fieldnewscount: "显示新闻",
      fieldadvertisechecked: "广告",
      fieldadvertisecount: "显示广告",
      columntitle: "标题",
      columndesc: "描述",
      columnlink: "链接",
      columnavailable: "可用",
      columnaction: "行动",
      modaltitle: "广告",
      modalfieldtitle: "标题",
      modalfielddesc: "描述",
      modalfieldlink: "链接",
      modalfieldavailable: "可用",
      modalsubmit: "提交",
      modalcancel: "取消",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
      confirmdelete: "删除广告？",
      deletesuccess: "删除成功",
    },
    listentertainment: {
      title: "娱乐",
      columnlink: "链接",
      columnavailable: "可用",
      columnaction: "行动",
      confirmdelete: "删除娱乐？",
      deletesuccess: "删除成功",
    },
    inputentertainment: {
      title: "娱乐",
      fieldlink: "链接",
      fieldavailable: "可用",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    editentertainment: {
      title: "娱乐",
      fieldlink: "链接",
      fieldavailable: "可用",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    listuserservice: {
      title: "用户服务",
      columnmain: "服务中心",
      columnprice: "总价",
      columnname: "用户",
      columncategory: "服务中心类别",
      columnrequestdate: "查询日期",
      columnnote: "注意",
      columncustomerid: "顾客 ID",
      columnassigned: "分配人员",
      columnstatus: "支付",
      columnaction: "行动",
      confirmdelete: "删除用户服务？",
      deletesuccess: "删除成功",
    },
    inputuserservice: {
      title: "用户服务",
      fieldphoneno: "用户",
      fieldcategory: "服务中心类别",
      fieldprice: "总价",
      fieldrequestdate: "查询日期",
      fieldnote: "注意",
      fieldcustomerid: "顾客 ID",
      fieldassigned: "分配人员",
      fieldstatus: "支付",
      fieldcommunity: "社区",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    edituserservice: {
      title: "用户服务",
      fieldphoneno: "用户",
      fieldcategory: "服务中心类别",
      fielditem: "服务中心项目",
      fieldrequestdate: "查询日期",
      fieldnote: "注意",
      fieldcustomerid: "顾客 ID",
      fieldassigned: "分配人员",
      fieldstatus: "支付",
      fieldcommunity: "社区",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
      userservicedetail: "用户服务明细",
      columnservicecenter: "服务中心",
      fieldprice: "价钱",
      fieldquantity: "数量",
      columnaction: "行动",
      fieldservicecenter: "服务中心",
      fieldprice: "价钱",
      fieldquantity: "数量",
      modaltitle: "编辑用户服务详细信息",
      modalcancel: "取消",
      modalsubmit: "编辑",
      confirmdelete: "删除用户服务详情？",
      deletesuccess: "删除成功",
    },
    listroomcategory: {
      title: "房间类别",
      columnname: "房间类别名称",
      columnavailable: "可得到",
      columnaction: "行动",
      confirmdelete: "删除房间类别?",
      deletesuccess: "删除成功",
    },
    inputroomcategory: {
      title: "房间类别",
      fieldname: "房间类别名称",
      fieldavailable: "可用",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    editroomcategory: {
      title: "房间类别",
      fieldname: "房间类别名称",
      fieldavailable: "可用",
      validation: "请填写所有输入！",
    },
    listroom: {
      title: "房间",
      columnname: "房间名称",
      columncategory: "房间类别",
      columnshortdesc: "简短的介绍",
      columnfulldesc: "详细描述",
      columnavailable: "可用",
      columnaction: "行动",
      confirmdelete: "删除房间？",
      deletesuccess: "删除成功",
    },
    inputroom: {
      title: "房间",
      fieldname: "房间名称",
      fieldcategory: "房间类别",
      fieldshortdesc: "简短的介绍",
      fieldfulldesc: "详细描述",
      fieldcommunity: "社区",
      fieldavailable: "可用",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
      modaltitle: "新的房间类别",
      modalfieldname: "房间类别名称",
      modalfieldavailable: "可用",
      modalsubmit: "提交",
      modalcancel: "取消",
    },
    editroom: {
      title: "房间",
      fieldname: "房间名称",
      fieldcategory: "房间类别",
      fieldshortdesc: "简短的介绍",
      fieldfulldesc: "详细描述",
      fieldcommunity: "社区",
      fieldavailable: "可用",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
      modaltitle: "新的房间类别",
      modalfieldname: "房间类别名称",
      modalfieldavailable: "可用",
      modalsubmit: "提交",
      modalcancel: "取消",
    },
    listroomreservation: {
      title: "预订房间",
      columnname: "用户",
      columncheckin: "日期登记入住",
      columncheckout: "约会结账",
      columnroom: "房间",
      columnprice: "价钱",
      columnstatus: "状态",
      columnaction: "行动",
      confirmdelete: "删除房间预订？",
      deletesuccess: "删除成功",
    },
    inputroomreservation: {
      title: "预订房间",
      fieldphoneno: "用户",
      fieldstartdate: "开始日期",
      fieldenddate: "结束日期",
      fieldstarttime: "开始时间",
      fieldendtime: "时间结束",
      fieldroom: "房间",
      fieldprice: "价钱",
      fieldstatus: "状态",
      fieldcommunity: "社区",
      validation: "请填写所有输入！",
      error: "请输入有效日期！",
      savesuccess: "保存成功",
    },
    editroomreservation: {
      title: "预订房间",
      fieldphoneno: "用户",
      fieldstartdate: "开始日期",
      fieldenddate: "结束日期",
      fieldstarttime: "开始时间",
      fieldendtime: "时间结束",
      fieldroom: "房间",
      fieldprice: "价钱",
      fieldstatus: "状态",
      fieldcommunity: "社区",
      validation: "请填写所有输入！",
      error: "请输入有效日期！",
      savesuccess: "保存成功",
    },
    listactivitycategory: {
      title: "活动类别",
      columnname: "活动类别名称",
      columnavailable: "可用",
      columnaction: "行动",
      confirmdelete: "删除活动类别？",
      deletesuccess: "删除成功",
    },
    inputactivitycategory: {
      title: "活动类别",
      fieldname: "活动类别名称",
      fieldavailable: "可用",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    editactivitycategory: {
      title: "活动类别",
      fieldname: "活动类别名称",
      fieldavailable: "可用",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    listactivity: {
      title: "活动",
      columnname: "活动名称",
      columncategory: "活动类别",
      columnshortdesc: "简短的介绍",
      columnfulldesc: "详细描述",
      columnavailable: "可用",
      columnaction: "行动",
      confirmdelete: "删除活动？",
      deletesuccess: "删除成功",
    },
    inputactivity: {
      title: "活动",
      fieldname: "活动名称",
      fieldcategory: "活动类别",
      fieldshortdesc: "简短的介绍",
      fieldfulldesc: "详细描述",
      fieldcommunity: "社区",
      fieldavailable: "可用",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
      modaltitle: "新的活动类别",
      modalfieldname: "活动类别名称",
      modalfieldavailable: "可用",
      modalsubmit: "提交",
      modalcancel: "取消",
    },
    editactivity: {
      title: "活动",
      fieldname: "活动名称",
      fieldcategory: "活动类别",
      fieldshortdesc: "简短的介绍",
      fieldfulldesc: "详细描述",
      fieldcommunity: "社区",
      fieldavailable: "可用",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
      modaltitle: "新的活动类别",
      modalfieldname: "活动类别名称",
      modalfieldavailable: "可用",
      modalsubmit: "提交",
      modalcancel: "取消",
    },
    listactivityreservation: {
      title: "活动预订",
      columnname: "用户",
      columncheckin: "日期登记入住",
      columncheckout: "约会结账",
      columnactivity: "活动",
      columnprice: "价钱",
      columnstatus: "状态",
      columnaction: "行动",
      confirmdelete: "删除活动预订？",
      deletesuccess: "删除成功",
    },
    inputactivityreservation: {
      title: "活动预订",
      fieldphoneno: "用户",
      fieldstartdate: "开始日期",
      fieldenddate: "结束日期",
      fieldstarttime: "开始时间",
      fieldendtime: "时间结束",
      fieldactivity: "活动",
      fieldprice: "价钱",
      fieldstatus: "状态",
      fieldcommunity: "社区",
      validation: "请填写所有输入！",
      error: "请输入有效日期！",
      savesuccess: "保存成功",
    },
    editactivityreservation: {
      title: "活动预订",
      fieldphoneno: "用户",
      fieldstartdate: "开始日期",
      fieldenddate: "结束日期",
      fieldstarttime: "开始时间",
      fieldendtime: "时间结束",
      fieldactivity: "活动",
      fieldprice: "价钱",
      fieldstatus: "状态",
      fieldcommunity: "社区",
      validation: "请填写所有输入！",
      error: "请输入有效日期！",
      savesuccess: "保存成功",
    },
    listorder: {
      title: "在线商店订单",
      columnname: "用户",
      columnaddress: "地址",
      columntotal: "总价",
      columnstatus: "状态",
      columnaction: "行动",
      confirmdelete: "删除此订单？",
      deletesuccess: "删除成功",
      alertpaid: "此订单已付清",
      alertcancelled: "订单已被取消",
      confirmpayment: "将此订单标记为已付款？",
      paymentsuccess: "此订单已标记为已付款",
    },
    inputorder: {
      title: "在线商店订单",
      fieldphoneno: "用户",
      fieldlabel: "交付给",
      fieldaddress: "地址",
      fieldtotal: "总价",
      fieldstatus: "状态",
      fieldcommunity: "社区",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    editorder: {
      title: "在线商店订单",
      fieldphoneno: "用户",
      fieldlabel: "交付给",
      fieldaddress: "地址",
      fieldtotal: "总价",
      fieldstatus: "状态",
      fieldcommunity: "社区",
      orderdetail: "订单详情",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
      columnmerchant: "商人",
      columncommodity: "商品",
      columnprice: "价钱",
      columnquantity: "数量",
      columnaction: "行动",
      fieldmerchant: "商人",
      fieldcommodity: "商品",
      fieldprice: "价钱",
      fieldquantity: "数量",
      modaltitle: "编辑订单明细",
      modalcancel: "取消",
      modalsubmit: "编辑",
      confirmdelete: "删除订单明细？",
      deletesuccess: "删除成功",
    },
    listuservehicle: {
      title: "用户车辆",
      columnname: "用户",
      columnplate: "板号",
      columntype: "车辆类型",
      columnaction: "行动",
      confirmdelete: "删除此用户车辆？",
      deletesuccess: "删除成功",
    },
    inputuservehicle: {
      title: "用户车辆",
      fieldphoneno: "用户",
      fieldplate: "板号",
      fieldtype: "车辆类型",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    edituservehicle: {
      title: "用户车辆",
      fieldphoneno: "用户",
      fieldplate: "板号",
      fieldtype: "车辆类型",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    listparking: {
      title: "停车处",
      columnname: "用户",
      columnuservehicle: "用户车辆",
      columnstartdate: "开始日期",
      columnenddate: "结束日期",
      columnstarttime: "开始时间",
      columnendtime: "时间结束",
      columnamount: "量",
      columnstatus: "状态",
      columnaction: "行动",
      confirmdelete: "删除停车？",
      deletesuccess: "删除成功",
    },
    inputparking: {
      title: "停车处",
      fieldphoneno: "用户",
      fielduservehicle: "用户车辆",
      fieldstartdate: "开始日期",
      fieldenddate: "结束日期",
      fieldstarttime: "开始时间",
      fieldendtime: "时间结束",
      fieldamount: "量",
      fieldstatus: "状态",
      fieldcommunity: "社区",
      validation: "请填写所有输入！",
      error: "请输入有效日期！",
      savesuccess: "保存成功",
    },
    editparking: {
      title: "停车处",
      fieldphoneno: "用户",
      fielduservehicle: "用户车辆",
      fieldstartdate: "开始日期",
      fieldenddate: "结束日期",
      fieldstarttime: "开始时间",
      fieldendtime: "时间结束",
      fieldamount: "量",
      fieldstatus: "状态",
      fieldcommunity: "社区",
      validation: "请填写所有输入！",
      error: "请输入有效日期！",
      savesuccess: "保存成功",
    },
    listuservisit: {
      title: "用户访问",
      columnhost: "主办",
      columnhostaddress: "主机地址",
      columnvisit: "访问日期",
      columnleave: "离开日期",
      columnvisitor: "游客",
      columnremark: "备注",
      columnaction: "行动",
      confirmdelete: "删除用户访问？",
      deletesuccess: "删除成功",
    },
    inputuservisit: {
      title: "用户访问",
      fieldhost: "主办",
      fieldhostaddress: "主机地址",
      fieldvisit: "访问日期",
      fieldleave: "离开日期",
      fieldvisitor: "游客",
      fieldvisitoric: "ID",
      fieldvisitorname: "访客姓名",
      fieldvisitorpic: "访客图片",
      fieldremark: "备注",
      fieldcommunity: "社区",
      validation: "请填写所有输入！",
      error: "请输入有效日期！",
      savesuccess: "保存成功",
    },
    edituservisit: {
      title: "用户访问",
      fieldhost: "主办",
      fieldhostaddress: "主机地址",
      fieldvisit: "访问日期",
      fieldleave: "离开日期",
      fieldvisitor: "游客",
      fieldvisitoric: "ID",
      fieldvisitorname: "访客姓名",
      fieldvisitorpic: "访客图片",
      fieldremark: "备注",
      fieldcommunity: "社区",
      validation: "请填写所有输入！",
      error: "请输入有效日期！",
      savesuccess: "保存成功",
    },
    listcallcenter: {
      title: "呼叫中心",
      columnname: "呼叫中心名称",
      columnphone: "电话号码",
      columnposition: "位置",
      columnavailable: "可用",
      columnaction: "行动",
      confirmdelete: "删除呼叫中心？",
      deletesuccess: "删除成功",
    },
    inputcallcenter: {
      title: "呼叫中心",
      fieldname: "呼叫中心名称",
      fieldphone: "电话号码",
      fieldposition: "位置",
      fieldcommunity: "社区",
      fieldavailable: "可用",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    editcallcenter: {
      title: "呼叫中心",
      fieldname: "呼叫中心名称",
      fieldphone: "电话号码",
      fieldposition: "位置",
      fieldcommunity: "社区",
      fieldavailable: "可用",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    listroomcategory: {
      title: "目录类别",
      columnname: "目录类别名称",
      columnavailable: "可得到",
      columnaction: "行动",
      confirmdelete: "删除目录类别？",
      deletesuccess: "删除成功",
    },
    inputroomcategory: {
      title: "目录类别",
      fieldname: "目录类别名称",
      fieldavailable: "可用",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    editroomcategory: {
      title: "目录类别",
      fieldname: "目录类别名称",
      fieldavailable: "可用",
      validation: "请填写所有输入！",
    },
    listdirectory: {
      title: "目录",
      columnname: "目录名称",
      columncategory: "目录类别",
      columnaddress: "地址",
      columnwebsite: "网站",
      columnphone: "电话号码",
      columnfacebook: "Facebook",
      columntwitter: "推特",
      columninstagram: "Instagram",
      columnavailable: "可用",
      columnaction: "行动",
      confirmdelete: "删除目录？",
      deletesuccess: "删除成功",
    },
    inputdirectory: {
      title: "目录",
      fieldlocation: "地点",
      fieldname: "目录名称",
      fieldcategory: "目录类别",
      fieldlatitude: "纬度",
      fieldlongitude: "经度",
      fieldaddress: "地址",
      fieldwebsite: "网站",
      fieldfacebook: "Facebook",
      fieldtwitter: "推特",
      fieldinstagram: "Instagram",
      fieldphone: "电话号码",
      fieldcommunity: "社区",
      fieldavailable: "可用",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
    editdirectory: {
      title: "目录",
      fieldlocation: "地点",
      fieldname: "目录名称",
      fieldcategory: "目录类别",
      fieldlatitude: "纬度",
      fieldlongitude: "经度",
      fieldaddress: "地址",
      fieldwebsite: "网站",
      fieldfacebook: "Facebook",
      fieldtwitter: "推特",
      fieldinstagram: "Instagram",
      fieldphone: "电话号码",
      fieldcommunity: "社区",
      fieldavailable: "可用",
      validation: "请填写所有输入！",
      savesuccess: "保存成功",
    },
  },
};

export function getLanguage(country, page) {
  return Languages[country][page];
}
